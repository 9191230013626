<template> 
  <div class="shop-car">
    <template v-if="stepIndex == 1">
      <div class="car-body-box">
        <div class="car-content hnw-area-center">
          <div class="cart-tit">
            <div class="tit-text">
              全部商品
              <span v-if="$route.params.type == 1">
                ({{ this.$store.state.cartNums }})
              </span>
            </div>
            
            <el-button  v-if="$route.params.type == 2" class="ex-btn" size="mini" round type="primary" @click="exGoodsList">导出预购单</el-button>
            
          </div>

          <div class="cart-table">

            <div class="list-head">
              <div class="list-head-left">
                <div class="first">
                  <div class="check">
                    <el-checkbox
                      v-model="checkAll"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                  </div>
                </div>
                <div class="goods-show">商品</div>
              </div>
              <div class="goods-right">
                <div
                  class="item goods-storage"
                  v-if="$store.state.shop != 1974 && $store.state.shop != 1871"
                >
                  库存
                  <div class="ico">
                    <i
                      class="el-icon-caret-top"
                      :class="{ active: acSort == 'goods_storage1' }"
                      @click="sortChange('goods_storage', 1)"
                    ></i>
                    <i
                      class="el-icon-caret-bottom"
                      :class="{ active: acSort == 'goods_storage2' }"
                      @click="sortChange('goods_storage', 2)"
                    ></i>
                  </div>
                </div>

                <div class="item goods-price">
                  订货价
                  <span
                    style="display: inline-block"
                    v-if="
                      $store.state.shop == '1871' || $store.state.shop == '1974'
                    "
                    >(不含税)</span
                  >
                  <!-- (整箱单价) -->
                  <div class="ico">
                    <i
                      class="el-icon-caret-top"
                      :class="{ active: acSort == 'goods_price1' }"
                      @click="sortChange('goods_price', 1)"
                    ></i>
                    <i
                      class="el-icon-caret-bottom"
                      :class="{ active: acSort == 'goods_price2' }"
                      @click="sortChange('goods_price', 2)"
                    ></i>
                  </div>
                </div>
                <!-- <div class="item spec">规格</div> -->
                <div class="item num">订货数量</div>
                <div class="item total">总计</div>

                <div class="item handle">操作</div>
                
                <!-- <div class="item buyerName">操作员</div> -->
                <div class="item buyerName">
                  <el-select size="mini" @change="buyerChange" v-model="seleBuyers"  placeholder="操作员" multiple >
                    <el-option
                      v-for="(item,index) in buyerList"
                      :key="index"
                      :label="item.buyer_name"
                      :value="item.buyer_id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="list-body">

              <div
                class="store-row"
                v-for="store in cartList"
                :key="store.store_id"
              >
                <div class="list-store" v-if="store.data.length>0">
                  <el-checkbox
                    v-model="store.choose"
                    @change="handleCheckStoreChange(store)"
                    class="store-check"
                  ></el-checkbox>
                  <div class="company"> 
                    <span v-if="$store.state.for_branch">
                      {{
                        $store.state.shopInfo.shop_name ||
                        "江苏华诺威山东直营仓"
                      }}
                    </span>

                    <span
                      v-else-if="
                        store.store_id == '1780' ||
                        store.store_id == $store.state.shop
                      "
                    >
                      {{ store.store_name }}
                    </span>
                    <span v-else>
                      {{ store.store_name }}
                    </span>
                  </div>

                  <span
                    class="phone"
                    v-if="
                      store.store_id == '1780' ||
                      store.store_id == $store.state.shop
                    "
                  >
                    <img src="../../assets/commom/phone.png" alt="" />
                    <span v-if="$store.state.for_branch">
                      {{ $store.state.shopInfo.shop_contactphone }}
                    </span>
                    <span v-else>
                      {{ store.store_phone }}
                    </span>
                  </span>
                  <span v-else>
                    <img src="../../assets/commom/phone.png" alt="" />
                      {{ store.store_phone }}
                    </span>
                  <span
                    v-if="
                      store.store_id == '1780' ||
                      store.store_id == $store.state.shop
                    "
                  >
                    <i class="el-icon-location-outline"></i>
                    <span v-if="$store.state.for_branch">
                      {{ $store.state.shopInfo.shop_area_info }}
                    </span>
                    <span v-else>
                      {{ store.area_info }}
                    </span>
                  </span>
                  <span v-else>
                    <i class="el-icon-location-outline"></i>
                      {{ store.area_info }}
                  </span>
                </div>
                <div
                  v-for="(item, index) in store.data"
                  :key="index"
                  class="cart-type-item"
                >
                  <div class="cart-type-item-title">
                    <!-- 商品的信息条最外框 -->
                    <div class="type-title"> 
                      <div class="type">{{ item.title }}</div>
                    </div>
                  </div>

                  <div
                    class="cart-type-item-goods"
                    v-for="(goods, index) in item.goods"
                    :key="goods.goods_id"
                  >
                    <div class="clear-tip" v-if="goods.goods_commend == 2">
                      订购数量已包含赠送数量
                    </div>
                    <div
                      v-show="goods.showGoods"
                      class="goods-item"
                      :class="{nonstock:$route.params.type == 2&&goods.goods_storage == 0  }"
                    >
                      <div class="goods-left" >
                        <div class="first">
                          <div class="check">
                            <el-checkbox
                              size=""
                              v-model="goods.choose"
                              @change="handleCheckGoodsChange(store)"
                            ></el-checkbox>
                          </div>
                          <div class="img-box" v-if="item.title == '集单'">
                            <img style="" :src="goods.goods_image" alt="" />
                          </div>
                        </div>
                        <div class="goods-show">
                          <HnwOssImg  :src="goods.goods_image" :preview="true" :width="82"></HnwOssImg>
                          <ul >
                            <li
                              @click="getGoodsInfo(goods.goods_id)"
                             
                            >
                              <div class="brand">{{ goods.oenaturebrand }}</div>
                              <span class="xname">
                                <span>
                                  {{ goods.oecode }}
                                </span>
                                <!-- <HnwCopy :w="20" :data="goods.oecode" :left="2" :top="-2"></HnwCopy> -->
                                <span>
                                  {{ goods.goods_name }}
                                </span>
                               </span> 
                            </li>
                            <li class="gary-text middle">
                              <span>生产码：{{ goods.sccode }} </span>
                              <!-- <HnwCopy :data="goods.sccode" :left="2" :top="-2"></HnwCopy> -->
                            </li>
                            <li class="gary-text">
                              <span>车型：{{ goods.vehicletype }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="goods-right ">
                        <div
                          class="item goods-storage"
                          :class="{ red: $route.params.type == 2 }"
                          v-if="$store.state.shop != 1974 && $store.state.shop != 1871" >
                          <span v-if="$store.state.look_inv == 1">{{goods.goods_storage}}</span>
                          <span v-else>
                            <span v-if="$store.state.shop == 1948">有货</span>
                            <span :class="{ 'font-red': goods.goods_storage == 0}" v-else>{{goods.goods_storage > 0 ? "有货" : "无货"}}</span>
                          </span> 

                          <div style="font-size: 12px;color: red;" v-if="$store.state.member_id.indexOf('1780-')!=-1"> 
                            <div style="margin: 5px 0;">上海:
                              <span v-if="$store.state.look_inv==1">{{goods.sh_storage}}</span>
                              <span v-else>{{goods.sh_storage>0?"有货":"无货"}}</span>
                            </div>
                            <div>丹阳:
                              <span v-if="$store.state.look_inv==1">{{goods.dy_storage}}</span>
                              <span v-else>{{goods.dy_storage>0?"有货":"无货"}}</span>
                            </div>
                          </div>
                          <div v-else class="cang-tip" style="color:red;font-size:12px;margin-top:10px;">{{goods.cangTip}}</div>
                        </div>

                        <div
                          class="item goods-price"
                          v-if="$store.state.shop == 1948"
                        >
                          详询销售 
                        </div>

                        <div class="item goods-price"
                        v-else>
                          ￥{{ goods.goods_price }} 
                        </div> 
                        
                        <div class="item num"> 
                          <span
                            class="goods-zbyh-tit"
                            v-if="
                              goods.is_chain &&
                              goods.is_chain.includes('3') &&
                              goods.group_storage &&
                              Number(goods.group_storage) > 0
                            "
                          >
                            总仓发货,预计1~3天发货
                          </span>
                          <template v-if="goods.cart_type != 1">
                            <el-input-number
                              size="mini"
                              v-model="goods.goods_num"
                              :min="1"
                              @change="changeCartNums(goods)"
                            ></el-input-number>
                          </template>

                          <template v-else>
                            <el-input-number
                              v-if="
                                $store.state.zero_buy != 1 ||
                                goods.goods_commend == 2
                              "
                              size="mini"
                              v-model="goods.goods_num"
                              :min="1"
                              @change="impueNumChange(goods)"
                              :step="goods.goods_commend == 2 ? 2 : 1"
                              step-strictly
                            ></el-input-number>
                            <el-input-number
                              v-else
                              @change="changeCartNums(goods)"
                              size="mini"
                              v-model="goods.goods_num"
                              :min="1"
                            ></el-input-number>
                          </template>
                          <div class="goods-Fcl-tit" v-if="goods.boxNum"> 
                          </div>
                        </div>

                        <div
                          class="item total"
                          v-if="$store.state.shop == 1948||goods.goods_price=='详询销售'"
                        >
                          详询销售 
                        </div>
                        <div class="item total"
                        v-else>
                          ¥ 
                          <span>
                            {{
                              $hnwTools.times(
                                goods.goods_num,
                                Number(goods.goods_price)
                              )
                            }}
                          </span>
                        </div>
                        
                        <div class="item handle">
                          <span @click="deleteOne(goods.car_id, item)">
                            删除
                          </span>
                        </div>
                        <div class="item buyerName">
                          <el-tooltip
                            class="item"
                            effect="light"
                            :content="goods.buyer_name"
                            placement="bottom"
                          >
                            <div>
                              {{ goods.buyer_name.slice(0, 6) }}
                              <span v-if="goods.buyer_name.length > 6"
                                >...</span>
                            </div>
                            
                          </el-tooltip>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
        <div class="bot-confirm hnw-area-center">
          <div class="bot-row">
            <div class="bot-left">
              <div class="list-t2-le">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </div>

              <div class="delGoods" @click="delCart">删除选中商品</div>
              <div
                class="clearCart"
                v-if="$route.params.type == 1"
                @click="clearCart"
              >
                清理购物车
              </div>
            </div>

            <div class="bot-rig">
              <div style="margin-right: 21px">
                已选中 <span class="bot-text">{{ total.num }}</span>
                件商品，商品总金额
                <span v-if="$store.state.shop == 1948">
                  <span>详询销售</span>
                </span>
                <span v-else>
                  
                  <span class="bot-text big"> ￥{{ total.price }}</span>
                </span>

                <span
                  v-if="
                    total.num > 0 &&
                    ($store.state.shop == '1871' || $store.state.shop == '1974')
                  "
                  >(不含税)</span
                >
              </div>
              <!-- <button @click="gotest">去测试</button> -->

              <div >
                <div
                  class="submit"
                  @click="createOrder"
                  
                >
                  <img src="../../assets//new/car/结算.png" alt="" srcset="">
                  <span>去结算</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="stepIndex == 2">
      <div class="order-msg-handle hnw-area-center"  v-loading="showLoading">
        <div class="msg-title">填写并核对订单信息</div>
        <div class="order-msg-handle-item">
          <div class="order-msg-title">
            收货人信息
            <div class="addAdress">
              <span @click="$refs.siteform.AddressVis = true"
                >新增收货地址</span
              >
            </div>
          </div>
          <div class="order-msg-body">
            <div class="site-list-body">
              <div class="row" v-if="!showMoreSite">
                <!-- subSite -->
                <div class="name opt active">
                  {{ subSite.true_name }}
                  <span class="mark"></span>
                  <i class="el-icon-check"></i>
                </div>

                <div class="adress">
                  <span>{{ subSite.true_name }}</span>
                  <span>{{ subSite.area_info }}</span>
                  <span>{{ subSite.address }}</span>
                  <span> {{ subSite.mob_phone }}</span>
                  <span class="default" v-if="subSite.is_default == 1"
                    >默认地址</span
                  >
                </div>
              </div>
              <div
                class="row site-row"
                v-for="(site, index) in siteList"
                :key="index"
                v-else
              >
                <div
                  class="name opt"
                  :class="{ active: site.choose }"
                  @click="seleSite(site)"
                >
                  {{ site.true_name }}
                  <span class="mark"></span>
                  <i class="el-icon-check"></i>
                </div>

                <div class="adress">
                  <span>{{ site.true_name }}</span>
                  <span>{{ site.area_info }}</span>
                  <span>{{ site.address }}</span>
                  <span> {{ site.mob_phone }}</span>
                  <span class="default" v-if="site.is_default == 1"
                    >默认地址</span
                  >
                </div>
                <div class="setting">
                  <span v-if="site.is_default == 0" @click="defa(site)"
                    >设为默认地址</span
                  >
                  <span @click="edit(site)">编辑</span>
                  <span @click="del(site.address_id)">删除</span>
                </div>
              </div>
            </div>

            <div class="moreadress" @click="showMoreSite = !showMoreSite">
              {{ showMoreSite ? "收起地址" : "更多地址" }}
              <i
                class="el-icon-d-arrow-right"
                :class="{ pack: showMoreSite }"
              ></i>
            </div>
          </div>
        </div>

        <div class="order-msg-handle-item"> 
          <div class="addremarks">
            <span style="color: #409eff; width: 4px">丨</span>支付方式
          </div>
          <div class="order-msg-body">
            <div class="row">
              <div
                class="name opt disab"
                :class="{ active: set_type == type }"
                @click="set_type = type"
                v-for="type in set_typeArr"
                :key="type"
              >
                {{ type }}
                <span class="mark"></span>
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
        </div> 
        <div class="order-msg-handle-item">
          <div class="addremarks">
            <span style="color: #409eff; width: 4px">丨</span>配送方式
          </div>
          <div class="order-msg-body">
            <el-radio-group v-model="transport_type">
              <el-radio
                v-for="option in transportList"
                :key="option"
                :label="option"
                >{{ option }}</el-radio
              > 
            </el-radio-group>
          </div>
        </div>

        <div class="order-msg-handle-item"> 
          <div class="addremarks">
            <span style="color: #409eff; width: 4px">丨</span>送货清单
          </div>
          <div class="order-msg-body">
            <div class="goods-table">
              <div class="table-head">
                <div class="goods-msg">商品</div>
                <div class="item">
                  单价
                  <!-- (整箱单价) -->
                </div>
                <div class="num">数量</div> 
                <div class="item">小计</div>
              </div>

              <div class="table-body">
                <div
                  class="table-row"
                  v-for="(goods, index) in subCartLists"
                  :key="index"
                >
                  <div class="goods-msg">
                    <div class="img-box" v-if="goods.goods_image">
                      <HnwOssImg  :src="goods.goods_image"  :width="78"></HnwOssImg>
                      <!-- <img :src="goods.goods_image" alt="" /> -->
                    </div>

                    <div class="box-replace"></div>
                    <div class="goods-info">
                      <div class="name">
                        <div class="type"> 

                          <span v-if="goods.cart_type == 1"> 采 </span>
                          <span v-else-if="goods.cart_type == 2"> 集 </span>
                          <span v-else-if="goods.cart_type == 3"> 秒 </span>
                        </div>
                        <div :title="goods.goods_name">
                          {{ goods.goods_name }}
                        </div>
                      </div>
                      <div :title="goods.oecode">{{ goods.oecode }}</div>
                      <div :title="goods.vehicletype">
                        适用车型：{{ goods.vehicletype }}
                      </div>
                    </div>
                    <div class="goods-info">
                      <div>生产码：{{ goods.sccode }}</div>
                      <div>品牌：{{ goods.oenaturebrand }}</div>
                    </div>
                  </div>

                  <div class="item price"> 
                    <span v-if="$store.state.shop == 1948">详询销售</span>
                    <span v-else>￥{{ goods.goods_price }}</span>
                    <span
                      class="goods-zbyh-tit"
                      v-if="
                        goods.is_chain &&
                        goods.is_chain.includes('3') &&
                        goods.group_storage &&
                        Number(goods.group_storage) > 0
                      "
                    >
                      总仓发货，预计1~3天发货
                    </span>
                  </div>
                  <div class="num">
                    <template v-if="goods.cart_type != 1">
                      <el-input-number
                        v-if="goods.upper_limit != 0"
                        size="mini"
                        :disabled="goods.disa"
                        v-model="goods.goods_num"
                        :min="1"
                        @change="changeCartNums(goods)"
                      ></el-input-number>
                      <el-input-number
                        v-else
                        size="mini"
                        v-model="goods.goods_num"
                        :min="1"
                        @change="changeCartNums(goods)"
                      ></el-input-number>
                    </template>
                    <template v-else>
                      <el-input-number
                        v-if="
                          $store.state.zero_buy != 1 || goods.goods_commend == 2
                        "
                        size="mini"
                        v-model="goods.goods_num"
                        :min="1"
                        @change="impueNumChange(goods)"
                        :step="goods.goods_commend == 2 ? 2 : 1"
                        step-strictly
                      ></el-input-number>
                      <el-input-number
                        v-else
                        @change="changeCartNums(goods)"
                        size="mini"
                        v-model="goods.goods_num"
                        :min="1"
                      ></el-input-number>
                    </template>
                    <div class="modify-tip">{{ goods.modifyTip }}</div> 
                  </div> 
                  <div class="item red" v-if="$store.state.shop == 1948||goods.goods_price=='详询销售'">  详询销售 
                  </div>
                  <div class="item red" v-else> ¥ 
                    <span>
                      {{
                        $hnwTools.times(
                          goods.goods_num,
                          Number(goods.goods_price)
                        )
                      }}
                    </span> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 

        <div class="addremarks">
          <span style="color: #409eff; width: 4px">丨</span>添加订单备注
        </div>
        <div class="order-sum">
          <div class="remark"> 
            <div class="remark-body">
              <el-input
                type="textarea"
                v-model="buyer_remarks"
                :rows="5"
                maxlength="45"
                show-word-limit
                placeholder="提示：定制类商品，请将购买需求在备注中做详细说明，请勿填写有关支付、收发票方面信息"
              ></el-input> 
            </div>
          </div>
          <div>
            <div class="sum-item">
              <div class="sum-label">
                共<span class="red">{{ total.num }}</span> 件商品,商品总金额：
              </div>
              <div class="sum-value">
                <span class="price red" v-if="$store.state.shop == 1948"
                  >详询销售</span
                >
                <span class="price red" v-else>￥{{ total.price }}</span>
              </div>
            </div>

            <div class="sum-item">
              <div class="sum-label">返现：</div>
              <div class="sum-value">
                <span class="price">￥0.00</span>
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-label">运费：</div>
              <div class="sum-value">
                <span class="price">￥0.00</span>
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-label">服务费：</div>
              <div class="sum-value">
                <span class="price">￥0.00</span>
              </div>
            </div>
            <div class="sum-item">
              <div class="sum-label">优惠卷：</div>
              <div class="sum-value">
                <span class="price">￥0.00</span>
              </div>
            </div>
          </div>
        </div>

        <div class="order-fot-msg">
          <div class="price">
            <template v-if="$store.state.for_branch && set_type == '挂账'">
              <div>剩余额度：</div>
              <div class="price-num red quota">
                {{ purQuota }}
              </div>
            </template>
            <div>应付金额：</div>
            <div class="price-num red" v-if="$store.state.shop == 1948">
              详询销售
            </div>
            <div class="price-num red" v-else>￥{{ total.price }}</div>
          </div>
          <div class="msg-adrress">
            <span>寄送至：</span>
            {{ subSite.area_info }}
            {{ subSite.address }}
            <span class="userName">收货人：{{ subSite.true_name }} </span>
            <span class="phone">{{ subSite.mob_phone }}</span>
          </div>
        </div>
        <div class="sub-order"> 
          <el-button class="sub-handle" type="primary" :loading="orderLoading" @click="subOrder">提交订单</el-button>
        </div>
      </div>
    </template>

    <template v-else-if="stepIndex > 2">
      <div class="sub-order-pay hnw-area-center">
        <div class="order-pay-header" v-if="stepIndex == 3">
          <div class="head-left">
            <div>
              <div class="su-icon">
                <i class="el-icon-check"></i>
              </div>
              <span class="su-text">订单已提交，请尽快完成支付！</span>
            </div>
            <div v-if="set_type == '微信' || set_type == '支付宝'">
              请您在提交订单
              <span class="limit red"> {{ i }} 分 {{ s }} 秒 </span
              >内完成支付，否则订单会自动取消。
            </div>
          </div>
          <div class="head-right">
            <span class="label">应付金额：</span>
            <span class="value red"
              >￥{{
                total.price && total.price != "0.00"
                  ? total.price
                  : orderData.actual_amount
              }}</span
            >
          </div>
        </div>

        <div class="order-msg order-ed-msg">
          <div class="tit">订单信息</div>
          <div class="msg-row">
            <div class="msg-label">订单号：</div>
            <div class="msg-value">{{ orderData.order_sn }}</div>
          </div>
          <div class="msg-row">
            <div class="msg-label">收货地址：</div>
            <div class="msg-value">
              {{ subSite.area_info }} {{ subSite.addAdress }}
            </div>
          </div>

          <div class="msg-row">
            <div class="msg-label">商品明细：</div>

            <div class="msg-value">
              <div
                class="goos-row"
                v-for="(cart, index) in subCartLists.length > 0
                  ? subCartLists
                  : goods"
                :key="index"
              >
                <span>{{ cart.goods_name }}</span>
                <span>{{ cart.oecode }} </span>
                <span>X{{ cart.goods_num }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="pay-hint" v-if="stepIndex == 3">
          <div class="way-title">
            {{ set_type }}
          </div>
          <div class="way-body">
            <div class="QR" v-if="set_type == '微信' || set_type == '支付宝'">
              <div class="erweima">
                <img :src="payErweim" alt="" v-if="payErweim" />
              </div>
              <div class="QR-footer">
                <div class="fot-left">
                  <img src="../../assets/commom/scanBor.png" alt="" />
                </div>
                <div class="fot-right">
                  <div>请使用{{ set_type }}扫一扫</div>
                  <div>扫二维码支付</div>
                </div>
              </div>
            </div>
            <div class="zz-box" v-else>
              <img src="../../assets/commom/sgzz.png" alt="" />
              <div class="zz-pad">
                <el-upload
                  :action="
                    $axios.defaults.baseURL + 'index.php?act=upload&op=image'
                  "
                  list-type="picture-card"
                  :data="upImgData"
                  :headers="{ token: $store.state.token }"
                  multiple
                  name="image"
                  :on-success="upImgSuccessVoucher"
                  size="mini"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
              <div class="up-btn">
                <!-- size="mini" -->
                <el-button type="primary" @click="saveVoucher"
                  >提交凭证</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div v-else class="pay-ok-box">
          <div class="pay-ok-top">
            <div class="ok-icon">
              <i class="el-icon-check"></i>
            </div>
            <div class="ok-text">支付成功</div>
            <div class="cart-img-box">
              <img src="../../assets/commom/cart.png" alt="" />
            </div>
            <div class="price">
              ￥{{
                total.price && total.price != "0.00"
                  ? total.price
                  : orderData.actual_amount
              }}
            </div>
          </div>
          <div class="pay-ok-bot">
            <div class="row">
              <div class="label">支付方式</div>
              <div class="value">
                {{ olineWay == 1 ? "支付宝" : "微信" }}
              </div>
            </div>
            <div class="row">
              <div class="label">付款时间</div>
              <div class="value">
                {{ $hnwTools.formatDateTime(new Date().getTime() / 1000) }}
              </div>
            </div>
            <div class="read-info-box">
              <div
                class="read-info"
                @click="$router.push('/my/orderInfo/' + orderData.id)"
              >
                查看订单详情
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <SiteForm
      ref="siteform"
      @getList="getSiteList"
      :editData="editSiteData"
      @closed="closed"
    ></SiteForm>
     
    <el-dialog
      title="提示"
      :visible.sync="TipdialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>你购买的商品超过库存，已为你修改，请核对商品再下单</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="TipdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="TipdialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
        <div class="his-list">
            <div class="list-head">
                <div>购买时间</div>
                <div>购买人</div>
                <div class="col-100">价格</div>
                <div class="col-100">购买个数</div>
                <div class="col-100">订单状态</div>
            </div>
            <div class="list-body">
                <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                    <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                    <div>{{his.member_name}}</div>
                    <div  class="col-100">{{his.goods_price}}</div>
                    <div  class="col-100">{{his.goods_num}}</div>
                    
                    <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import SiteForm from "../../components/SiteForm";
import { mapState } from "vuex";
import AddressSelect from "../../components/AdressSelect.vue";
import { export_json_to_excel } from "../../common/utils/Export2Excel"
import { dataTool } from "echarts";
export default {
  data() {
    return {
      hisBuyVis: false,
      siteBuyVis: false,
      goodsNum: 0,
      showinquiry: false,
      showInvoice: false,
      showVoucher: false,
      showAppreciation: false,
      checked: false,
      couponsValue: "",
      options: [
        {
          value: "选项1",
          label: "不使用优惠券",
        },
        {
          value: "选项2",
          label: "满减优惠券",
        },
        {
          value: "选项3",
          label: "现金券",
        },
      ],
      invoiceInput: "",
      registrationInput: "",
      personageInput: "个人",
      radio: "2",
      genre: "1",
      mold: "1",
      checkAll: false,
      dialogVisible: false,
      invoiceVis: false,
      invoiceAddVis: false,
      invoInfo: {},
      invoVal: "", 
      invoData: {
        // 个人还是企业
        inv_type: "个人",
        inv_title: "",
        inv_code: "",
        inv_reg_addr: "",
        inv_reg_phone: "",
        inv_reg_bname: "",
        inv_reg_baccount: "",
      },
      invoRules: {},
      cartList: [],
      buyerList:[],
      // 选中的操作员
      seleBuyers:[],
      // 购物车里的有货预购单商品
      purCarlist: [],
      // 地址列表
      siteList: [],
      olineWay: 1,
      payErweim: "",
      showMoreSite: false,
      set_type: "挂账",
      // "微信","支付宝",
      set_typeArr: [ "挂账"],
      inverId: "",
      buyer_remarks: "",
      // 订单信息
      orderData: {
        order_sn: "",
      },
      goods: [],
      timer: "",
      editSiteData: {},
      subSite: {},
      timerInter: "",
      leftTime: "",
      goods_id: "",
      // 倒计时
      h: 0,
      i: 23,
      s: 21,
      transport_type: "联盟默认大众物流(运费客户到付)",
      transportList: [
        "联盟默认大众物流(运费客户到付)",
        "顺丰加急(运费客户到付)",
        "四通八达(运费客户到付)",
      ],
      // 是否可以提交
      canSub: true,
      acSort: "",
      // 地址列表
      setList: [],
      companyName: "",
      registeredSite: "",
      depositBank: "",
      registrationMark: "",
      RegisteredTelephone: "",
      bankAccount: "",
      forbid: false,
      cease: false,
      taker_name: "",
      taker_cellphone: "",
      taker_site: "",
      taker_address: "",
      ischoose: "",
      upImgData: {
        store_id: this.$store.state.store_id,
      },
      zz_voucher: "",
      // 采购额度
      purQuota: "999999",
      submitbg: "#666", //结算按钮的颜色
      skuList: [],
      TipdialogVisible: false,
      subCartLists:[],
      orderLoading:false,//订单按钮加载
      showLoading:false,
      gooodsHisList:[],// 购买记录
      hisVis:false,// 购买记录的弹窗
    };
  },

  async created() {  
   
    // 预购单 按钮变灰
    this.$api.getAnalysisLog(this.$route.params.type == "2" ?"pre":'cart')
    this.submitbg = this.$route.params.type == "2" ? "#666" : "#3E84D6";
    // 如果是直营
    if (this.$store.state.for_branch) {
      let res = await this.$api.getPurQuota();
      if (res.pay_type == "现金") {
        // ,"微信"
        this.set_typeArr = ["对公转账", "支付宝"];
        this.set_type = "支付宝";
      } else if (res.pay_type == "挂账") {
        this.set_typeArr = ["挂账"];

        this.purQuota = res.purchase_quota_ok;
      } else {
        // "微信",
        this.set_typeArr = ["支付宝", "对公转账", "挂账"];
        this.purQuota = res.purchase_quota_ok;
      }
    }
    // 步骤 到哪一步了
    let arr = String(this.$route.params.step).split("_");

    this.$store.commit("UpDateState", {
      stepIndex: arr[0],
    });
    // 获取购物车数量
    this.$store.dispatch("getCartNums");
    // 如果是第二步就是秒杀
    if (this.$route.params.step == 2) {
      await this.getSiteList();
      let seckil = JSON.parse(localStorage.getItem("hnw-web-seckil-data"));
      let res2 = await this.$api.getGoodsInfo({
        goods_id: seckil.goods_id,
      });
      // 是否有限购
      if (seckil.upper_limit == 0) {
        this.goodsNum = seckil.goods_num;
      } else {
        this.goodsNum = seckil.upper_limit * 1;
      }
      this.cartList.push({
        data: [
          {
            goods: [
              {
                ...seckil,
                choose: true,
                goods_num: this.goodsNum,
                disa: true,
                oecode: res2.oecode,
                vehicletype: res2.vehicletype,
                sccode: res2.sccode,
                cart_type: 3,

                oenaturebrand: res2.oenaturebrand,
                goods_image: seckil.groupbuy_image,
                goods_price: seckil.groupbuy_price,
              },
            ],
          },
        ],
      });
    } else {
      // 如果有传过来的订单id 用于支付方式为非挂账的，订单生成，但未付款
      if (arr[1]) {
        let orderData = await this.$api.getOrderInfo({
          id: arr[1],
        }); 
        this.subSite = orderData.buyer_info;
        this.orderData = orderData.order_info;
        this.goods = orderData.goods;
        this.payErweim = orderData.order_info.qrcode;
        // 如果是支付宝等支付的

        // 倒计时
        this.leftTime = orderData.order_info.payment_time_over * 1000;
        this.set_type = orderData.order_info.set_type;
        if (this.set_type == "支付宝" || this.set_type == "微信") {
          this.countDown();
          this.setInterValFunc();
          let ms = 1;
          this.inverId = setInterval(async () => {
            let res1 = await this.$api.getPayRes(orderData.order_info.order_sn);

            ms++;
            if (res1.cus_id === "1") {
              // this.dialogVisible = true

              this.$store.commit("UpDateState", {
                stepIndex: 4,
              });

              clearInterval(this.inverId);
            } else {
              if (ms > 3600) {
                clearInterval(this.inverId);
                this.$erMsg("支付超时，二维码已失效");
                this.tempNum = 1;
              }
            }
          }, 1000);
        }
      } else {

        // 获取购物车列表
        this.cartList = await this.$api.getCartList({
          type: this.$route.params.type,
          shop_id: this.$store.state.shop,
        });  
        // 给购物车列表排序
        if(this.$store.state.is_sh=='0'){
          this.cartList.forEach(item => {
            item.data.forEach(store => {
              store.goods.sort((a,b) => {
                return Number(b.goods_stcids) - Number(a.goods_stcids)
              })
            })
          })
        }
        
        if (this.$store.state.shop == 1780) {
          this.cartList[0].data.forEach((goods) => {
            goods.goods.forEach((goods) => {
              this.$storageCangInfo(goods)
            });
          });
        } 
        
        
        //  如果是集单将价格改为集单价格
        if(this.cartList[0]){
            this.cartList[0].data.forEach((item) => {
            if (item.title == "集单") { 
              item.goods.forEach((goods) => {
                goods.goods_price = goods.subtotal;
              });
            }
          });
        }  
        
        this.handleCartGoods()
        

        if (this.$route.params.type == 1) {
          try {
            // 查询有库存预购单
            
            let purRes = await this.$api.getCartList({
              type: 2,
              shop_id: this.$store.state.shop,
            });
            if (this.$store.state.shop == 1780) {
              purRes[0].data.forEach((goods) => {
                goods.goods.forEach((goods) => {
                  this.$storageCangInfo(goods)
                });
              }); 
              if (purRes.length > 0) {
                if (purRes[0].data.length != 0) {
                  purRes[0].data[0].goods.forEach((goods) => {
                    if (goods.goods_storage > 0) {
                      this.purCarlist.push(goods);
                    }
                  });
                }
              }
            }

            

            if (this.purCarlist.length > 0) {
              // 如果购物车没有商品
              if (this.cartList.length == 0) {
                if (this.$store.state.for_branch) {
                  this.cartList = [
                    {
                      data: [],
                      area_info:
                        this.$store.state.shopInfo.shop_name ||
                        "江苏华诺威山东直营仓",
                      store_id: this.$store.state.for_branch,
                      store_name:
                        this.$store.state.shopInfo.shop_name ||
                        "江苏华诺威山东直营仓",
                      store_phone: "021-85620258",
                    },
                  ];
                } else {

                  this.cartList = [
                    {
                      data: [],
                      area_info: "上海-上海-嘉定区",
                      store_id: "1780",
                      store_name: "华诺威汽车零部件（上海）股份有限公司",
                      store_phone: "021-85620258",
                    },
                  ];
                }
              }

              if (
                this.$store.state.shop != 1974 &&
                this.$store.state.shop != 1871
              ) {
                this.cartList[0].data.unshift({
                  title: "预购单有货商品",
                  goods: this.purCarlist,
                });
              }
            }
            this.handleCartGoods();

            
          } catch (error) {
            console.log("查询预购单发生错误");
            
            console.log(error);
            
          }
          
        }
       
        this.$nextTick(()=>{
          if(this.cartList[0]&&this.cartList[0].data){
            this.cartList[0].data.forEach((item) => {
              item.goods.forEach((goods) => {
                let info = this.buyerList.find(buyItem=>buyItem.buyer_id==goods.member_id)
                console.log("info",info);
                
                this.$set(goods,"showGoods",true)
                if(!info){
                  this.buyerList.push({
                    buyer_id:goods.member_id,
                    buyer_name:goods.buyer_name
                  })
                }
              });
            });

            console.log("buyerList",this.buyerList);
            
          }
        })
      }
    } 
  },
  computed: {
    ...mapState(["stepIndex"]),

    subCartList: {
      get() {
        let list = [];

        this.cartList.forEach((store) => {
          store.data.forEach((goodsType) => {
            goodsType.goods.forEach((goods) => {
              if (goods.choose) {
                list.push(goods);
                // 对店铺商品进行处理
                goods.store_id = store.store_id || goods.store_id || "1780";
                goods.store_name =
                  store.store_name ||
                  goods.store_name ||
                  "华诺威汽车零部件（上海）股份有限公司";
              }
            });
          });
        });
        // 无货的商品留在购物车
        if (
          this.$store.state.shop != 1974 &&
          this.$store.state.shop != 1871 &&
          this.$store.state.shop != 1948
        ) {
          list = list.filter((item) => item.goods_storage != 0);
          // console.log("list", list);
        }
        return list;
      },
      set(newValue) {
        // console.log("newValue", newValue);
        this.subCartLists = newValue
      },
    },

    total() {
      // console.log("this.subCartLists",this.subCartLists)
      let num = 0;
      let price = 0;
      let carlist = this.subCartLists.length>0?this.subCartLists:this.subCartList
      carlist.forEach((goods) => {
        num = this.$hnwTools.plus(num, goods.goods_num);

        let temp = 0;
        if(goods.goods_price!="详询销售"){
          temp = this.$hnwTools.times(goods.goods_price, goods.goods_num);
        }
        price = this.$hnwTools.plus(price, temp);
        // price +=  *
      });

      price = price.toFixed(2);
      return {
        num,
        price,
      };
    },
    // 选中的地址
  },
  watch: {
    "invoData.inv_type": {
      deep: true,
      handler(n) {
        if (n == "个人") {
          this.invoRules = {
            inv_title: [
              { required: true, message: "请输入发票抬头", trigger: "blur" },
            ],
          };
        } else {
          this.invoRules = {
            inv_title: [
              { required: true, message: "请输入发票抬头", trigger: "blur" },
            ],
            inv_code: [
              {
                required: true,
                message: "请输入纳税人识别号",
                trigger: "blur",
              },
            ],
          };
        }
      },
    },
    invoiceAddVis(n) {
      if (!n) {
        for (let key in this.invoData) {
          if (key != "inv_type") {
            this.invoData[key] = "";
          }
        }
      }
    },
  },
  methods: {
    async readHisList(goods_id){
        this.$suMsg("请稍等")
        let res = await this.$api.getGoodsRecord(goods_id)
        this.gooodsHisList = res
        
        this.hisVis = true
    },
    handleClose(done) {}, 
    upAdress(info) {
      this.taker_site = info;
    },
    // 处理购物车商品

    handleCartGoods() {
      // console.log("this.cartList",this.cartList)
      //   return
      this.cartList.forEach((store) => {
        this.$set(store, "choose", false);
        // 华诺威商品处理
        if (store.store_id == 1780||store.store_id=='2197') { 
          store.data.forEach((type) => { 
            if (type.title) {
              type.goods.forEach((goods) => {
                // 处理不同的价格体系
                goods.goods_price = this.$handlePrice(goods);
               

                if (!goods.goods_num) {
                  goods.goods_num = 1;
                }
                // 如果0库存不能购买或者是买一送一
                if (
                  this.$store.state.zero_buy != 1 ||
                  goods.goods_commend == 2
                ) {
                  // 预采购 如果库存大于0 并且购买的数量大于库存数 将购买数量变成库存
                  if (
                    goods.goods_storage > 0 &&
                    Number(goods.goods_num) > Number(goods.goods_storage)
                  ) {
                    goods.goods_num = goods.goods_storage;
                    this.$api.changeCartNums({
                      car_id: goods.car_id,
                      goods_num: goods.goods_num,
                    });
                  }
                }
              });
            }
          });
        } else {
          // 非华诺威商品
          store.data.forEach((type) => {
            if (type.title) {
              type.goods.forEach(async (goods) => {
                if (type.title != "集单" && type.title != "秒杀") {
                  this.$handleStorePrice(goods);
                }

                if (goods.is_chain.includes("3")) {
                  this.$hnwTools.formatGoodsKey(goods);
                }
                // 如果是直营
                if (goods.is_chain.includes("3") && goods.goods_storage == 0) {
                  let res = await this.$api.getHnwStorage({
                    store_id: 1780,
                    sccode: goods.sccode,
                    oecode: goods.oecode,
                  });
                  if (res) {
                    // 直营商品的华诺威库存

                    this.$set(goods, "group_storage", res.goods_storage || 0);
                    // goods.group_storage = res.goods_storage || 0
                  }
                }
              });
            }
          });
        }
      });
 
    },
    upImgSuccessVoucher(res) {
      // this.applyData.receipt_images.push(res.data.url)
      this.zz_voucher = res.data.url;
    },
    async saveVoucher() {
      let res = await this.$http("index.php?act=store_cart&op=upload_voucher", {
        id: this.orderData.order_sn,
        url: this.zz_voucher,
      });
      if (res) {
        this.$suMsg("提交成功");
        this.$router.push("/my/order");
      }
    },
    select(item, index) {
      let subscript = index;
      if (item.choose) {
        this.companyName = item.inv_title;
        this.registeredSite = item.inv_reg_addr;
        this.depositBank = item.inv_reg_bname;
        this.registrationMark = item.inv_code;
        this.RegisteredTelephone = item.inv_reg_phone;
        this.bankAccount = item.inv_reg_baccount;
        this.forbid = true;
      } else {
        this.companyName = "";
        this.registeredSite = "";
        this.depositBank = "";
        this.registrationMark = "";
        this.RegisteredTelephone = "";
        this.bankAccount = "";
        this.forbid = false;
      } 
      this.hisBuyVis = false;
    },
    goaddress(item, index) {
      let subscript = index;
      this.ischoose = item.choose;
      if (item.choose) {
        this.taker_name = item.name;
        this.taker_site = item.provinces;
        this.taker_cellphone = item.mob_phone;
        this.taker_address = item.address;
        this.cease = true;
      } else {
        this.taker_name = "";
        this.taker_site = "";
        this.taker_cellphone = "";
        this.taker_address = "";
        this.cease = false;
      } 
    },
    clickInvoice(label) {
      if (label == 2) {
        this.genre = "1";
        this.mold = "1";
      }
    },
    async sortChange(type, way) {
      let sort_way = way;
      let sort_type = type;
      this.acSort = sort_type + sort_way;

      this.cartList = await this.$api.getCartList({
        sort_way: sort_way,
        sort_type: sort_type,
        type: this.$route.params.type,
        shop_id: this.$store.state.shop,
      });
      if (this.$route.params.type == 1) {
        if (this.purCarlist.length > 0) {
          if (
            this.$store.state.shop != 1974 &&
            this.$store.state.shop != 1871
          ) {
            this.cartList[0].data.unshift({
              title: "预购单有货商品",
              goods: this.purCarlist,
            });
          }
        }
      }
    },
    getGoodsInfo(goods_id) {
      // this.goods_id = goods_id;
      // this.dialogVisible = true;
    },
    deleteOne(car_id, store) {
      // this.$api.test()

      this.$affMsg(
        "确定删除该商品?",
        async () => {
          let res = this.$api.delCart([car_id]);

          if (res) {
            this.$suMsg("删除成功");
            store.goods = store.goods.filter((goods) => goods.car_id != car_id);
          } else {
            this.$erMsg("删除失败");
          }
        },
        () => {
          
        }
      );
    },
    clearCart() { 
      this.$affMsg(
        "确定要清空购物车",
        async () => {
          let cars = [];
          this.cartList.forEach((store) => {
            store.data.forEach((goodsType) => {
              goodsType.goods.forEach((goods) => {
                cars.push(goods.car_id);
              });
            });
          });
          let res = await this.$http("index.php?act=store_cart&op=cartDel", {
            cart: "all",
            member_id: this.$store.state.member_id,
            store_id: this.$store.state.store_id,
          });
          if (res) {
            this.$set(this, "cartList", []);
            this.$suMsg("清空完成");
          } else {
            this.$erMsg("清空失败");
          }
        },
        () => {
          
        }
      );
    },
    delCart() {
      let cars = [];
      this.cartList.forEach((store) => {
        store.data.forEach((goodsType) => {
          goodsType.goods.forEach((goods) => {
            if (goods.choose) {
              cars.push(goods.car_id);
            }
          });
        });
      });

      if (cars.length == 0) {
        return this.$warMsg("未选择任何商品!");
      }
      this.$affMsg(
        "确定要删除指定商品",
        async () => {
          let res = await this.$api.delCart(cars);

          if (res) {
            this.$suMsg("删除成功");
            this.cartList.forEach((store) => {
              store.data.forEach((goodsType) => {
                goodsType.goods = goodsType.goods.filter(
                  (goods) => !goods.choose
                );
                goodsType.goods.forEach((goods) => {
                  goods.choose = false;
                });
              });
            });
          } else {
            this.$suMsg("删除失败");
          }
        },
        () => {}
      );
    }, 
    handleCheckAllChange(val) {
      let erMsg = ""
      this.cartList.forEach((store) => {
        this.$set(store, "choose", val);
        store.data.forEach((goodsType) => {
          this.$set(goodsType, "choose", val);
          goodsType.goods.forEach((goods) => { 
            if(val){
              // ||goods.goods_price==0
              if(goods.goods_storage==0){
                erMsg+= goods.sccode+","
              }else{

                if(goods.showGoods){
                  this.$set(goods, "choose", val);
                }
              }
            }else{
              this.$set(goods, "choose", val);
            }

          });
        });
      });

      if (this.$route.params.type == "2") {
        this.submitbg = "#3E84D6";
      }
      if(this.$route.params.type==1&&erMsg.length>0){
        this.$message({
          message:erMsg+"库存或者价格为0",
          type:"warning",
          duration:5000,
          showClose:true
        })
      }
    },
    handleCheckStoreChange(store) { 
      store.data.forEach((goodsType) => {
        this.$set(goodsType, "choose", store.choose);
        goodsType.goods.forEach((goods) => {
          this.$set(goods, "choose", store.choose);
        });
      });
      this.checkAll = this.cartList.every((store) => store.choose);
      if (this.$route.params.type == "2") {
        // 点击的订单中如果存在无货 去结算按钮就变为 灰色
        store.data.forEach((goods) => {
          let checkGoods = goods.goods.filter((item) => item.choose == true);
          let flag = checkGoods.every((item) => item.goods_storage != "0");
          this.submitbg = flag && checkGoods.length > 0 ? "#3E84D6" : "#666";
        });
      }
    },
    handleCheckGoodsChange(store) { 
      store.choose = store.data.every((goodsType) => {
        goodsType.choose = goodsType.goods.every((goods) => goods.choose);
        return goodsType.choose;
      });
      
      this.checkAll = this.cartList.every((store) => store.choose);
      // console.log("store12",this.cartList,this.checkAll,store)
      if (this.$route.params.type == "2") { 
        // 点击的订单中如果存在无货 去结算按钮就变为 灰色
        store.data.forEach((goods) => { 
          let checkGoods = goods.goods.filter((item) => item.choose == true);
          let flag = checkGoods.every((item) => item.goods_storage != "0");
          this.submitbg = flag && checkGoods.length > 0 ? "#3E84D6" : "#666";
        });
      }
    }, 
    buyerChange(){
      console.log("seleBuyers",this.seleBuyers);
      
      if(this.cartList[0]&&this.cartList[0].data){
        // goods
        this.cartList[0].data.forEach(item=>{
          item.goods.forEach(goods=>{
            if(this.seleBuyers.length>0){
              let inclu = this.seleBuyers.find(sele=>sele==goods.member_id)
              if(!inclu){
                goods.showGoods = false
                goods.choose = false
              }else{
                goods.showGoods = true
              }
            }else{
              goods.showGoods = true
            }
          })
        })
      }
    },
    // 去结算
    async createOrder() {
      let flag = this.subCartList.every((item) => item.goods_storage != "0");
      console.log("this.$store.shop",this.$store.state)
      // 非华诺威
      if ( this.$store.state.shop == 1974 || this.$store.state.shop == 1871 || this.$store.state.shop == 1948 ) {
        if (this.subCartList.length == 0) {
          return this.$erMsg("未选择任何商品!");
        }
        let storeids = [];
        this.subCartList.forEach((goods) => {
          let s = storeids.find((item) => item == goods.store_id);
          if (!s) {
            storeids.push(goods.store_id);
          }
        });
        if (storeids.length > 1) {
          return this.$message({
            message: "当前提交商家数过多，每次提交单个商家商品",
            type: "warning",
            showClose: true,
            duration: 5000,
          });
        }
        if (this.$store.state.for_branch) {
          if (this.$store.state.transpor_type == "同汽配城") {
            this.transportList = ["同汽配城(送货上门)"];
          } else if (this.$store.state.transpor_type == "同市区") {
            this.transportList = ["同城快递(商家配送)"];
          } else {
            this.transportList = ["其他市区(快递配送)"];
          }
          this.transport_type = this.transportList[0];
        }
        let collectList = [];

        this.subCartList.forEach((goods) => {
          if (goods.cart_type == 2) {
            let p = new Promise(async (resolve) => {
              let res = await this.$api.getCollectBuyerNum(goods.setlist_id);
              resolve(res);
            });
            collectList.push(p);
          }
        });
        let collectRes = await Promise.all(collectList);

        let notStr = "";
        this.subCartList.forEach((goods, index) => {
          if (goods.cart_type == 2) {
            let temp = collectRes.find(
              (collect) => collect.setlist_id == goods.setlist_id
            );
            if (
              Number(temp.buy_quantity) + Number(goods.goods_num) >
                Number(temp.buy_total) &&
              temp.asnumover == 1
            ) {
              this.$set(goods, "choose", false);
              notStr += temp.setlist_name + " ";
            }
          }
        });
 
        scrollTo(0, 0);
        if (notStr.length > 0) {
          this.canSub = true;
          this.checkAll = false;

          this.$affMsg(
            notStr + "超出可购买数量,是否继续购买？",
            async () => {
              if (this.subCartList.length < 1) {
                return this.$erMsg("当前没有可购买的商品");
              }
              await this.getSiteList();
              this.$store.commit("UpDateState", {
                stepIndex: 2,
              });
            }
          );
        } else {
          await this.getSiteList();

          this.$store.commit("UpDateState", {
            stepIndex: 2,
          });
        }
      // 触发setter
      this.subCartList = this.subCartList.filter(item => item.skuId!='') 
      } else {
        if (flag) {
          if (this.subCartList.length == 0) {
            return this.$erMsg("未选择任何商品!");
          }

          let storeids = [];
          let skuId = [];
          this.subCartList.forEach((goods) => {
            skuId.push(goods.skuId);
            let s = storeids.find((item) => item == goods.store_id);
            if (!s) {
              storeids.push(goods.store_id);
            }
          });
           
          if(this.$store.state.shop==1780){
            let shopId = this.subCartList.every(item => item.store_id=='2197')?'2197':this.$store.state.shop 
            let res = await this.$http("index.php?act=store_cart&op=stock_cart", {
              shop_id: shopId,
              skuId: skuId.join(","),
            });
              if (res.length > 0) {
              this.subCartList.forEach((item, index) => {
                try{
                    var stock = res.find((s) => s.skuId == item.skuId);  
                    if(stock.store_id!='2197'){ 
                      var house = JSON.parse(stock.house_info).find((s) => s.warehouseCode == "丹阳仓");
                      // 处理丹阳仓库存 
                      if ( this.$store.state.is_sh == '0'&&stock.skuId == item.skuId &&house&& item.goods_num>house.qty) {
                        this.subCartList[index].goods_num = house.qty
                        this.subCartList[index].modifyTip = "该配件超出库存已修改为实际库存"
                        this.TipdialogVisible = true;
                      }else if(this.$store.state.is_sh == '1'&&stock.skuId == item.skuId && item.goods_num>stock.goods_storage){
                        this.subCartList[index].goods_num = stock.goods_storage
                        this.subCartList[index].modifyTip = "该配件超出库存已修改为实际库存"
                        this.TipdialogVisible = true;
                      }
                    } 
                    // 联盟配件
                    if(stock.store_id=='2197'&&stock.skuId == item.skuId && item.goods_num>stock.goods_storage){
                      this.subCartList[index].goods_num = stock.goods_storage
                      this.subCartList[index].modifyTip = "该配件超出库存已修改为实际库存"
                      this.TipdialogVisible = true;
                    }
                     
                  }catch(e){ 
                  }
                }); 
              let arr = []
              let temData = ""
              res.forEach((item,index) => { 
                if(item.store_id!='2197'){
                    var house = JSON.parse(item.house_info).find((s) => s.warehouseCode == "丹阳仓");
                    if(item.goods_storage==0&&this.$store.state.is_sh=='1'){ 
                      temData = this.subCartList.find(items => items.skuId == item.skuId) 
                      arr.push(temData)
                    }else if(house!=undefined&&house.qty==0&&this.$store.state.is_sh=='0'){
                      temData = this.subCartList.find(items => items.skuId == item.skuId) 
                      arr.push(temData)
                    }
                }
                if(item.store_id=='2197'&&item.goods_storage=='0'){
                  
                  temData = this.subCartList.find(items => items.skuId == item.skuId) 
                  arr.push(temData) 
                }
                
              })
              // 触发setter
              this.subCartList = this.subCartList.filter(item => item.skuId!='') 
              arr.forEach(item => { 
                this.subCartLists = this.subCartLists.filter(goods => goods.skuId != item.skuId)  
              }) 
               
            }
          }else{
            // 触发setter
            this.subCartList = this.subCartList.filter(item => item.skuId!='')
          } 
          
          if (this.$store.state.for_branch) {
            if (this.$store.state.transpor_type == "同汽配城") {
              this.transportList = ["同汽配城(送货上门)"];
            } else if (this.$store.state.transpor_type == "同市区") {
              this.transportList = ["同城快递(商家配送)"];
            } else {
              this.transportList = ["其他市区(快递配送)"];
            }
            this.transport_type = this.transportList[0];
          }
          console.log("total",Number(this.total.price),this.$store.state)
          // 获取订单金额是否小于1500的限制
          let resAcc = await this.$api.getAccMsg({
              member_id:this.member_id,
              type:this.$store.state.role_name=='all'?1:2
          })
          if(this.$store.state.shop==1780&&Number(this.total.price)<1500&&resAcc.store_info.is_limit==1){
            return this.$message({
              message: "订单总金额小于1500无法提交",
              type: "warning",
              showClose: true,
              duration: 5000,
            });
          } 
          let collectList = [];
          this.subCartLists.forEach((goods) => {
            if (goods.cart_type == 2) {
              let p = new Promise(async (resolve) => {
                let res = await this.$api.getCollectBuyerNum(goods.setlist_id);
                resolve(res);
              });
              collectList.push(p);
            }
          })

          let collectRes = await Promise.all(collectList);

          let notStr = "" ;
          
          this.subCartLists.forEach((goods, index) => {
            if (goods.cart_type == 2) {
              let temp = collectRes.find(
                (collect) => collect.setlist_id == goods.setlist_id
              );
              if (
                Number(temp.buy_quantity) + Number(goods.goods_num) >
                Number(temp.buy_total) &&
                temp.asnumover == 1
              ) {
                this.$set(goods, "choose", false);
                notStr += temp.setlist_name + " ";
              }
            }
          })
          scrollTo(0, 0);
          if (notStr.length > 0) {
            this.canSub = true;
            this.checkAll = false;

            this.$affMsg(
              notStr + "超出可购买数量,是否继续购买？",
              async () => {
                if (this.subCartLists.length < 1) {
                  return this.$erMsg("当前没有可购买的商品");
                }
                await this.getSiteList();
                this.$store.commit("UpDateState", {
                  stepIndex: 2,
                });
              },
              () => {
                // this.$infoMsg("已取消")
              }
            );
          } else {
            await this.getSiteList();

            this.$store.commit("UpDateState", {
              stepIndex: 2,
            });
          }
        } else {
          return this.$erMsg("存在无货商品，不能下单");
        }
      }
    },
    async subOrder() { 
      
      if (this.$store.state.store_id == 1780) {
        return this.$erMsg("总部不可下单");
      }
      

      if (!this.subSite.true_name) {
        return this.$erMsg("未选择地址!");
      }

      if (this.$store.state.for_branch && this.set_type == "挂账") {
        if (parseInt(this.total.price) > parseInt(this.purQuota)) {
          return this.$erMsg("额度不足");
        }
      }
      // 处理0价格
      let priceErrMsg = ""
     
      if (this.subCartLists.length == 0) {
        this.$store.commit("UpDateState", {
          stepIndex: 1,
        });
        return this.$erMsg("当前没有可购买的商品");
      }
      this.orderLoading = true
      this.showLoading = true
      
      if(!this.canSub){
        this.orderLoading = false
        this.showLoading = false
      	this.$warMsg("请勿重复点击")
      	return
      }
      if(priceErrMsg.length==0){
        if(this.$store.state.shop==1780){
          if(this.subCartLists.length>0&&this.subCartLists.length!=this.subCartList.length){
            this.$warMsg("无货商品添加到购物车")
          } 
        } 
      }
      this.canSub = false;
      let chainID = "";
      if (this.$store.state.shop_type == 1) {
        chainID = 1;
      } else if (this.$store.state.shop_type == 2) {
        chainID = 3;
      } else if (this.$store.state.shop_type == 3) {
        chainID = 4;
      }

      let subData = {
        set_type: this.set_type,
        chain: chainID,
        shop_type: this.$store.state.shop_type,
        zero_buy: this.$store.state.zero_buy,
        store: [],
        data: {
          // 传的发票信息
          whether: this.radio,
          invoice: this.genre,
          partnertype: this.mold,
          company_name: this.companyName,
          registered_address: this.registeredSite,
          bank_of_deposit: this.depositBank,
          registration_number: this.registrationMark,
          registered_telephone: this.RegisteredTelephone,
          bank_account: this.bankAccount,
          taker_name: this.taker_name, //收票人姓名
          taker_phone: this.taker_cellphone, //收票人电话
          taker_site: this.taker_site, //收票人地址
          detailed_address: this.taker_address, //详细地址
          invoice_title: this.genre == 2 ? "" : this.personageInput, //个人发票抬头
          company_invoice_title: this.invoiceInput, //单位发票抬头
          company_registration_number: this.registrationInput, //单位信用代码/注册号
        },
      };
      if (this.$store.state.than) {
        subData.than = this.$store.state.than;
      }
      let temp = {};

      temp.total_sum = this.total.price;

      temp.buyer_id = this.$store.state.store_id;

      temp.buyer_name = this.$store.state.store_name;

      temp.member_name = this.$store.state.member_name;
      temp.member_id = this.$store.state.member_id;
      temp.address_id = this.subSite.address_id;
      // 地址
      temp = {
        ...temp,
        ...this.subSite,
      };
      // 商家信息和地址商家id
      temp.store_id = this.subCartLists[0].store_id;

      temp.store_name = this.subCartLists[0].store_name;
      // 联盟订单，暂时不用看
      if (temp.store_id != 1780 && this.$store.getters.notLvD) {
        subData.chain = 2;
      }

      let isZy = this.subCartLists.some(
        (goods) => goods.is_chain && goods.is_chain.includes("3")
      );

      if (isZy) {
        subData.chain = 3;
      } 
      // 发票
      temp.invoice_type = "增值票";
      temp.take_type = "发货";

      temp.buyer_remarks = this.buyer_remarks;

      temp.goods_list = [];
      let collectList = [];

      this.subCartLists.forEach((goods) => {
        if (goods.cart_type == 2) {
          let p = new Promise(async (resolve) => {
            let res = await this.$api.getCollectBuyerNum(goods.setlist_id);
            resolve(res);
          });
          collectList.push(p);
        }
      });
      let collectRes = await Promise.all(collectList);

      let notStr = "";
      // 如果集单限购 当购买数量大于可购买数量时，重置选中状态
      this.subCartLists.forEach((goods, index) => {
        if (goods.cart_type == 2) {
          let temp = collectRes.find(
            (collect) => collect.setlist_id == goods.setlist_id
          );
          if (
            Number(temp.buy_quantity) + Number(goods.goods_num) >
              Number(temp.buy_total) &&
            temp.asnumover == 1
          ) {
            this.$set(goods, "choose", false);
            notStr += temp.setlist_name + " ";
          }
        }
      });

      if (notStr.length > 0) {
        this.canSub = true;
        this.checkAll = false;
        this.$affMsg(
          notStr + "超出可购买数量,是否继续购买？",
          async () => {
            this.tidySubOrder(temp, subData);
          },

          () => {
            if (this.subCartLists.length < 1) {
              this.$store.commit("UpDateState", {
                stepIndex: 1,
              });
              this.canSub = true;
              this.$erMsg("当前没有可购买的商品");
            }
          }
        );
      } else {
        this.tidySubOrder(temp, subData);
      }
    },
    // 整理数据并提交订单
    async tidySubOrder(temp, subData) {
      // 获取订单金额是否小于1500的限制
      let resAcc = await this.$api.getAccMsg({
          member_id:this.member_id,
          type:this.$store.state.role_name=='all'?1:2
      })
      if(this.$store.state.shop==1780&&Number(this.total.price)<1500&&resAcc.store_info.is_limit==1){
        this.orderLoading = false
        this.showLoading = false
        return this.$message({
          message: "订单总金额小于1500无法提交",
          type: "warning",
          showClose: true,
          duration: 5000,
        });
      } 
      // console.log("temp,subData,this.subCartLists,this.subCartList",temp,subData,this.subCartLists,this.subCartList)
      // return
      
      
      let houseList = [];
      if (this.subCartLists.length < 1) {
        this.$store.commit("UpDateState", {
          stepIndex: 1,
        });
        this.canSub = true;
        return this.$erMsg("当前没有可购买的商品");
      }
      // 新加四个价格 group_storage 总部数量
      let goodsAttrs = [
        "car_id",
        "goods_id",
        "store_id",
        "store_name",
        "sccode",
        "oecode",
        "goods_name",
        "goods_price",
        "goods_num",
        "groupbuy_id",
        "setlist_id",
        "house",
        "houseId",
        "taxUntPrice",
        "taxWebPrice",
        "sellPrice1",
        "taxSellPrice2",
      ]; 
      this.subCartLists.forEach((goods) => {
        // goods.goods_price = 0
        let obj = {};

        if (this.$store.state.shop == 1780) { 
          var stockInfo = this.$hnwTools.getStockInfo(
            goods["goods_num"],
            this.$store.state.is_sh,
            goods["house_info"]
          );
          if (stockInfo) {
            obj.warehouse = stockInfo;
          }
        }   
        goodsAttrs.forEach((key) => {
          obj[key] = goods[key];
        }); 
        obj.order_type = goods.cart_type;
        try { 
          obj.storage = goods.goods_storage || "0";
        } catch (e) { 

        }
        
        // 买一送一
        if (goods.goods_commend == 2) { 
          // console.log("含买一送一商品", obj.goods_name);
        } 
        // 分仓逻辑
        if (obj.order_type == 1) {
          if (obj.house && obj.house != "0") {
            let houseObj = houseList.find((item) => item.house == obj.house);

            if (houseObj) {
              houseObj.goods_list.push(obj);
            } else {
              houseList.push({
                house: obj.house,
                houseId: obj.houseId,
                goods_list: [obj],
              });
            }
          } else {

            temp.goods_list.push(obj);
          }
        } else {
          temp.goods_list.push(obj);
        }
      }); 


      let res = "";
      subData.store.push(temp); 
      if (this.$store.state.for_branch) {
        subData.store[0].transport_type = this.transport_type;
      } 
      if (temp.goods_list.length > 0) {
        
        
        res = await this.$api.createOrder(subData);
        this.orderLoading = false
        this.showLoading = false

        if (!res) {
          return this.$message({
            showClose: true,
            message: '订单生成失败',
            duration:0,
            type: 'error'
          });
          // return this.$erMsg("订单生成失败");
        }
      } 
       
      this.orderData.id = res.id;
      // 更新购物车数量
      this.$store.dispatch("getCartNums");
      if (this.set_type == "微信" || this.set_type == "支付宝") {
        this.$store.commit("UpDateState", {
          stepIndex: 3,
        });

        // 倒计时
        this.leftTime = new Date().getTime() + 60 * 60 * 1000;

        this.countDown();
        this.setInterValFunc();

        this.payErweim = res.url;
        this.orderData.order_sn = res.outTradeNo;
        let ms = 1;


        this.inverId = setInterval(async () => {
          let res1 = await this.$api.getPayRes(res.outTradeNo);

          ms++;
          if (res1.cus_id === "1") {
            // this.dialogVisible = true

            this.$store.commit("UpDateState", {
              stepIndex: 4,
            });

            clearInterval(this.inverId);
          } else {
            if (ms > 3600) {
              clearInterval(this.inverId);
              this.$erMsg("支付超时，二维码已失效");
              this.tempNum = 1;
            }
          }
        }, 1000);

        scrollTo(0, 0);
      } else if (this.set_type == "对公转账") {
        this.orderData.order_sn = res.orderId;
        this.$store.commit("UpDateState", {
          stepIndex: 3,
        });
      } else {

        if (res.message == "success") {
          this.$suMsg("提交成功");
        } else {
          this.$suMsg(res.message);
        } 
        this.$router.push("/my/order");
      }
    },
    seleSite(site) {
      this.siteList.forEach((site) => {
        this.$set(site, "choose", false);
      });
      this.$set(site, "choose", true);
      this.showMoreSite = false;
      this.subSite = site;
    },
    edit(site) {
      this.editSiteData = site;

      this.$refs.siteform.AddressVis = true;
    },
    async del(address_id) {
      this.$affMsg(
        "确定删除该地址?",
        async () => {
          let res = await this.$api.delAddr(address_id);
          if (res) {
            this.getSiteList();
            this.$suMsg("删除成功");
          } else {
            this.$erMsg("删除失败");
          }
        },
        () => {
          
        }
      );
    },
    async defa({ address_id }) {
      let res = await this.$api.defaAddr(address_id);
      if (res) {
        this.$suMsg("设置成功");
        this.getSiteList();
      } else {
        this.$erMsg("设置失败");
      }
    },
    closed() {
      this.editSiteData = {};
    },
    changeCartNums(goods) { 
      this.$api.changeCartNums({
        car_id: goods.car_id,
        goods_num: goods.goods_num,
      });
    },

    impueNumChange(goods) { 
      if (isNaN(goods.goods_num)) {
        this.$nextTick(() => {
          this.$set(goods, "goods_num", 1);
        });
      }
      if (
        this.$store.state.shop != 1974 &&
        this.$store.state.shop != 1871 &&
        this.$store.state.shop != 1948
      ) {
        // 如果是直营
        if (goods.is_chain.includes("3")) {
          if (
            Number(goods.goods_storage) > 0 &&
            Number(goods.goods_num) > Number(goods.goods_storage)
          ) {
            this.$nextTick(() => {
              goods.goods_num = goods.goods_storage;
            });
            return this.$store.commit("UpDateState", { invDia: true });
          } else if (
            Number(goods.group_storage) > 0 &&
            goods.goods_num > Number(goods.group_storage)
          ) {
            this.$nextTick(() => {
              goods.goods_num = Number(goods.group_storage);
            });
            return this.$store.commit("UpDateState", { invDia: true });
          } else {
            this.$api.changeCartNums({
              car_id: goods.car_id,
              goods_num: goods.goods_num,
            });
          }
        } else if (
          Number(goods.goods_num) > Number(goods.goods_storage) &&
          Number(goods.goods_storage) > 0
        ) {
          this.$nextTick(() => {
            goods.goods_num = goods.goods_storage;
            this.$api.changeCartNums({
              car_id: goods.car_id,
              goods_num: goods.goods_num + "",
            });
          });
          return this.$store.commit("UpDateState", { invDia: true });
          // goods.goods_num = goods.goods_storage
          // return
        } else {
          this.$api.changeCartNums({
            car_id: goods.car_id,
            goods_num: goods.goods_num + "",
          });
        }
      }
    },
    async getSiteList() {
      let temp = await this.$api.getAddrList();
      this.siteList = temp.data;

      if (this.subSite.true_name) {
        this.siteList.forEach((site) => {
          this.$set(site, "choose", false);
          if (site.address_id == this.subSite.address_id) {
            this.$set(site, "choose", true);
          }
        });
      } else {
        this.siteList.forEach((site) => {
          this.$set(site, "choose", false);
          if (site.is_default == 1) {
            this.$set(site, "choose", true);
            this.subSite = site;
          }
        });
      }
    },
    setInterValFunc() {
      this.timerInter = setInterval(() => {
        this.countDown(this);
      }, 1000);
    },
    countDown() {
      let leftTime = this.leftTime - new Date();

      let hours = 0,
        minutes = 0,
        seconds = 0;
      if (leftTime > 0) {
        hours = parseInt(leftTime / 1000 / 60 / 60, 10);
        minutes = parseInt((leftTime / 1000 / 60) % 60, 10);
        seconds = parseInt((leftTime / 1000) % 60, 10);
      } else {
        // clearTimeout(this.timerInter);
        clearInterval(this.timerInter);
      }
      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.h = hours;
      this.i = minutes;
      this.s = seconds;
    },
    async exGoodsHhj(){
      let tHeader = ["组id","OE号"]
      
    },
    async exGoodsList(){
            // 表头
        
        let tHeader = ["商品名称","OE号","生产编码","配件品牌","订货价","订货数量","适配车型","总计","操作员"]
        let filterVal = []
        
        tHeader.forEach(title=>{
          let key = this.$hnwTools.getGoodsKey(title)

          if(key){
            filterVal.push(key)
          }else{
            switch(title){
              case "订货数量":
                filterVal.push("goods_num")
                break;
              case "总计":
                filterVal.push("goodspr_total")
                break;
              case "操作员":
                filterVal.push("buyer_name")
                break;
            }
          }
        })
        console.log("filterVal1",filterVal);
        let msg =""
        try {
            msg = this.$message({
                message:'正在导出中....请不要离开本页面',
                type: 'warning',
                duration:0
            })
            let exList = []
            try {
              if(this.cartList[0].data[0].goods){
                exList = this.cartList[0].data[0].goods.filter(goods=>goods.showGoods)
              }
            } catch (error) {
              
            }
            console.log("exList",exList);
            
            if(exList.length==0){
              this.$warMsg("预购单商品为空")
            }
            exList.forEach(goods=>{
              if(goods.goods_price!="详询销售"){
                goods.goodspr_total = this.$hnwTools.times(goods.goods_num,Number(goods.goods_price))
              }
            })
            let data = this.formatJson(filterVal,exList)
            // this.$store.state.store_name+"-"+this.$store.state.member_name +"-预采购商品"
            let filename = "预购单列表"
            console.log("tHeader",tHeader)
            console.log("data",data)
            export_json_to_excel({
                header:tHeader,
                data,
                filename,
                autoWidth:true,
                bookType:'xlsx'
            })
            msg.close()
        } catch (error) {
          this.$warMsg("error",error)
          this.$warMsg("导出失败")
          msg.close()
        }
        
        
    },
    formatJson(filterVal, tableData) {
        return tableData.map(v => {

            return filterVal.map(j => {
                    return v[j]
                })
            }
        )
    }
  },
  beforeDestroy() {
    clearInterval(this.inverId);
  },
  components: {
    SiteForm,
    AddressSelect,
  },
};
</script>

<style   lang="scss"  scoped>
.gray {
  background: #ccc;
}
.red {
  color: #ec5151;
}
.white {
  background: #fff;
}
.shop-car {
  overflow: hidden;
  border-top: 10px solid #F9F9F9;
  .active-text {
    color: #3E84D6;
  }
  ::v-deep .el-checkbox__inner{
    width: 18px;
    height: 18px;
    &:after{
      height: 9px;
      left: 6px;
    }
  }
  .el-header {
    padding: 0;
    // 减去了滚动条的宽度
    background: $gray;
    .hnw-header {
      height: 100%;
      padding: 12px 0;

      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .shu {
        height: 14px;
        width: 1px;
        background: #333333;
        margin: 0 10px;
      }
      .logo {
        display: flex;
        align-items: center;
        // width: 60px;
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        img {
          margin-right: 4px;
        }
        div {
          cursor: pointer;
        }
      }
      .header-right {
        display: flex;
        align-items: center;
        .user-avatar {
          position: relative;
          top: -3px;
        }
        .my-hnw {
          padding-left: 3px;
          position: relative;
          top: -3px;
          margin-right: 5px;
        }
        .el-menu {
          background: transparent;
          .shu {
            float: left;
          }
          ::v-deep .el-submenu {
            .is-active {
              .el-submenu__title {
                border-bottom: none;
              }
            }
            ::v-deep .el-submenu {
              .el-submenu__title {
                padding: 0;
                line-height: 14px;
                color: #333;
                height: 14px;
                width: 126px;
                flex-shrink: 0;
                background: transparent;
                &:hover {
                  background: transparent;
                }
              }
            }
            ::v-deep i {
              margin-left: 0;
            }
            .el-menu--horizontal {
            }
          }
          ::v-deep .el-menu-item {
            height: 14px;
            line-height: 14px;
            color: #333;
            padding: 0;
            border: none;
            width: 70px;
            flex-shrink: 0;
            background: transparent;
            &:hover {
              background: transparent;
            }
          }
          .no-r {
            padding-right: 0;
          }
          ::v-deep .el-menu-item.is-active {
            border: none;
          }
        }
        
      }
    }
  }

  // 购物车起始页
  .car-body-box {
   
    background:#F9F9F9;
    .car-content {
      margin: 0 auto;
      background: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      .goods-right {
        display: flex;
        align-items: center;
        flex: 1; 
        
        
        .goods-storage {
          width: 120px;
          .font-red {
            color: red;
          }

        }
        .ico {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > i {
            cursor: pointer;
          }
          i:hover {
            transform: scale(1.5);
            color: #3E84D6;
          }
          .el-icon-caret-top {
            position: relative;
            top: 3px;
          }
          .el-icon-caret-bottom {
            position: relative;
            top: -3px;
          }
          .active {
            color: #3E84D6;
          }
        }
        .goods-price {
          width: 160px;
        }
        .spec {
          width: 100px;
          text-align: center;
        }

        .total {
          width: 100px;
        }
        .handle {
          width: 50px;
          span {
            cursor: pointer;
          }
        }

        .num {
          width: 140px;
          text-align: center;

          box-sizing: border-box;
          position: relative;
          .goods-zbyh-tit {
            position: absolute;
            left: 50%;
            // top: -20px;
            bottom: -20px;
            width: 200px;
            transform: translateX(-50%);
            text-align: center;

            padding-left: 10px;
            // color: #999;
            color: red;
          }
          .goods-Fcl-tit {
            position: absolute;
            bottom: -22px;
            left: 50%;

            transform: translateX(-50%);
            text-align: center;

            width: 400px;
            color: red;
          }
        }
        .buyerName {
          width: 100px;
          .el-button {
            border: none;
            background: none;
            color: black;
            font-family: none;
          }
        }
      }
      .cart-tit {
        // width: 130px;
        min-height: 24px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 18px;
        color: #002A78;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 10px;
        .tit-text {
          // border-bottom: 2px solid #3E84D6;
        }
        .ex-btn{
         margin:5px 10px
        }
      }
      .list-head {
        height: 38px;
        box-sizing: border-box;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        background: #f7f7f7;
        justify-content: space-between;
        .list-head-left {
          // width: 330px;
          width: 458px;
          display: flex;
          .goods-show {
            // min-width: 190px;
            // max-width: 190px;
            flex: 1;
            overflow: hidden;
            text-align: center;
          }
        }
        .goods-right{
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
  
          }

        }
      }
      .list-body {
        .store-row {
          margin-top: 10px;
          .list-store {
            padding: 10px 16px;
            border-bottom: 1px solid #9e9e9e;
            display: flex;
            align-items: center;
            background: #f7f7f7;
            .company {
              font-size: 14px;
              color: #333;
              font-weight: 700;
              margin-right: 10px;
            }
            .phone {
              font-size: 14px;
              color: #666;
              padding-right: 20px;
              img {
                width: 14px;
                height: 14px;
              }
            }
            .store-check {
              margin-right: 8px;
            }
          }
          .cart-type-item {
            .cart-type-item-title {
              padding: 10px 16px;
              border-bottom: 1px solid #f2f2f2;
              display: flex;
              .type-title {
                display: flex;
                align-items: center;

                .type {
                  background: #0D347F;
                  color: #fff;
                  padding: 0 8px;
                  height: 24px;

                  line-height: 24px;
                  margin-left: 24px;
                  text-align: center;
                  border-radius: 3px;
                }
              }
            }
            .cart-type-item-goods {
              font-size: 14px;
              position: relative;
              // 买一送一提示
              .clear-tip {
                position: absolute;
                margin-left: 10px;
                color: red;
                font-size: 12px;
                line-height: 12px;
                right: 182px;
                bottom: 10px;
              }
              .goods-item {
                border-bottom: 1px solid #f2f2f2;
                display: flex;
                padding: 10px 16px;
                text-align: center;
                .goods-left {
                  // width: 330px;
                  width: 458px;
                  display: flex;
                  // 预购单无库存商品
                  .first {
                    // width: 140px;
                    display: flex;
                    align-items: center;
                    .img-box {
                      width: 100px;
                      height: 100px;
                      border: 1px solid #ededed;
                      margin-left: 10px;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    .check{
                      
                    }
                  }

                  .goods-show {
                    // width: 260px;
                    flex: 1;
                    overflow: hidden;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;

                    .cur {
                      cursor: pointer;
                    }
                    ul {
                      margin-left: 10px;
                      li {
                        font-size: 14px;
                        text-align: center;
                        display: flex;
                        .brand {
                          // background: #3E84D6;
                          // color: #fff;
                          border-radius: 4px;
                          border: 1px solid #0D347F;
                          color: #0D347F;
                          font-size: 12px;
                          min-width: 40px;
                          height: 18px;
                          padding: 2px;
                          line-height: 18px;
                          text-align: center;
                        }
                        .xname {
                          margin: 0 8px;
                          white-space: nowrap;
                          overflow-x: hidden;
                          text-overflow: ellipsis;
                          // display: flex;

                        }
                        .name {
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      }
                      .gary-text {
                        color: #999;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      .middle {
                        padding: 10px 0;
                      }
                    }
                  }
                }
                

                .red {
                  color: #f00;
                }
              }
              .nonstock{
                opacity: 0.8;
                filter: grayscale(100%);
                .red{
                  .goods-storage{
                    opacity: 1;
                    filter: grayscale(0);
                  }
                }
              }
              .purchase-record{
                position: absolute;
                bottom: 5px; 
                right: 10px;
                cursor: pointer;
                height: 20px;
                color: red;
                text-align: right;
                line-height: 20px;
                font-size: 12px; 
                // background: #f5f5f5;
                padding-right: 100px;
                box-sizing: border-box;
              }
              
            }
          }
        }
        // .store-row:first-child{
        // 	margin: 0;
        // }
      }
    }
    .bot-confirm {
      box-shadow: 0px -2px 18px 0px rgba(0,0,0,0.05);
      height: 80px;
      display: flex;
      align-items: center;
      padding-left: 36px;
      box-sizing: border-box;
      background: #fff;
      .bot-row {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bot-left {
          display: flex;
          align-items: center;
          .list-t2-le{
            
          }
          .el-checkbox {
            position: relative;
            top: 1px;
          }
          .delGoods {
            
            color: #666666;
            cursor: pointer;
            width: 116px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #E4E4E4;
            border-radius: 15px;
            margin-left: 15px;
          }
          .clearCart {
            width: 116px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border: 1px solid #0D347F;
            cursor: pointer;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #0D347F;
            text-align: center;
            margin-left: 20px;
          }
        }
        .bot-rig {
          display: flex;
          align-items: center;
          .submit {
            // width: 216px;
            // height: 59px;
            // background: #3E84D6;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 154px;
            height: 56px;
            background: #F83431;
            border-radius: 6px;
            cursor: pointer;
            img{
              width: 22px;
              margin-right: 4px;
            }
            span {
              width: 74px;
              height: 23px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
             
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
            }
          }
          .bot-text{
            font-size: 14px;
            font-family: Source Han Sans CN;
            color: #F83431;
          }
          .big{
           
            font-weight: bold;
            font-size: 21px;
          }
        }
      }
    }
  }
  // 提交订单页
  .order-msg-handle {
    .msg-title {
      color: #666;
      font-size: 16px;
      border-bottom: 1px solid #f1f1f1;
      padding: 10px 0;
      font-weight: bold;
    }

    // 每个模块
    .order-msg-handle-item {
      border-bottom: 1px solid #f1f1f1;
      .order-msg-title {
        padding: 10px 20px;
        box-sizing: border-box;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        .addAdress {
          color: #3E84D6;
          span {
            cursor: pointer;
            font-size: 14px;
          }
        }
        .font18 {
          font-size: 18px;
        }
      }
      .invoice-list {
        display: flex;
        font-size: 14px;
        color: #666;
        height: 56px;
        line-height: 56px;
        .inquiry {
          width: 140px;
          padding-left: 18px;
          // padding-right: 56px;
        }
        .input {
          .el-input {
            width: 260px;
            .el-input__inner {
            }
          }
        }
        .el-radio {
          width: 100px;
          margin-right: 0;
        }
      }
      .appreciation {
        padding-bottom: 30px;
        border-bottom: 1px dashed #f0f0f0;
        .appreciation-msg {
          height: 36px;
          line-height: 36px;
          background: #fcebeb;
          padding-left: 20px;
          color: #ff2020;
          margin-bottom: 10px;
        }
        .dedicated-body {
          .dedicated {
            height: 287px;
            .dedicated-msg {
              height: 48px;
              line-height: 48px;
              background-color: #f2f2f2;
              display: flex;
              justify-content: space-between;
              padding: 0 10px;
            }
            .dedicated-from {
              height: 241px;
              display: flex;
              padding: 40px 0 0 100px;
              box-sizing: border-box;
              .from-l {
                width: 50%;
                height: 100%;
                .el-input {
                  width: 320px;
                }
                div {
                  margin-top: 10px;
                  span {
                    width: 120px;
                    display: inline-block;
                    text-align: right;
                    padding-right: 20px;
                  }
                }
              }
              .from-r {
                width: 50%;
                height: 100%;
              }
            }
          }
        }
        .location-body {
          .location {
            height: 210px;
            .location-msg {
              height: 48px;
              line-height: 48px;
              background-color: #f2f2f2;
              display: flex;
              justify-content: space-between;
              padding: 0 10px;
            }
            .location-from {
              height: 168px;
              display: flex;
              padding: 40px 0 0 100px;
              box-sizing: border-box;
              .from-l {
                width: 50%;
                height: 100%;
                .el-input {
                  width: 320px;
                }
                div {
                  margin-top: 10px;
                  span {
                    width: 120px;
                    display: inline-block;
                    text-align: right;
                    padding-right: 20px;
                  }
                }
                .choice {
                  display: flex;
                  ::v-deep .AdressSelect {
                    .el-input__inner {
                      height: 36px;
                      line-height: 36px;
                      border-radius: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .order-msg-body {
        padding-bottom: 10px;
        .row {
          display: flex;
          align-items: center;
        }
        .site-row {
          margin: 10px 0;
          position: relative;
          .setting {
            position: absolute;
            display: none;
            right: 0;
            > span {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
        .site-row:hover {
          background: #b5d7ff;
          .opt {
            background: #fff;
          }
          .setting {
            display: block;
          }
        }
        .opt {
          width: 130px;
          height: 30px;
          border: 1px solid #d8d8d8;
          text-align: center;
          line-height: 30px;
          margin-right: 10px;
          cursor: pointer;
          .mark {
            opacity: 0;
          }
          i {
            opacity: 0;
          }
        }
        .name {
        }
        .disab {
          // background: #D8D8D8;
          // color: #666;
        }
        .active {
          border: 1px solid #3E84D6;
          position: relative;
          overflow: hidden;
          background: #fff;
          color: #333;
          .mark {
            position: absolute;
            bottom: 0;
            width: 20px;
            height: 30px;
            right: -16px;
            background: #3E84D6;
            transform: skewX(-45deg);
            opacity: 1;
          }
          i {
            position: absolute;
            bottom: -2px;
            right: -1px;
            color: #fff;
            opacity: 1;
          }
        }
        .adress {
          .default {
            background: #999;
            color: #fff;
            margin-left: 5px;
            padding: 5px;
          }
        }
        .moreadress {
          padding-top: 10px;
          color: #999;
          cursor: pointer;
          i {
            transform: rotate(90deg);
            transition: all 0.3;
          }
          .pack {
            transform: rotate(-90deg);
          }
        }
        // 商品列表
        .goods-table {
          .table-head {
            background: #f2f2f2;
            height: 38px;
            line-height: 38px;
            text-align: center;
            display: flex;
            .goods-msg {
              width: 630px;
            }
            .item {
              flex: 1;
            }
            .num {
              width: 130px;
            }
          }
          .table-body {
            .table-row {
              background: #f2f2f2;
              margin-top: 3px;
              display: flex;
              padding: 16px 0;
              .goods-msg {
                display: flex;
                width: 630px;
                .img-box {
                  width: 80px;
                  height: 80px;
                  margin: 0 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .box-replace {
                  padding-left: 20px;
                }
                .goods-info {
                  color: #666;
                  margin-right: 70px;
                  .name {
                    font-size: 14px;
                    display: flex;
                    .type {
                      padding: 2px 4px;
                      background: #3E84D6;
                      color: #fff;
                      font-size: 12px;
                      margin-right: 10px;
                    }
                  }
                  > div {
                    margin-bottom: 10px;
                    max-width: 148px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
              .item {
                flex: 1;
                text-align: center;
              }
              .price {
                position: relative;
                .goods-zbyh-tit {
                  position: absolute;
                  left: 50%;
                  // top: -20px;
                  bottom: 38px;
                  width: 200px;
                  transform: translateX(-50%);
                  text-align: center;

                  padding-left: 10px;
                  // color: #999;
                  color: red;
                }
              }
              .num {
                width: 130px;
                position: relative;

                .goods-Fcl-tit {
                  position: absolute;
                  bottom: 22px;
                  left: 50%;

                  transform: translateX(-50%);
                  text-align: center;

                  width: 400px;
                  color: red;
                }
                .modify-tip {
                  color: red;
                  font-size: 12px;
                  font-weight: bold;
                  margin-top: 10px;
                }
              }
            }
          }
        } 
        .invoice-list {
          display: flex;
          font-size: 12px;
          color: #666;
          .invoice-opt {
            margin: 0 8px;
          }
          .edit {
            color: #3E84D6;
            cursor: pointer;
          }
        }
      }
      .site-list-body {
        max-height: 128px;
        overflow-y: auto;
      }
      .select-box {
        display: flex;
        height: 79px;
        line-height: 79px;
        .please {
          width: 140px;
          text-align: right;
          padding-right: 10px;
          box-sizing: border-box;
          color: #666666;
        }
        .elect-box {
          width: 420px;
          .el-select {
            width: 100%;
          }
        }
        .regulation {
          font-size: 14px;
          font-weight: 500;
          color: #409eff;
          padding-left: 10px;
        }
      }
      .coupons-content {
        width: 1097px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        margin: 23px 0 20px 14px;
        .coupons-list {
          position: relative;
          width: 260px;
          height: 140px;
          background-color: #d3e7fb;
          overflow: hidden;
          margin-right: 10px;
          margin-bottom: 10px;
          .coupons-name {
            position: absolute;
            top: 11px;
            left: -30px;
            width: 100px;
            height: 20px;
            background-color: #c3e1ff;
            color: #50a3f8;
            text-align: center;
            line-height: 20px;
            transform: rotate(314deg);
          }
          .coupons-price {
            height: 66px;
            line-height: 66px;
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            color: #50a3f8;
          }
          .coupons-require {
            height: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 18px;
            color: #50a3f8;
          }
          .coupons-line {
            width: 234px;
            border: 1px dashed #50a3f8;
            margin: 10px 0 14px 15px;
          }
          .coupons-date {
            font-size: 18px;
            color: #50a3f8;
            text-align: center;
          }
        }
        .coupons-lists {
          position: relative;
          width: 260px;
          height: 140px;
          background-color: #d3d3d3;
          overflow: hidden;
          margin-right: 10px;
          margin-bottom: 10px;
          .coupons-names {
            position: absolute;
            top: 11px;
            left: -30px;
            width: 100px;
            height: 20px;
            background-color: #d5d5d5;
            color: #999999;
            text-align: center;
            line-height: 20px;
            transform: rotate(314deg);
          }
          .coupons-prices {
            height: 66px;
            line-height: 66px;
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            color: #999999;
          }
          .coupons-requires {
            height: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 18px;
            color: #999999;
          }
          .coupons-lines {
            width: 234px;
            border: 1px dashed #999999;
            margin: 10px 0 14px 15px;
          }
          .coupons-dates {
            font-size: 18px;
            color: #999999;
            text-align: center;
          }
        }
      }
    }
    .addremarks {
      margin-top: 10px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
    .order-sum {
      display: flex;
      justify-content: space-between;
      padding: 0 5px 20px;
      font-size: 12px;
      .remark {
        width: 730px;
      }
      .sum-item {
        display: flex;
        line-height: 18px;
        padding: 3px 0;
        .sum-label {
          text-align: right;
          margin-right: 12px;
          width: 140px;
        }
        .sum-value {
          text-align: left;
        }
      }
    }
    .order-fot-msg {
      background: #f2f2f2;
      padding: 20px 10px;
      text-align: right;
      font-size: 12px;
      .price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
        .price-num {
          font-size: 18px;
        }
        .quota {
          margin-right: 20px;
        }
      }
      .msg-adrress {
        span {
        }
        .phone {
        }
        .userName {
          margin-left: 14px;
        }
      }
    }
    .sub-order {
      display: flex;
      justify-content: flex-end;
      padding: 20px 0;
      .sub-handle {
        width: 140px;
        height: 36px;
        // line-height: 36px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        background: #3E84D6;
      }
    }
  }
  // 支付页
  .sub-order-pay {
    padding-top: 10px;
    .order-pay-header {
      background: #c0daf9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 88px;
      box-sizing: border-box;
      padding: 20px 56px;
      .head-left {
        > div {
          display: flex;
          padding-bottom: 16px;
          align-items: center;
          .su-icon {
            background: #60d44b;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 26px;
            margin-right: 2px;
            i {
              font-size: 16px;
            }
          }
          .su-text {
            font-size: 18px;
            font-weight: bold;
          }
          .limit {
          }
        }
      }
      .head-right {
        font-size: 18px;
        .label {
        }
        .value {
        }
      }
    }
    .order-msg {
      padding: 20px 80px 0;
      .tit {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 22px;
      }
      .msg-row {
        display: flex;
        padding-bottom: 10px;
        .msg-label {
          // width: 60px;
        }
        .msg-value {
          .goos-row {
            padding-bottom: 10px;
            span {
              margin-right: 5px;
            }
          }
        }
        .readInfo {
          margin-left: 44px;
          cursor: pointer;
          color: #3E84D6;
        }
      }
    }
    .pay-way-box {
      height: 165px;
      padding: 34px 80px;
      box-sizing: border-box;
      background: $backGray;
      .tit {
        font-size: 18px;
        font-weight: Bold;
        padding-bottom: 22px;
      }
      .pay-way-list {
        display: flex;
        .way-item {
          margin-right: 18px;
          padding: 2px 14px;
          width: 140px;
          height: 40px;
          box-sizing: border-box;
          border: 1px solid #f7f7f7;
          cursor: pointer;
          img {
          }
          .mark {
            opacity: 0;
          }
          i {
            opacity: 0;
          }
        }
        .active {
          border: 1px solid #3E84D6;
          position: relative;
          overflow: hidden;
          .mark {
            position: absolute;
            bottom: 0;
            width: 20px;
            height: 30px;
            right: -16px;
            background: #3E84D6;
            transform: skewX(-45deg);
            opacity: 1;
          }
          i {
            position: absolute;
            bottom: -2px;
            right: -1px;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
    .pay-hint {
      .way-title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: 30px 0;
      }
      .way-body {
        display: flex;
        justify-content: center;
        padding-bottom: 80px;
        // .hint-img{
        // 	margin-right: 60px;
        // }
        .QR {
          height: 366px;
          .erweima {
            border: 1px solid #ccc;
            padding: 18px;
            img {
              width: 266px;
            }
          }
          .QR-footer {
            display: flex;
            background: #3E84D6;
            height: 62px;
            align-items: center;
            justify-content: center;
            color: #fff;
            .fot-left {
              margin-right: 20px;
              img {
              }
            }
            .fot-right {
              > div {
                margin-bottom: 8px;
              }
            }
          }
        }
        .zz-box {
          text-align: center;
          .zz-pad {
            padding: 10px 0;
          }
        }
      }
    }
    // 支付成功
    .pay-ok-box {
      padding: 40px 0;
      text-align: center;
      .pay-ok-top {
        position: relative;
        .ok-icon {
          width: 60px;
          height: 60px;
          background: #00a2e8;
          border-radius: 50%;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          i {
            color: #fff;
          }
        }
        .ok-text {
          margin-top: 10px;
        }
        .cart-img-box {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 50px;
          background: #fff;
          img {
          }
        }
        .price {
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          margin-top: 30px;
          color: #f00;
          border: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
        }
      }
      .pay-ok-bot {
        color: #666;
        .row {
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          margin-top: 16px;
          .label {
          }
          .value {
          }
        }
        .read-info-box {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          .read-info {
            cursor: pointer;
            width: 180px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            background: #00a2e8;
          }
        }
      }
    }
  }
  .el-footer {
    @include hnwFooter;
  }
  // 发票
  .invoice-box {
    ::v-deep .el-dialog {
      background: #fff;
      .el-dialog__header {
        padding: 0;
        .el-dialog__headerbtn {
          top: 10px;
          .el-dialog__close {
            color: #333;
            font-size: 16px;
          }
        }
      }
      .el-dialog__body {
        padding: 0;
        .invoice-title {
          background: $gray;
          height: 30px;
          line-height: 30px;
          padding-left: 10px;
        }
        .invoice-body {
          padding: 10px;
          .btn-box {
            text-align: center;
          }
        }
      }
    }
  }
  .dema-info-dis {
    ::v-deep .el-dialog__header {
      padding: 10px 20px;
      background: #3E84D6;
      .dema-info-title {
        font-size: 15px;
        color: #fff;
        i {
          margin-right: 5px;
          font-size: 18px;
        }
      }
      .el-dialog__headerbtn {
        top: 12px;
        i {
          color: #fff;
        }
      }
    }

    ::v-deep .el-dialog__body {
      min-height: 500px;
      padding: 0px 0px;
      .dama-info-msg {
        .details-title {
          height: 40px;
          background-color: #ccc;
          display: flex;
          justify-content: space-between;
          line-height: 40px;
          > div {
            flex: 1;
            text-align: center;
          }
        }
        .details-body {
          height: 40px;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          line-height: 40px;
          border-bottom: 1px solid #eee;
          > div {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }
}
.el-dialog__body{
    // padding: 10px 20px;
    padding: 0 !important;
    .his-list{ 
        .list-head{
            display: flex;
            height: 40px;
            align-items: center;
            background: #EEEEEE;
            margin: 10px 0;
            padding: 0 8px;
            >div{
                flex: 1;
                text-align: center;
            }
            .col-100{
                max-width: 100px;
            }
        }
        .list-body{
            .row{
                display: flex;
                padding: 0 8px;
                >div{
                    flex: 1;
                    padding: 2px 0;
                    border-bottom: 1px solid #eee;
                    flex: 1;
                    text-align: center;
                    line-height: 28px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .col-100{
                    max-width: 100px;
                }
            }
        }
    }
}
</style>