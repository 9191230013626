<template>
  <div class="login">
    <header class="login-header">
      <div class="l-head-left">
        <img src="../../assets/login/logo1.png" alt="" />
        <div class="wel">欢迎登录华诺威</div>
        <div class="reHome btn" @click="$router.push('/index/navhome/self')">
          返回首页
        </div>
      </div>
      <div class="l-head-right">
        <div class="not-acc">没有账号？</div>
        <div class="btn toRegister" @click="toRegister">去注册</div>
      </div>
    </header>

    <div class="login-main-container">
      <!-- <img class="login-main-container-img" src="../../assets/login/back.png"/> -->
      <img
        class="login-main-container-img"
        src="../../assets/login/back1.png"
      />
      <div class="login-main">
        <!-- <img src="../../assets/login/车.png" class="leftImg"> -->
        <div class="login-main-form-box">
          <div class="login-main-left">
            <div class="logo-box">
              <img src="../../assets/login/logo2.png" class="logo" />
            </div>
            <div>
              <img src="../../assets/login/qcgyl.png" class="qc" />
            </div>
            <div>
              <img src="../../assets/login/che1.png" class="che" />
            </div>
          </div>
          <div class="form-main">
            <div class="way-handle">
              <div @click="passwordLogin">
                <span :class="{ active: !iscaptcha }"> 密码登录 </span>
              </div>
              <div @click="iscaptchaLogin">
                <span :class="{ active: iscaptcha }"> 验证码登录 </span>
              </div>
            </div>
            <el-form
              :model="loginData"
              status-icon
              :rules="rules"
              ref="loginFrom"
            >
              <el-form-item prop="seller_name">
                <div class="input-box">
                  <img src="../../assets/login/手机.png" class="in-icon" />
                  <input
                    type="text"
                    :placeholder="accountPla"
                    v-model="loginData.seller_name"
                    @keyup.enter="login"
                    autofocus
                  />
                </div>
              </el-form-item>

              <el-form-item prop="dxYzm" v-if="iscaptcha">
                <div class="input-box">
                  <img src="../../assets/login/验证码.png" class="in-icon" />
                  <input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="loginData.dxYzm"
                    @keyup.enter="login"
                    name="user"
                  />
                  <div @click="getCaptcha" class="dxYzm-btn">
                    <div>
                      {{ vcodeBtnName }}
                    </div>
                  </div>
                </div>
              </el-form-item>

              <el-form-item prop="password" v-else>
                <div class="input-box">
                  <img src="../../assets/login/密码.png" class="in-icon" />
                  <input
                    type="password"
                    placeholder="请输入密码"
                    v-model="loginData.password"
                    @keyup.enter="login"
                    name="password"
                  />
                </div>
              </el-form-item>
              <div class="login-btn-box">
                <div @click="login" class="login-btn">登录</div>
              </div>
              <div class="re_reset">
                <div class="remeber">
                  <el-checkbox v-model="reChoose">记住密码</el-checkbox>
                </div>
                <div class="reset">
                  <span @click="reSetPass"> 忘记密码? </span>
                </div>
              </div>

              <!-- <div class="login-register">
                                <div @click="toRegister">
                                    注册新用户
                                </div>
                            </div> -->
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <p>版权所有©2020华诺威汽车零部件（上海）有限公司</p>
      <p>沪ICP备16048698号-3</p>
    </footer>
    <el-dialog width="630px" top="10%" class="dis-goods-show" :visible.sync="showDiaVis" @close="showDiaVis=false" :show-close="false">
			<div class="vis-img-box" @click="showDiaVis=false">
				<div class="closeBtn" @click.stop="showDiaVis=false"></div>
				<img width="100%" src="../../assets//login/loginDis.png" alt="">
			</div>
		</el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: "",
      height: "",
      loginData: {
        seller_name: "",
        password: "",
        dxYzm: "",
        yzm: "",
      },
      showDiaVis:false,
      // 是否记住密码
      reChoose: true,
      accounType: "text",
      accountPla: "请输入账号",
      loginPayText: "验证码登录",
      // 密码是否可见
      isPass: true,
      iscaptcha: false,
      vcodeBtnName: "获取验证码",

      countDownTimer: "",
      countNum: "",
      rules: {},

      brandList: [],

      statiNums: {},
    };
  },
  methods: {
    passwordLogin(){
      this.iscaptcha = false;
      let account = localStorage.getItem("hnw-web-account");
      if (account) {
        account = JSON.parse(account);
        this.loginData.seller_name = account.seller_name;
        this.loginData.password = account.password;
      }  
    },
    iscaptchaLogin(){
      this.iscaptcha=true;
      this.resetForm()
    },
    resetForm() {
      for (let k in this.loginData) {
        this.loginData[k] = "";
      } 
    },
    async getCaptcha() {
      if (this.loginData.seller_name.length != 11) {
        return this.$erMsg("请填写正确的手机号");
      }
      if (
        this.vcodeBtnName != "获取验证码" &&
        this.vcodeBtnName != "重新发送"
      ) {
        return;
      }

      this.vcodeBtnName = "发送中...";
      // 与后端 api 交互，发送验证码 【自己写的具体业务代码】
      let res = await this.$api.sendSms({
        phone: this.loginData.seller_name,
        type: 2,
      });
      // 1 注册 2登录 3忘记密码

      // 假设发送成功，给用户提示
      if (res) {
        this.$suMsg("短信已发送，请注意查收");
      } else {
        this.$erMsg("手机号未注册！");
      }

      // 倒计时
      this.countNum = 120;
      this.countDownTimer = setInterval(
        function () {
          this.countDown();
        }.bind(this),
        1000
      );
    },
    countDown() {
      if (this.countNum < 1) {
        clearInterval(this.countDownTimer);
        this.vcodeBtnName = "重新发送";
        return;
      }
      this.countNum--;
      this.vcodeBtnName = this.countNum + "秒重发";
    },
    // 忘记密码
    reSetPass() {
      this.$router.push("/reSetPassword");
    },
    // 注册
    toRegister() {
      this.$router.push("/register");
    },
    async login() {
      
      let res = "";
      if (this.iscaptcha) {
        this.rules = {
          seller_name: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              min: 11,
              max: 11,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
          ],
          dxYzm: [
            { required: true, message: "请填写验证码", trigger: "blur" },
            { min: 6, max: 6, message: "请输入正确的验证码", trigger: "blur" },
          ],
        };
      } else {
        this.rules = {
          seller_name: [
            { required: true, message: "请填写账号", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请填写密码", trigger: "blur" },
            { min: 6, message: "密码必须大于6位", trigger: "blur" },
          ],
        };
      }
      this.loginAfter();
    },
    // 登录接口返回结果进行处理
    loginAfter() {
      function savePas(account) {
        localStorage.setItem("hnw-webToPur-account", JSON.stringify(account));
      }
      // sc_rent
      let res = "";
      this.$nextTick(() => {
        this.$refs.loginFrom.validate(async (flag) => {
          if (flag) {
            // 验证码登录

            if (this.iscaptcha) {
              res = await this.$api.meslogin({
                loginName: this.loginData.seller_name,
                captcha: this.loginData.dxYzm,
              });
            } else {
              res = await this.$api.login(this.loginData);
            }
            
            if (res.loginErr) {
              console.log("res",res);

              if(res.msg.includes('禁用')){
                this.showDiaVis = true
              }
              // if (this.iscaptcha) {
              //   return this.$erMsg("验证码错误！");
              // } else {
              //   return this.$erMsg("登录失败");
              // }
              // jnxtcs
            } else { 
              if (res.joinin_state == 31) {
                res = false;
                return this.$erMsg("该企业审核失败");
              } else if (res.joinin_state == 404) {
                res = false;
                return this.$erMsg("该企业未认证");
              } else {
                if (res.sc_name == "A-供货商") {
                  this.$warMsg("该账号禁止登录");
                  return 
                } 

                
               
                res.sc_bail = res.sc_bail || 0;
                let state = JSON.stringify(res);
                // 存储账号密码到localStorage
                savePas({
                  seller_name: this.loginData.seller_name,
                  password: res.password,
                })
                
                if (this.reChoose && !this.iscaptcha) {
                  let account = JSON.stringify(this.loginData);
                  localStorage.removeItem("hnw-web-account");
                  localStorage.setItem("hnw-web-account", account);
                }
                
                localStorage.removeItem("hnw-web-state");
                localStorage.setItem("hnw-web-state", state); 
                this.$store.commit("UpDateState", res);
                // 授权验证
                // this.authAmc()
                var info = {
                  shop: "",
                  buyer_id: res.store_id,
                  member_id: res.member_id,
                  shop_type:"" 
                }
                // console.log("res",res)

                if (res.for_branch && res.for_branch != 1780) {  // 直营店铺 
                  info.shop = res.for_branch;
                  info.shop_type = 2;// 直营
                  this.$router.push(`/store/businesses/${res.for_branch}`); 
                }else if (res.shop &&res.shop!=1780) { // 自营店铺  
                  info.shop = res.shop;
                  info.shop_type = 1;//自营
                  if(res.shop=='2136'){
                    info.shop_type = 3
                  }
                    this.$router.push(`/store/businesses/${res.shop}`);
                 
                }else {// 平台店铺 

                  info.shop = 1780;
                  info.shop_type = 3; // 平台店铺
                  // 如果该账号只是查看epc的账号
                  // res.sc_rent = 1;
                  let resAcc = await this.$api.getAccMsg({
                    member_id: this.$store.state.member_id,
                    type: this.$store.state.role_name == "all" ? 1 : 2,
                  })
                  if(resAcc.brands&&resAcc.brands.length>0){
                    this.$router.push("/find/index");
                  }else{
                    console.log("到达");
                    if(res.joinin_state==11){
                      this.$router.push("/brand");
                    }else{
                      this.$router.push("/index/navhome/self");
                    }
                  }

                  this.$api.getAnalysisLog("login")
                  
                }  
                this.$store.commit("UpDateState",info) 
                
              }
            }
          }
        });
      });
    },
    // 处理登录后的操作
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    async authAmc(){
      let isok = false
      try {
        let devMac = ""
        if(this.$store.state.member_id){
          devMac = await this.$api.deviceMacCdde({
            member_id: this.$store.state.member_id,
            member_name: this.$store.state.member_name,
            buyer_id: this.$store.state.store_id,
            buyer_name: this.$store.state.store_name
          })
        }
       
        
        if(devMac.length>0){
            let MyMacCddeList = await this.$api.getMyMacCdde({
              member_id: this.$store.state.member_id
            })
            
            if(MyMacCddeList&&MyMacCddeList.length){
              devMac.forEach(devmac => {
                if(!isok){
                  isok = MyMacCddeList.some(item=>item.mac_md5==devmac.mac_md5)
                }
              });
              // MyMacCddeList.some(item=>item.mac==)
            }
        }else{
          console.log("本地mac地址获取失败");
          // this.$warMsg("本地mac地址获取失败")
          this.$router.push("/login")
        }
      } catch (error) {
          console.log("获取本地mac地址发生错误");
          // this.$warMsg("获取本地mac地址发生错误")
          this.$router.push("/login")
      }
      if(isok){
        console.log("已授权");

      }else{
        console.log("未授权");
        this.$warMsg("设备未授权")
        this.$router.push("/login")
      }
    }
  },
  async created() {
    // 在页面初始化的时候要重置密码
    // this.resetForm();
    // console.log("document.title",document.title,this.iscaptcha)
    this.width = document.documentElement.clientWidth + "px";
    this.height = document.documentElement.clientHeight + "px";
    let _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.width = document.documentElement.clientWidth + "px"; //窗口宽度
      _this.height = document.documentElement.clientHeight + "px"; //窗口高度
    };
     
      let account = localStorage.getItem("hnw-web-account");
      if (account) {
        account = JSON.parse(account);
        this.loginData.seller_name = account.seller_name;
        this.loginData.password = account.password;
      } 
  },
  watch: {
    iscaptcha(flag) {
      if (flag) {
        this.accountPla = "请输入手机号";
        this.loginPayText = "账号登录";
      } else {
        this.accountPla = "请填写账号";
        this.loginPayText = "验证码登录";
      }
    },
  },
};
</script>

<style lang="scss">
.backgd {
  //    background-size: 50%  auto;
}

.login {
  background: #f2f2f2;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .login-header {
    display: flex;
    font-size: 0.545455rem /* 18/33 */;
    justify-content: space-between;
    height: 1.818182rem /* 60/33 */;
    padding: 0 0.909091rem /* 30/33 */;
    .btn {
      cursor: pointer;
      color: #3E84D6;
    }
    > div {
      display: flex;
      align-items: center;
    }
    .l-head-left {
      img {
        width: 1.787879rem /* 59/33 */;
      }
      > div {
        margin-left: 0.484848rem /* 16/33 */;
      }
      .reHome {
      }
      .wel {
      }
    }
    .l-head-right {
      .not-acc {
        color: #a9a9a9;
      }
    }
  }
  .login-main-container {
    height: 100%;
    position: relative;
    .login-main-container-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .login-main {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .leftImg {
        width: 24.848485rem /* 820/33 */;
        height: 13.030303rem /* 430/33 */;
        margin-right: 3.636364rem /* 120/33 */;
        z-index: 1;
      }
      .login-main-form-box {
        padding: 0.909091rem /* 30/33 */;
        box-sizing: border-box;
        width: 26.060606rem /* 860/33 */;
        height: 16.545455rem /* 546/33 */;
        background: #ffffff;
        border-radius: 0.30303rem /* 10/33 */ 4.848485rem /* 160/33 */
          0.30303rem /* 10/33 */ 4.848485rem /* 160/33 */;
        z-index: 1;
        display: flex;
        .login-main-left {
          text-align: center;
          position: relative;
          .logo-box {
            text-align: left;
          }
          .logo {
            width: 2.909091rem /* 96/33 */;
          }
          .qc {
            width: 7.212121rem /* 258/33 */;
            margin-top: 1.818182rem /* 60/33 */;
          }
          .che {
            margin-top: 0.30303rem /* 10/33 */;
            width: 11.393939rem /* 376/33 */;
          }
          &::before {
            content: "";
            width: 2px;
            height: 11.818182rem /* 390/33 */;
            background: #e8e8e8;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .form-main {
          padding-left: 1.515152rem /* 50/33 */;
          padding-top: 2.424242rem /* 80/33 */;
          .way-handle {
            display: flex;
            padding: 0.151515rem /* 5/33 */ 0;
            > div {
              flex: 1;
              text-align: right;
              cursor: pointer;
              color: #999;
              font-weight: 500;
              padding-right: 0.3rem /* 10/33 */;
              span {
                font-size: 0.606061rem /* 20/33 */;
              }
              .active {
                color: #59a8f8;
                position: relative;
              }
            }
            > div:first-child {
              border-right: 1px solid #b7b7b7;
              text-align: left;
              padding-left: 0.3rem;
            }
          }
          .el-form {
            //    padding-top: .727273rem /* 24/33 */;
            padding-top: 1.212121rem /* 40/33 */;
            width: 9.090909rem /* 300/33 */;
            .el-form-item {
              margin-bottom: 0.606061rem /* 20/33 */;
              .input-box {
                border: 1px solid #d6dadf;
                position: relative;
                border-radius: 3px;
                width: 9.090909rem /* 300/33 */;
                // height: 26px;
                height: 1.272727rem /* 42/33 */;
                display: flex;
                align-items: center;
                padding: 0.454545rem /* 15/33 */ 0.30303rem /* 10/33 */;

                box-sizing: border-box;

                input {
                  border: none;
                  margin-left: 10px;
                  margin-right: 0.606061rem /* 20/33 */;
                  outline: none;
                  font-size: 0.424242rem /* 14/33 */;
                  width: 7rem;
                  &::placeholder {
                    color: #cccccc;
                  }
                }

                .dxYzm-btn {
                  border-left: 1px solid #d6dadf;
                  background: #f5f7fa;
                  width: 13.636364rem /* 450/33 */;

                  height: 6.060606rem /* 200/33 */;
                  transform-origin: right center;
                  transform: scale(0.2);

                  position: absolute;
                  cursor: pointer;
                  right: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  > div {
                    // font-size: 14px;
                    font-size: 1.818182rem /* 60/33 */;
                  }
                }
              }
            }
            .login-btn-box {
              display: flex;
              justify-content: center;
              padding-top: 0.606061rem /* 20/33 */;
              .login-btn {
                width: 10.909091rem /* 360/33 */;
                height: 1.393939rem /* 46/33 */;
                border-radius: 0.09rem /* 3/33 */ 0.57rem /* 19/33 */ 0.09rem
                  /* 3/33 */ 0.57rem /* 19/33 */;
                display: flex;
                background: #409eff;
                color: #fff;
                cursor: pointer;
                justify-content: center;
                align-items: center;
              }
            }
            .re_reset {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-top: 0.606061rem /* 20/33 */;
              .remeber {
                color: #919191;
              }
              .reset {
                span {
                  cursor: pointer;
                  color: #409eff;
                }
              }
            }

            .login-register {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 0.30303rem /* 10/33 */;
              cursor: pointer;
              > div {
                font-size: 0.424242rem /* 14/33 */;
              }
            }
          }
        }
        
      }
    }
  }
  footer {
    height: 2.424242rem /* 80/33 */;
    background: #fff;
    color: #666666;
    padding-top: 0.30303rem /* 10/33 */;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    bottom: 0;
    p {
      margin: 0.151515rem /* 5/33 */ 0;
    }
  }
  .dis-goods-show{
      background: transparent;
      .el-dialog{
        background: transparent;
        box-shadow: 0 0px 0px ;
      }
      .el-dialog__header{
        background: transparent;
        padding: 0;
      }
      .el-dialog__body{
        // background: transparent;
        padding: 0;
        display: flex;
        justify-content: center;
        
      }
      .vis-img-box{

        background: transparent;
        position: relative;
        text-align: center;
        width: 630px;
        .closeBtn{
          position: absolute;
          right: 40px;
          top: 0px;
          width: 100px;
          height: 100px;
          cursor: pointer;
          // background: #000;
        }
        img{
          
        }
    }
  }
}
</style>