<template>
<div class="store-goods-home">
    <div class="home-modu w" v-if="recommendGoodsList.length>0">
        <div class="modu-title">
            <img class="dianzan" src="../../../assets/store/dianzan.png" alt="" srcset="">
            为你推荐
        </div>
        <div class="modu-content clear-modu" >
            <div class="goods-list">
                <div class="goods-item" @click="showGoodsInfo(goods)" v-for="(goods,index) of recommendGoodsList" :key="index" :class="{'disbld':!goods.goods_id}">
                    <div class="img-box" v-if="goods.goods_id">
                        <img :src="goods.homeImg||goods.goods_image" alt="">
                        <!-- <img :src="goods.goods_image" alt=""> -->
                    </div>
                    <div class="goods-info" v-if="goods.goods_id">
                        <div class="goods-name">{{goods.goods_name}}</div>
                        <div class="price" v-if="$store.state.shop==1948">
                            <!-- 价格   -->
                            <span v-if="$store.state.shop==shopId||$store.state.store_id==shopId||$store.state.for_branch==shopId">
                                <span class="symbol">详询销售</span>  
                            </span>
                            <span v-else>
                                会员可见
                            </span>
                        </div>
                        <div class="price" v-else>
                            <!-- 价格   -->
                            <span v-if="$store.state.shop==shopId||$store.state.store_id==shopId||$store.state.for_branch==shopId">
                                <span class="symbol">￥</span>{{goods.taxWebPrice}} 
                            </span>
                            <span v-else>
                                会员可见
                            </span>
                        </div>
                        <div class="join-box" @click.stop>
                            <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                            <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                             
                            <template v-if="$store.state.shop==1974||$store.state.shop==1871||$store.state.shop==1948">
                                <el-button class="add-btn" v-if="chain==3"  @click="joinCart(goods,index)" >加入购物车</el-button> 
                                <el-button class="add-btn" v-else  @click="joinCart(goods,index)" >加入购物车</el-button> 
                            </template>
                            
                            <template v-else>
                                <el-button class="add-btn" v-if="chain==3" :class="{'ycg-btn':goods.goods_storage==0&&Number(goods.group_storage)==0}" @click.stop="joinCart(goods)" >{{(goods.goods_storage>0||goods.group_storage>0)?'加入购物车':'加入预购单'}}</el-button> 
                            <el-button class="add-btn" v-else :class="{'ycg-btn':goods.goods_storage==0}" @click.stop="joinCart(goods)" >{{(goods.goods_storage>0)?'加入购物车':'加入预购单'}}</el-button> 
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
</div>
</template>

<script>
export default {
    data(){
        return{
            clearGoodsList:[],
            hotGoodsList:[],
            recommendGoodsList:[],
            shopId:""
        }
    },
    methods:{
        async showGoodsInfo(goods){
          if(!goods.goods_id){
            return 
          }
          if(this.$store.state.shop!=this.shopId&&this.$store.state.store_id!=this.shopId&&this.$store.state.for_branch!=this.shopId){
            return this.$warMsg("暂无权限")
          }
          goods.preRou = "店铺"
          localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
          this.$emit("showInfo")
          
      },
      impueNumChange(goods) {
            // group_storage
            if(isNaN(goods.goods_num)){
                
                
                this.$nextTick(()=>{
                    this.$set(goods,"goods_num",1)
                })
                }
            if(this.$store.state.shop!=1974&&this.$store.state.shop!=1871&&this.$store.state.shop!=1948){
                if(this.chain==3){
                    if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                        this.$nextTick(()=>{
                            goods.goods_num = goods.goods_storage
                        })
                        return this.$store.commit('UpDateState',{invDia:true})
                    }else if(Number(goods.group_storage)>0&&goods.goods_num>Number(goods.group_storage)){
                        this.$nextTick(()=>{
                            goods.goods_num = Number(goods.group_storage)
                        })
                        return this.$store.commit('UpDateState',{invDia:true})
                    }

                }else{
                    if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                        this.$nextTick(()=>{
                            goods.goods_num = goods.goods_storage
                        })
                        return this.$store.commit('UpDateState',{invDia:true})
                    }
                }
            }
            
        },
         //加入购物车
        async joinCart(carItem,){
            if(this.$store.state.store_id==this.shopId){
                return this.$warMsg("不能购买自己配件")
            }
            if(this.$store.state.zero_buy!=1){
                if(carItem.goods_storage<1){
                   console.log("???",carItem.group_storage);
                  if(carItem.group_storage&&Number(carItem.group_storage)>0){
                      if(carItem.goods_num+Number(carItem.cartNum)>carItem.group_storage){
                        return this.$store.commit('UpDateState',{invDia:true})
                      }
                  }
                }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            // 预购单也添加购物车
            if(this.$store.state.shop==1974||this.$store.state.shop==1871||this.$store.state.shop==1948){
                if(carItem.goods_storage==0){
                    carItem.goods_storage = 1
                }
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                group_storage:carItem.group_storage||"",
                buyer_id:'',
                order_name:'',
                store_id:carItem.store_id,
                member_id:'',
                buyer_name:'',
                store_name:carItem.store_name,
                cart_type:'1',
                setlist_id:'',
                groupbuy_id:'',
                promotion_id:''
            }
            let res = await this.$api.joinCart([carMessage])
            //更新记录中 已添加X件 字段
            // carItem.cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            
        }
    },
    async created(){
        
        this.shopId = this.$route.params.id
        
        // 获取清库存 4
        this.clearGoodsList = await this.$api.getlistRecom({
            goods_commend:3,
            store_id:this.shopId,
            chain:this.chain
        }) 
        // 获取热销 5
        this.hotGoodsList = await this.$api.getlistRecom({
            goods_commend:4,
            store_id:this.shopId,
            chain:this.chain
        }) 
        // 推荐 8
        let res= await this.$api.getShopRecommend({
          store_id:this.$route.params.id,
          curpage:this.currentPage,
          page:999,
          type:"recommend",
          chain:this.chain
        })
        this.recommendGoodsList = res.data
        if(this.recommendGoodsList.length>0){
            this.recommendGoodsList.forEach(goods => {
                this.$set(goods,"goods_num",1) 
            })
            let ids= this.recommendGoodsList.map(item=>item.goods_id)
            let goods_id = ids.join(",")
            let listimgs = await this.$api.getGoodsListHomeImg({
              goods_id,
              goods_type:1 + "推荐",
              shop_id:this.shopId
            })
            listimgs.forEach(item=>{
                this.recommendGoodsList.forEach(goods=>{
                    if(goods.goods_id==item.goods_id){
                    this.$set(goods,"homeImg",item.url)
                    }
                })
            })
        }
         
        if(this.clearGoodsList.length>4){
            this.clearGoodsList = this.clearGoodsList.slice(0,4)    
        }else if(this.clearGoodsList.length>0&&this.clearGoodsList.length<4){
            for (let index = 0; index < 4-this.clearGoodsList.length; index++) {
                this.clearGoodsList.push({
                    name:""
                })
            }
        }
        if(this.hotGoodsList.length>5){
            this.hotGoodsList = this.hotGoodsList.slice(0,5)    
        }else if(this.hotGoodsList.length>0&&this.hotGoodsList.length<5){
            for (let index = 0; index < 5-this.hotGoodsList.length; index++) {
                this.hotGoodsList.push({
                    name:""
                })
            }
        } 

    },
    props:{
        chain:""
    }
}
</script>

<style lang="scss">

$xyblue:#1B458B;
$xyow:#FF9E20;
.store-goods-home{
    padding-top: 10px; 
    .home-modu{
        background: #fff;
        .modu-title{
            color: #2A6DBB;
            display: flex;
            align-items: center;
            padding-left: 15px;
            padding-top: 10px;
            .dianzan{
                margin-right: 5px;
                width: 18px ;
            }
        }
        .modu-content{
            padding: 10px 0;
            >div{

            }
        }
        .goods-item{
            cursor: pointer;
        }
        // 清库存
        .clear-modu{
            padding: 10px;
            
            .goods-list{
                display: flex;
                border: 1px solid #DCDCDC;
                flex-wrap: wrap;
                .goods-item{
                    background: #fff;
                    width: 20%;
                    padding: 10px 0;
                    box-sizing: border-box;
                    border-right: 1px solid #DCDCDC;
                    border-bottom:1px solid #DCDCDC;
                    .img-box{
                        width: 100%;
                        text-align: center;
                        img{
                            width: 80%;
                            
                        }
                    }
                    .goods-info{
                        padding: 10px 15px;
                        .goods-name{

                        }
                        .price{
                            padding: 10px 0;
                            font-size: 16px;
                            color: #2A6DBB;
                            .symbol{
                                font-size: 12px;
                            }
                        }
                        .join-box{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .el-input-number--mini{
                                width: 110px;
                                >span{
                                    border-radius: 0;
                                    background-color:#fff;
                                }
                                .el-input{
                                    input{
                                        border-radius: 0;
                                    }
                                }
                            }
                            .el-button--default {
                                width: 80px;
                                height: 28px;
                                border: 1px solid #2A6DBB;
                                padding: 0;
                                color: #2A6DBB;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 28px;
                                border-radius: 0px;
                            }
                        }
                    }
                }
                
            }

        }
        // 热销商品
        .hot-modu{
            position: relative;
            height: 840px;
            box-sizing: border-box;
            .shadow-line{
                position: absolute;
                height: 415px;
                width: 100%;
                background: #e1e5ec;
                bottom: 0;
                
            }
            .hot-content{
                position: absolute;
                width: 1200px;
                left: 50%;
                transform: translateX(-50%);
                .hot-title{
                    position: absolute;
                    width: 715px;
                    height: 437px;
                    // padding: 30px 15px;
                    // padding-top: 96px;
                    box-sizing: border-box;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background: #e1e5ec;
                    >div{
                       
                        font-size: 24px;
                        color: $xyblue;
                    }
                    .hot-line{
                        width: 280px;
                        padding: 0;
                        height: 5px;
                        margin: 15px 0;
                        box-sizing: border-box;
                        background: #1B458B;
                        opacity: 0.2;
                    }
                }
                .hot-goods{
                    padding-left: 462px;
                    height: 830px;
                    .goods-list{
                        display: flex;
                        flex-wrap: wrap;
                        // position: relative;
                        justify-content: space-between;
                        padding-left: 10px;
                        .goods-item{
                            width: 359px;
                            .img-box{
                                img{
                                    width: 359px;
                                    height: 359px;
                                    background: #FFFFFF;
                                    border-radius: 24px;
                                }
                            }
                            .goods-info{
                                display: flex;
                                padding: 15px;
                                justify-content: space-between;
                                .goods-name{
                                    font-size: 24px;

                                }
                                .read-btn{
                                    background: $xyow;
                                    color: #fff;
                                    width: 30px;
                                    line-height: 24px;
                                    text-align: center;
                                    // padding: 2px 0;
                                    cursor: pointer;
                                    i{
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                        .first-goods{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 462px;
                            .img-box{
                                img{
                                    width: 462px;
                                    height: 462px;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
    .recommend-home{
        position: relative;
        height: 760px;
        padding-top: 10px;
        .modu-title{
            color: #fff;
            border-left: 4px solid #fff;
            position: relative;
            z-index: 5;
        }
        .shadow-line{
            width: 3000px;
            background: $xyblue;
            height: 480px;
            position: absolute;
            top: 0;
            left: -800px;
            
        }
        .goods-list{
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            justify-content: space-between;
            .goods-item{
                width: 284px;
                border-radius: 15px;
                margin-top: 10px;
                .img-box{
                    img{
                        width: 284px;
                        border-radius: 15px;
                    }
                }
                .goods-info{
                    background: $xyblue;
                    padding: 16px 8px;
                    .price-row{
                        color: #fff;
                        span{

                        }
                        .now-price{
                            color: $xyow;
                            .symbol{
                                font-size: 12px;
                            }
                        }
                        .old-price{
                            text-decoration: line-through;
                            color: #C1C1C1;
                            font-size: 12px;
                        }
                    }
                    .item-bottom{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #fff;
                        .goods-name{
                            font-size: 16px;
                        }
                        .read-btn{
                            background: $xyow;
                            color: #fff;
                            padding: 5px ;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .home-hot{
        .modu-title{
            margin: 0 auto;
            box-sizing: border-box;
            
        }
    }
}
</style>