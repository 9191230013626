<template>
  
  <el-container class="hnw-index">
    <el-dialog
      :visible.sync="showAdcer"
      class="hnw-home-index-dia"
      destroy-on-close
      width="1000px"
    >
      <transition name="el-zoom-in-center">
        <!--   -->
        <div v-show="showAdcer" class="adver-banner">
          <div class="clear-btn" @click="showAdcer = false">
            <i class="el-icon-close"></i>
          </div>

          <el-carousel height="574px" :interval="3000" arrow="always">
            <el-carousel-item v-for="(item, index) in banner" :key="index">
              <img :src="item.adcerImgSrc" alt="" @click="bannerJump(item)" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </transition>
    </el-dialog>
     <!-- 折扣推荐弹窗 -->
    <el-dialog class="dis-goods-show" :visible.sync="showDiaVis" @close="DiaVisClose" :show-close="false">
			<div class="vis-img-box" @click="goRecommend(showDiaVisGoods)">
				<div class="closeBtn" @click.stop="showDiaVis=false"></div>
				<img width="100%" :src="showDiaVisGoods.s_image" alt="">
			</div>
		</el-dialog>
    <!-- 到货提醒 -->
    <el-dialog class="dis-goods-show pur-dis"  :visible.sync="showDiaPur" @close="showDiaPur=false" >
			<div class="pur-content-box">
        <img class="daohuobeijing" src="../../assets/index/daohuobeijing.png" alt="" srcset="">
				<div class="closeBtn" @click.stop="showDiaPur=false"></div>
				<div class="pur-list-box">
          <div class="pur-modu">
            <div class="modu-title">
              今日到货
            </div>
            <div class="goods-list">
              <div class="goods-row" v-for="(item, index) in purDayGoodsList" :key="index">
                <div class="goods-col">{{ item.goods_name }}</div>
                <div class="goods-col"> <span class="label">OE:</span> {{ item.oecode }}</div>
                <div class="goods-col"> <span class="label">品牌:</span> {{ item.oenaturebrand }}</div>
              </div>
            </div>
          </div>
          <div class="pur-modu">
            <div class="modu-title">
              近7日到货未购买
            </div>
            <div class="goods-list">
              <div class="goods-row" v-for="(item, index) in purGoodsList" :key="index">
                <div class="goods-col">{{ item.goods_name }}</div>
                <div class="goods-col"> <span class="label">OE:</span> {{ item.oecode }}</div>
                <div class="goods-col"> <span class="label">品牌:</span> {{ item.oenaturebrand }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="buy-box">
          <div class="btn" @click="showDiaPur=false;toCart(1)">
            去购买
          </div>
        </div>
			</div>
		</el-dialog>

    <el-dialog
      :visible.sync="editPassDia"
      class="hnw-index-password-edit"
      :show-close="false"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="edit-password-img">
        <img src="../../assets/index/editPassword.png" alt="" srcset="" />
        <div class="close-btn" @click="closePassDia"></div>
        <div
          class="btn edit-btn"
          @click="
          $router.push('/my/account');
            editPassDia = false;
          "
        ></div>
        <div class="btn not-dia-btn" @click="closePassDia"></div>
        <!-- /my/account -->
      </div>
    </el-dialog>

    <el-header height="40px">
      <!-- <hnw-header></hnw-header> -->
      <!-- 头部 -->
      <div class="hnw-header"  id="hnw-header">
        <div class="logo">
          <template v-if="!token">
            <div @click="$router.push('/login')">登录</div>
            <div class="shu"></div>
            <div @click="$router.push('/Register')">注册</div>
            <div class="shu"></div>
          </template>
          <template v-if="pageMode == 1">
            <div v-if="$store.getters.notLvD">帮助中心</div>
            <div class="re-home" v-else @click="toHome">返回上一页</div>
          </template>
          <template v-else-if="pageMode != 1 && $store.getters.notLvD">
            <div class="re-home" @click="toHome">
              <img src="../../assets/shopcar/house.png" alt="" />
              <span>华诺威首页</span>
            </div>
            <div class="shu"></div>
            <div>帮助中心</div>
          </template>
        </div>

        <div class="header-right">
          <!-- menu-trigger="click" -->
          <el-menu
            mode="horizontal"
            @select="menuSelect"
            active-text-color="#666"
            text-color="#666"
          >
            <template v-if="token">
              <el-submenu index="1">
                <template slot="title">
                  <span class="my-hnw">{{ $store.state.nickname ||$store.state.member_name}} </span>
                </template>    

                <el-menu-item
                  style="height: 57px; 
                  background-color: #edeff4"
                  index="1-1"
                >
                
                  <div
                    style="
                      font-size: 16px;
                      color: #666666;
                      text-align: center;
                      height: 30px;
                    "
                  >
                    {{ $store.state.company_name_jc ||$store.state.member_name}}
                  </div>
                  <div
                    style="
                      font-size: 12px;
                      color: #999999;
                      text-align: center;
                      height: 20px;
                      margin-top: -10px;
                    "
                  >
                    {{ $store.state.nickname||$store.state.member_name }} / {{ $store.state.sc_name }}
                  </div>
                </el-menu-item>

                <el-menu-item
                  style="text-align: center"
                  index="1-1"
                  @click="toMymodule(menu.path)"
                  v-for="(menu, index) in $hnwTools.storeMenuList"
                  :key="index"
                >
                  <img
                    style="width: 16px; height: 16px; padding-right: 5px"
                    :src="require(`../../assets/my/msg/${menu.img}.png`)"
                    alt=""
                  />
                  {{ menu.title }}
                </el-menu-item>
                <el-menu-item
                  style="
                    text-align: center;
                    height: 57px;
                    line-height: 57px;
                    border-top: 1px solid #f6f6f6;
                  "
                  index="1-1"
                  @click="loginOut"
                >
                  <img
                    style="width: 16px; height: 16px; padding-right: 5px"
                    src="../../assets/store/quit.png"
                    alt=""
                  />
                  退出登录
                </el-menu-item>
              </el-submenu>

              <div class="shu"></div>
            </template>
            <!-- <el-menu-item index="4"  @click="goStatement" v-if="$store.getters.notLvD">
							采购报表
						</el-menu-item>
						
						<div class="shu" v-if="$store.getters.notLvD"></div> -->

            <el-menu-item index="4" @click="goStore" v-if="$store.getters.notLimitBrands">
              <!-- @click="dialogFormVisible=true" -->
              批量采购
            </el-menu-item>
            <div class="shu" v-if="$store.getters.notLimitBrands"></div>
            <el-menu-item index="4" @click="toOrder"> 我的订单 </el-menu-item>
            <!-- <div class="shu" v-if="$store.getters.notLvD"></div>

						<el-menu-item index="4" @click="toUnion" v-if="$store.getters.notLvD">
							联盟调货
						</el-menu-item> -->

            <div class="shu"></div>

            <el-menu-item
              index="3"
              @click="toHnwPur"
              v-if="$store.getters.notLvD"
            >
              <div class="to-hnw-pur">
                <img
                  class="store-icon"
                  src="../../assets/index/storeicon.png"
                  alt=""
                />
                企业中心
              </div>
            </el-menu-item>
            <div class="shu" v-if="$store.getters.notLvD"></div>
            <el-menu-item index="4" @click="toVin" class="jd-red">
              <img
                style="width: 14px; height: 14px"
                src="../../assets/store/vin.png"
                alt=""
              />
              电子目录
            </el-menu-item>
            <div class="shu" v-if="$store.getters.notLvD"></div>
            <el-submenu index="5" v-if="$store.getters.notLvD">
              <template slot="title">手机版</template>
              <el-menu-item index="4-1" class="home-menu-erm">
                <div class="home-menu-erm-row">
                  <div class="erm-item">
                    <div class="img-box">
                      <!-- <img src="../../assets/index/andewm.jpg" alt=""> -->
                      <img src="../../assets/index/anzhuoerweima.png" alt="" />
                    </div>
                    <div class="tit">APP安卓版</div>
                  </div>

                  <div class="erm-item">
                    <div class="img-box">
                      <img src="../../assets/index/iosewm.png" alt="" />
                    </div>
                    <div class="tit">APP苹果版</div>
                  </div>
                </div>
              </el-menu-item>
            </el-submenu>

            <div class="shu" v-if="$store.getters.notLvD"></div>
            <!-- <el-menu-item index="2" disabled v-if="$store.getters.notLvD">合伙人计划</el-menu-item> -->
          </el-menu>
        </div>
      </div>
    </el-header>
    <el-main>
      <div id="watermark-overlay">  
        <div  
          v-for="rowIndex in 2"  
          :key="rowIndex"  
          class="watermark-row"  
        >  
          <div  
            v-for=" cellIndex in 3"  
            :key="cellIndex"  
            class="watermark-cell"  
          >  
            <span class="watermark-text"> {{ $store.state.nickname||$store.state.member_name||"" }}  {{ $store.state.member_id }}</span>  
           
          </div>  
        </div>  
      </div> 
      
      <section class="main-box">
        <!-- {{ pageMode }} -->
        <!-- 主体的头部 -->
       
        <div class="main-head newHead" >
         
          <div class="head-left" @click="toHome">
            <img
              :src="require('../../assets/index/logo.svg')"
              title="点击刷新页面"
              alt=""
            />
            <!-- <img :src="require('../../assets/index/汽配供应链专家.svg')" alt="" class="logo-text-img">  -->
            <img :src="require('../../assets/index/logotext.png')" alt="" class="logo-text-img"> 
            <div class="left-text"  v-if="pageMode != 1">
              华诺威首页
            </div>
          </div>
          <div class="search" v-if="pageMode == 1">
            <!-- 去抖延时 debounce getSearchRecord-->
            <el-autocomplete
              class="inline-input"
              v-model="searchText"
              :fetch-suggestions="querySearch"
              placeholder="请输入搜索内容，关键词用空格隔开"
              @select="search"
              clearable
              :hide-loading="true"
              value-key="record_name"
              @keyup.enter.native="search"
            ></el-autocomplete>
            <div  class="seach-btn"  @click="search">
              搜索
            </div>
          
          </div>
          <div class="shopcart-btns" v-if="pageMode == 1||pageMode==3">
            <div
              class="shopCart"
              v-if="
                $store.state.shop != 1974 &&
                $store.state.shop != 1871 &&
                $store.state.shop != 1948
              "
            >
              <div @click="toCart(2)" class="cart-adv-btn">
                <div class="aog" v-if="$store.state.advCart">
                  <div class="aog-text">到货</div>
                  <div class="btn-text">预购单</div>
                </div>
                <div v-else>预购单</div>
                
              </div>
            </div>

            <div class="shopCart" >
              <div class="cart-btn" @click="toCart(1)">
                <img src="../../assets/newHome/gwc.png" alt="" />
                <el-badge
                  :value="cartNums"
                  class="num"
                  type="primary"
                  :max="99"
                  :hidden="!cartNums"
                >
                </el-badge>
                <span class="btn-text"> 我的购物车 </span>
              </div>
             
            </div>

          </div>
          <div v-if="pageMode == 5">
            <div class="inteNum">
                <div class="label">
                    我的积分:
                </div>
                <div class="red num">
                    {{$store.state.integral_num}}
                </div>
            </div>
          </div>
        </div>
       

        <div class="main-nav-box" v-if="pageMode == 1">
          <div class="main-nav" v-if="$route.path != '/store/info'&&$route.path != '/brand'">
            <div class="main-tabs">
              <template v-for="(nav,index) in navList">
                <div
                  class="tab-item"
                  :key="index"
                  v-if="nav.showNav"
                  @click="navChange(nav,index)"
                  :class="{ 'active':activeIndex== index }"
                >
                  {{nav.title}}
                </div>
              </template>

              
            </div>
          </div>
        </div>

        <div class="main-body-box">
          <div class="main-body">
           
            <router-view  />
           
          </div>
        </div>
        <el-backtop  :visibility-height="100">
          <i class="el-icon-caret-top"></i>
          <div class="text">
            置顶
          </div>
        </el-backtop>
      </section>
    </el-main>
    
    <el-footer height="260px">
      <div class="feature-box">
        <div class="feature-list hnw-area-center">
          <div class="feature-item">
            <img src="../../assets/newHome/正品保证.png" >
            <div>
              <div>正品保证</div>
              <div>货真价实,品质保障</div>
            </div>
          </div>
          
          <div class="feature-item">
            <img src="../../assets/newHome/30天.png" >
            <div>
              <div>30天保价</div>
              <div>30天保价，不高于行价</div>
            </div>
          </div>
          
          <div class="feature-item">
            <img src="../../assets/newHome/售后.png" >
            <div>
              <div>售后无忧</div>
              <div>售后无忧，服务至上</div>
            </div>
          </div>
          
          <div class="feature-item">
            <img src="../../assets/newHome/采购.png" >
            <div>
              <div>数智采购</div>
              <div>精准查询，高效匹配</div>
            </div>
          </div>
          
          <!-- <div class="feature-item">
            <img src="../../assets/newHome/全.png" >
            <div>
              <div>配件齐全</div>
              <div>200,000SKU,强力支持</div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="footer-bom">
        <div class="footer-box hnw-area-center">
          <div class="footer-left">
            <div class="links-copyright">
              <!-- <ul>
                
                <li @click="$router.push('/service/6')"> 关于我们</li> 
                <li @click="$router.push('/service/8')"> 法律声明</li>
                <li @click="$router.push('/service/7')">联系方式</li>
              </ul> -->

              <div class="copyright">
                <p style="color: rgb(151, 151, 151);">
                  <a href="https://beian.miit.gov.cn" style="color: rgb(151, 151, 151);"> 版权所有©2020华诺威汽车零部件（上海）股份有限公司 
                    </a>
                </p>
                <p style="color: rgb(151, 151, 151);">
                  <a href="https://beian.miit.gov.cn" style="color: rgb(151, 151, 151);"> Copyright©2020chinawayutoparts  沪ICP备16048698号-3</a>
                </p>
              </div>
            </div>
            <div class="qrCode-wrap">
              <div class="imgBox"><img src="../../assets/index/erweima1.jpg" alt="" srcset=""> <p>华诺威公众号</p></div>
              <div class="line"></div>
              <div class="gzhCode imgBox"><img src="../../assets/index/anzhuoerweima.png" alt="" srcset=""> <p>APP安卓版</p></div>
              <div class="gzhCode imgBox" style="margin-left: 20px;"><img src="../../assets/index/iosewm.png" alt="" srcset=""> <p style="margin-left: -2px; width: 86px;">APP苹果版</p></div>
            </div>
          </div>
          <div class="customer-service">
            <p style="color: rgb(151, 151, 151);" @click="$router.push('/service/8')">
              <span @click="$router.push('/service/8')"> 法律声明</span >
              <!-- <a href="mailto:service@etc-parts.com" style="color: rgb(151, 151, 151);"> 法律声明</a> -->
            </p>
            <p style="color: rgb(151, 151, 151);">
              <a href="http://www.chinawayautoparts.com" style="color: rgb(151, 151, 151);"> 公司官网:http://www.chinawayautoparts.com</a>
            </p>
            <p>服务热线:    021-69585015</p>
            <p>周一至周六    9:00-18:00</p>
          </div>
        </div>
      </div>
    </el-footer>

    <!-- 批量采购弹框 -->
    <el-dialog title="批量查询" :visible.sync="dialogFormVisible">
      <div class="btn" v-if="showPlaceh">
        <div @click="batchWay = 1" :class="{ atv: batchWay == 1 }">复制粘贴</div>
        <div @click="batchWay = 2" :class="{ atv: batchWay == 2 }">Excel导入</div>
      </div>
      <el-input
        type="textarea"
        :rows="15"
        placeholder=""
        resize="none"
        v-model="textarea"
        class="textBox"
        ref="batchRef"
        @paste.native="paste"
        v-show="showPlaceh"
        v-if="batchWay == 1"
      >
      </el-input>
      <!-- 用来覆盖在textEare上面得提示 -->
      <div v-if="batchWay == 1" class="hint" v-show="showPlaceh" @click="trigger">
        <!-- <img src="./hint.jpg" alt=""> -->
        <div class="example-info">
          <p class="blue">说明：</p>
          <p>
            1.从Excel文件中选择件号,订购数量两列复制，如复制超过两列，将自动忽略
            ;
          </p>
          <p>2.点击这里执行"粘贴"操作 ;</p>
          <p>3.查看粘贴结果 ;</p>
          <p>4.点击查询按钮进行配件查询 ;</p>
          <p class="red">5.如有红色行表示查询到重复商品 ;</p>
          <p class="gray">例：复制表格中红框圈出来的部分 。</p>
        </div>

        <div class="example-table">
          <div class="table-head">
            <!-- <div>配件编码</div> -->
            <div>生产编码/OE号</div>
            <div>订购数量</div>
          </div>
          <div class="table-body">
            <div class="body-row">
              <div>25858DGD544DFG</div>
              <div>251</div>
            </div>
            <div class="body-row">
              <div>25858DGD544DFG</div>
              <div>251</div>
            </div>
            <div class="body-row">
              <div>25858DGD544DFG</div>
              <div>251</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 文件导入查询 -->
      <div v-if="batchWay == 2 && showPlaceh" class="lead">
        <div class="lead-text">请选择你要导入的 Excel / xlsx 文件：</div>
        <input
          type="file"
          v-show="false"
          ref="ExelFile"
          @change="skuBatch"
          class="eleclFIle"
        />
        <div class="inp" @click="triggerFile">
          <div style="padding-left: 15px">选择文件...</div>
          <div class="import">
            <img src="../../assets/index/xuanze.png" alt="" />
            文件导入
          </div>
          <!-- <el-button type="primary"   size="mini" @click="triggerFile">文件导入</el-button> -->
        </div>
        <div class="require">
          <p class="blue">文件要求：</p>
          <p>1.Excel导入请使用<span class="red">下方模板</span>格式 ;</p>
          <p>2.文件大小不得大于 25 MB ;</p>
          <p>3.一次最多导入1000项产品。</p>
          <div class="download" @click="execlDown">模板下载</div>
        </div>
      </div>
      <!-- 批量查询的表格 -->
      <div class="batch-table" v-show="!showPlaceh">
        <div class="paste-res">
          <div class="title">粘贴结果</div>
          <el-button @click="resetPaste">撤销</el-button>
        </div>
        <div class="table">
          <el-table
            :data="batchs"
            style="width: 100%; max-height: 350px; overflow: auto"
            :header-cell-style="{ backgroundColor: '#eee' }"
          >
            <el-table-column prop="oecode" label="配件编码">
              <template slot-scope="scope">
                <input type="text" v-model="scope.row.oecode" />
              </template>
            </el-table-column>
            <el-table-column prop="goods_storage" label="订购数量" width="220">
              <template slot-scope="scope">
                <input type="text" v-model="scope.row.goods_storage" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            isBatch = false;
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handSearch()">搜索</el-button>
      </div>
    </el-dialog>
    <div class="index-kernel-dialog">
      <el-dialog
        title="提示"
        :visible.sync="$store.state.invDia"
        width="360px"
        center
      >
        <div class="body-center">
          <img src="../../assets/index/inv-icon.png" alt="" />
          <div class="msg">已超过库存限额，请您修改订购数量</div>
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
// 引入步骤组件
import StepList from "../../components/StepList"

  
  

export default {

  data() {
    return {
      // 批量采购
      dialogFormVisible: false,
      textarea: "",
      batchs: [],
      searchData: {},

      navList:[
        {
          title:"首页",
          path:"/index/navhome/self",
          showNav:this.$store.getters.notLimitBrands
        },
        {
          title:"集中采购",
          path:"/purchase/index",
          showNav:false
        },
        {
          title:"找配件",
          path:"/find/index",
          showNav:true
        },
        {
          title:"品牌中心",
          path:"/brand",
          showNav:true
        },
        {
          title:"积分商城",
          path:"/integral",
          showNav:this.$store.state.is_int_show==0&&this.$store.getters.notLimitBrands
        },

        // {
        //   title:"精准找件",
        //   path:"",
        //   showNav:true
        // },
        // {
        //   title:"品牌宣讲",
        //   path:"/talkBrand",
        //   showNav:true
        // },
        // {
        //   title:"招商加盟",
        //   path:"",
        //   showNav:true
        // },
        
      ],
      batchWay:1,
      shopInfo: {},
      searchText: "",
      storeText: "",

      // 当前页面的模式
      pageMode: 1,
      // 当前导航的标题
      nowNavTitle: "1",
      activeIndex:0,
      // 弹出的banner
      banner: [],
      showAdcer: false,
      editPassDia: false,
      showDiaVisGoods:"",
      showDiaVis:false,
      showDiaPurGoods:"",
      showDiaPur:false,
      purDayGoodsList:[],
      purGoodsList:[]
    };
  },
  updated(){ 
  },
  mounted(){
    if(this.$store.state.shop==1780){
      // if(this.$store.state.is_sh){
      //   console.log("存在is_sh")
      // }else{
      //   console.log("不存在is_sh")
      //   localStorage.removeItem("hnw-web-state")
      //   this.$store.commit("resetState")
      //   this.$router.push("/login")
      // }
    }
  },
  methods: {
    // 采购报表
    goStatement() {
      this.$router.push("/statement");
    },
    closePassDia() {
      this.editPassDia = false;
      localStorage.setItem("pass-dia-save", "never");
    },
    refuse() {
      window.location.reload(true)
    },
    async bannerJump(banner) {
      if(!this.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
      let tempArr = banner.data.split("&"); 
      if (tempArr[1] && tempArr[1] != "undefined") {
        localStorage.setItem("web-banner-body-img", jumpMsg.data.split("&")[1]);
        this.$router.push("/bodyImg");
      } else if (tempArr[2]) {
        this.$store.commit("UpDateState", {
          searchText: tempArr[2],
        }); 
        this.$router.push("/find/index");
      }
      this.showAdcer = false;
    },
    // 模板下载
    execlDown() {
      let link = document.createElement("a");
      link.href = "https://api.huanuowei.cn/data/upload/批量查询模板.xlsx";
      link.click();
    },
    async querySearch(str, cb) {
      if (!this.searchText) {
        // ...
        let res = await this.$api.getSearchRecord({
          record_type: 3,
        });
        cb(res);
      } else {
        cb([]);
      }
    },
   
    // 菜单选中
    menuSelect(item) {},
    // 搜索方法
    search() { 
      // if(!this.$store.state.token){
      //   this.$warMsg("请先去登录")
      //   return this.$router.push("/login")
      // }
      // if (!this.$isCompany()) {
      //   return;
      // }
      // 判断是否有网
      if (!window.navigator.onLine) {
        location.reload(true);
      }
      // this.searchText = this.searchText.toUpperCase();

      // 搜索得不是空
      if (this.searchText == this.$store.state.searchText) {
        if (this.searchText === "") {
          return this.$warMsg("请输入要搜索的内容");
        } else {
          this.$store.commit("UpDateState", {
            searchText: "",
          });

          // return this.$warMsg("请不要重复搜索")
        }
      }

      if (this.$route.path == "/find/index") {

        this.$store.commit("UpDateState", {
          searchText: this.searchText,
        });
      } else {
        this.$store.commit("UpDateState", {
          searchText: this.searchText,
        });

        this.$router.push("/find/index");
      }
      this.$store.commit("UpDateState", { 
        batchs: [],
        searchOesort:"" 
      });
      this.activeIndex = 2
    },

    searchGoods() {
      this.$store.commit("UpDateState", {
        searchText: this.searchText,
      });
    },

    // 切换模式
    navChange(nav,index) {
     
      this.activeIndex = index
      if(nav.path){
        this.toPath(nav.path)
      }
    },
    toPath(path){
			this.$router.push(path)
		},
    storeToCart() {
      this.$router.push("/shopcar/1/" + 2);
    },
    // 退出登录deng
    loginOut() {
      this.$router.push("/login");
      // if (!this.$store.getters.notLvD) {
      // } else {
      //   this.$router.push("/");
      // }
      localStorage.removeItem("hnw-web-state");
      this.$store.commit("resetState");
    },
    goRecommend(acti){
      this.showDiaVis=false
      if(!this.token){
        this.$warMsg("请先去登录")
        return this.$router.push("/login")
      }
      if(this.$isCompany()){
        acti.preRou = "首页"
        if(acti.g_id){
          acti.goods_id = acti.g_id
        }
        let rou = '/index/goodsinfo'
        localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
        
        this.$router.push(rou)
      }
    },
    DiaVisClose(){
      let day = new Date().getDay()
      localStorage.setItem("hnw-web-show-dis-day",day)
    },
    toMymodule(path) {
      if (!this.$isCompany()) {
        return;
      }
      this.toPath(path)
    },
    toCart(type) {
      if(!this.token){
        this.$warMsg("未登录账号")
        return this.$router.push(`/login`);
      }
      if (!this.$isCompany()) {
        return;
      }
      this.$router.push("/shopcar/1/" + type);
    },
    toUn() {
      this.$router.push("/union/allGoods");
    },
    toHome() {
      this.$router.push("/");
      this.activeIndex = 0
      // if(this.$store.getters.notLimitBrands){
      //   if (this.$store.state.shop && this.$store.state.shop != 1780) { 
      //     this.$router.push(`/store/businesses/${this.$store.state.shop}`);
      //   } else if (this.$store.state.for_branch) {
      //     this.$router.push(`/store/businesses/${this.$store.state.for_branch}`);
      //   } else { 
      //     if (this.pageMode == 2) {
      //       this.$router.push("/find/index");
      //     } else {
      //       this.$router.push("/");
      //     }
      //   }
      // }else{
      //   this.$router.push("/find/index");
      // }
    },
    toOrder() {
      if (!this.token) {
        this.$warMsg("未登录账号");
        return this.$router.push("/login");
        // window.open("http://www.huanuowei.cn/#/login/self")
      } else {
        this.$router.push({ path: "/my/order" });
      }
    },
    toUnion() {
      if (!this.token) {
        this.$warMsg("未登录账号");
        return this.$router.push("/login");
      } else {
        this.$router.push({ path: "/union/home" });
      }
    },
    toHnwPur() {
      if (!this.token) {
        this.$warMsg("未登录账号");
        return this.$router.push("/login");

        
      } else if (this.register_from == 2) {
        return this.$warMsg("您的账号不能登录企业中心");
        
      } else if (this.$store.state.shop && this.$store.state.shop != 1780) { 
      } else {
        let acc = this.account_number + "|" + this.password;
        let mixText = this.$hnwTools.mixStr(acc, 1); 

        if (this.$axios.defaults.baseURL == "https://jc.hnwmall.com/web/") {
          window.open("http://jc.hnwmall.com:6600/#/login/" + mixText);
        } else if (
          this.$axios.defaults.baseURL == "https://test.huanuowei.cn/api/web/"
        ) {
          window.open("https://test.huanuowei.cn/store/#/login/" + mixText);
        } else if (
          this.$axios.defaults.baseURL == `https://api.huanuowei.cn/web/`
        ) { 
          if (this.$store.state.member_id.indexOf("-") == -1) {
            // window.open("https://b.huanuowei.cn/store/#/login/"+mixText)
            window.open("https://huanuowei.cn/store/#/login/" + mixText);
            // window.open(`https://api.huanuowei.cn/store/#/login/`+mixText)
          } else {
            this.$warMsg("账户暂无权限");
          }
        } else { 
          window.open("https://www.huanuowei.cn/#/login/" + mixText);
        }
      }
    },
    toVin() {
      // 判断是否登录
      // if (!this.token) {
      //   this.$warMsg("未登录账号");
      //   return this.$router.push("/login");
      // } else if (this.$isCompany()) {
        
        
      // }
      let link = location.href.split("#")[0];

      if (this.$store.state.shop == 2018) {
        // window.open(link + '#/catalog/home/1')
        window.open(link + "#/elecatalog/index");
      } else {
        window.open(link + "#/epc/home/vin");
      }
    },
    async getBussiessInfo() { 
      let res = await this.$api.getShopList({});

      let shopList = res;
      this.shopInfo = shopList.find(
        (shop) => shop.store_id == this.$route.params.id
      );
      if (this.$store.state.for_branch) {
        let url = this.shopInfo.url; 
        this.shopInfo = JSON.parse(this.shopInfo.branch_info);
        this.shopInfo.url = url;
      } 
      if (this.shopInfo) {
        this.shopInfo.zyGoods = this.shopInfo.store_zy.split("，");
        // store_certificate
        // 证书列表

        this.shopInfo.store_all_banner_url =
          this.shopInfo.url + this.shopInfo.store_all_banner;
        this.shopInfo.store_banner_url =
          this.shopInfo.url + this.shopInfo.store_banner;
        this.shopInfo.store_label_url =
          this.shopInfo.url + this.shopInfo.store_label;
        this.shopInfo.store_recom_banner_url =
          this.shopInfo.url + this.shopInfo.store_recom_banner;

        this.shopInfo.cerImgs = [];
        if (this.shopInfo.store_certificate) {
          let cerImgs = this.shopInfo.store_certificate.split(",");
          cerImgs.forEach((img) => {
            this.shopInfo.cerImgs.push(this.shopInfo.url + img);
          });
        }

        this.$store.commit("UpDateState", {
          shopInfo: this.shopInfo,
        });
      }
    },

    async triggerFile() {
      this.$refs.ExelFile.click();
    },
    async skuBatch(ev) {
      let file = ev.target.files[0];
      this.importfxx(file);
    },
    importfxx(obj) {
      let _self = this;
      // 通过DOM取文件数据
      this.file = obj;
      let rABS = false; //是否将文件读取为二进制字符串
      let f = this.file;
      let reader = new FileReader();

      FileReader.prototype.readAsBinaryString = function (f) {
        let binary = "";
        let rABS = false; //是否将文件读取为二进制字符串
        let pt = this;
        let wb; //读取完成的数据
        let outdata = [];
        let fileData = {};
        let allData = [];
        let reader = new FileReader();
        reader.onload = function (e) {
          let bytes = new Uint8Array(reader.result);
          let length = bytes.byteLength;

          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          let XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }

          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); //outdata就是你想要的东西 
          outdata.forEach((item) => {
            if(item["生产编码/OE号"]){
              item.oecode = item["生产编码/OE号"];
              item.search = item["生产编码/OE号"];
            }else{
              item.oecode = item["oecode"];
              item.search = item["oecode"];
            }
            if(item.订购数量){
              item.goods_storage = item.订购数量 || 1;
            }else if(item.num){
              item.goods_storage = item.num || 1;
            }
          });

          _self.batchs = outdata; 
        };
        // 再以二进制读取
        reader.readAsArrayBuffer(f);
      };

      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    goStore() { 
      if(!this.token){
        this.$warMsg("未登录账号")
        return this.$router.push(`/login`);
      }
      if (this.$store.getters.notLvD) {
        this.dialogFormVisible = true;
      } else {
        this.$router.push({
          path: `/store/businesses/${this.$store.state.shop}`,
        });
        this.dialogFormVisible = true;
      }
    },
    async handSearch() {
      console.log(" this.batchs.forEach",this.batchs);
      
      this.batchs.forEach((item) => {
        // item.search = (item.oecode+"").replace(/[\W]/g,'')
        if(item.oecode){
          item.oecode = item.oecode.toUpperCase().trim();
        }
        if(item.search){
          item.search = item.search.toUpperCase().trim();
          if (item.oecode != item.search.replace(/\s/g, "")) { 
            item.search = item.oecode;
          }
        }
      });

      this.$store.commit("UpDateState", {
        batchs: JSON.parse(JSON.stringify(this.batchs)),
      });

      if (this.$store.getters.notLvD) {
        this.$router.push({ path: "/find/index" });
      } else {
        this.$router.push({
          path: `/store/businesses/${this.$store.state.shop}`,
        });
      }
      this.dialogFormVisible = false;
    },
    paste(e) {},
    trigger() {
      this.$refs.batchRef.focus();
    },
    resetPaste() {
      this.batchs.splice(0, this.batchs.length);
      this.textarea = "";
    },
  },

  computed: {
    ...mapState([
      "company_name_jc",
      "store_id",
      "cartNums",
      "token",
      "account_number",
      "password",
      "member_name",
      "register_from",
      "role_name",
      "member_id",
      "storeShopId",
      "nickname",
    ]),

    
    showPlaceh() {
      return this.batchs.length < 1;
    },
  },
  // immediate: true,
  watch: {
    "$route.path": {
      deep: true,
      immediate: true,
      async handler(rou) {
        let index = this.navList.findIndex(item=>rou.includes(item.path))
        this.activeIndex = index
        // if(index!=-1){
        //   this.activeIndex = 0
        // }else{
        //   this.activeIndex = index
        // }
        // 处理页面是否显示导航

        if (/\/shopcar/.test(rou)) {
          this.pageMode = 2;
        } else if (/\/my/.test(rou)) {
          this.searchText = "";
          this.pageMode = 3;
        } else if (/\/businesses/.test(rou)) {
          this.pageMode = 4;
          this.getBussiessInfo();
        } else if(/\/integral/.test(rou)){
          //  
          this.pageMode = 5;
        } else {

          this.pageMode = 1;
          this.$store.commit("UpDateState", { stepIndex: 1 });
        }

        // console.log("pageMode",this.pageMode)
      },
    },
    textarea(new2) {
      if (!new2) {

        return this.batchs.splice(0, this.batchs.length);
      }
      this.batchs = [];

     
      let data = new2.trim();
      // 按换行切割
      let datas = data.split(/\n/);
      datas.forEach((item) => {
        // 按空格切割
        let row = item.split(/\t/);
        let search = row[0];
        let oecode = row[0].replace(/\s/g, "");
        let goods_storage = row[1] || 1;
        // 有的oe号可能有空格

        oecode = oecode.trim();
        this.batchs.push({
          oecode,
          search,
          goods_storage,
        })
        
      });
    },
  },
  // 路由跳转之前
  beforeRouteUpdate(to, from, next) {
   
    if(this.$store.getters.onlyBrand){
      // 可以去的网址 
      let urls = "/brand"
      if(urls.includes(to.path)){
        scrollTo(0, 0);
        next();
      }
      
    }else{
      // 获取预购单数量
      if (this.$store.state.member_id) {
  
        this.$store.dispatch("getAdvCart");
      }
      scrollTo(0, 0);
      next();

    }
  },
  beforeCreate() {
    // function getQueryString(name) {
    // 	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    // 	var r = window.location.search.substr(1).match(reg);
    // 	if (r != null) return decodeURI(r[2]);
    // 	  return null;
    //  }
    // if(!window.name){
    // 	var str = Math.random().toString(36).substr(2);//随机字符串
    // 	var S = getQueryString('S')
    // 	window.name = 'isreload';
    // 	if (S) {
    // 		window.location.href = window.location.href.replace(/^([^&]*)\?([^&]*)&?S=([^&]+)(\S*)$/, function(match,$1,$2,$3){
    // 			return match.replace($3, str)
    // 		})
    // 	}else{
    // 		if(window.location.search.indexOf('?') > -1){
    // 			window.location.search += 'S=' + str;
    // 		}else{
    // 			window.location.search = 'S=' + str;
    // 		}
    // 	}
    // }else{

    // 	window.name = ''
    // }
  },
  async created() {
    console.log(" $store.state",this.$store.state);
    // 初始化
    // 要执行的命令  
    
    if(this.token){
    
        this.$api.getAnalysisLog("online")
        // window.addEventListener('beforeunload', function (event) {
        //   this.$api.getAnalysisLog("网站关闭")
          
        // });

        let resAcc = await this.$api.getAccMsg({
          member_id: this.$store.state.member_id,
          type: this.$store.state.role_name == "all" ? 1 : 2,
        })

        if(resAcc.store_info){
          this.$store.commit("UpDateState", { 
            is_limit: resAcc.store_info.is_limit,
            is_int_show: resAcc.store_info.is_int_show
          });
          if(resAcc.store_info.is_int_show!=0){
            this.navList = this.navList.filter(item=>!item.title.includes('积分')) 
          }
        }
        this.$store.commit("UpDateState", { 
          nickname: resAcc.nickname,
          brands:resAcc.brands
        }); 
        this.$store.dispatch("getCartNums");
        console.log("进入");

        // 获取展示列表
        let day = localStorage.getItem("hnw-web-show-dis-day")
        let nowDay = new Date().getDay()
        if(day!=nowDay){
          let showDiaImgRes = await this.$api.getDisList({
            show:1,
            type:1
          })
          if(showDiaImgRes.length>0){
    
            this.showDiaVisGoods = showDiaImgRes[0]
            this.showDiaVis = true
          }
        }
    }
    
    if (this.$store.state.shop == 1974) {
      document.title = "上海昕豫汽配自营店";
    } else if (this.$store.state.shop == 1905) {
      document.title = "成都华诺威汽配";
    } else if (this.$store.state.shop == 1780) {
      document.title = "华诺威_汽配供应链专家";
    } else if (this.$store.state.shop == 1871) {
      document.title = "上海挺海汽配自营店";
    } else if (this.$store.state.shop == 1870) {
      document.title = "济南祥泰";
    } else if (this.$store.state.shop == 2018) {
      document.title = "优百佳汽配自营店";
    } else if (this.$store.state.shop == 1881) {
      document.title = "楚佑汽配";
    } else if (this.$store.state.shop == 1975) {
      document.title = "中润丰汽配";
    } else if (this.$store.state.shop == 2097) {
      document.title = "上海鑫崇芳汽配自营店";
    } else if (this.$store.state.shop == 2136) {
      document.title = "开放店铺";
    } else if (this.$store.state.shop == 2029) {
      document.title = "景阳城汽配";
    } else if (this.$store.state.shop == 1855) {
      document.title = "西铁汽配";
    } else if (this.$store.state.shop == 1880) {
      document.title = "北京鸿宇";
    } else if (this.$store.state.shop == 1948) {
      document.title = "长沙中驰汽配自营店";
    } else {
      document.title = "华诺威_汽配供应链专家";
    }
    // else if (this.$store.state.shop == 1876) {
    //   document.title = "航黔汽配自营店";
    // } 
    let orgin = window.location.hostname;

    let storeInfo = this.$hnwTools.storeUrls.find(
      (store) => store.url == orgin
    ); 
    if (storeInfo) { 
      if (storeInfo.id) {
        return this.$router.push(`/store/businesses/${storeInfo.id}`);
      } else {
        console.log("当前网址未开通店铺!");
      }
    }

    let verRes = await this.$api.handleVer({
      model: 2,
    });
    let vers = localStorage.getItem("hnw-web-vers");
    if(vers!="undefined"){
      vers = JSON.parse(vers);

      if (vers != verRes) {
        localStorage.setItem("hnw-web-vers", JSON.stringify(verRes));
        location.reload(true);
      }
    }
     

    setTimeout(() => {
      let oldDay = localStorage.getItem("hnw-web-advDay");

      let day = new Date().getDay();

      if (day != oldDay && this.banner.length > 0) {
        if (
          this.$store.state.for_branch &&
          this.$store.state.for_branch != 1780
        ) {
          return;
        }
        if (this.$store.state.shop && this.$store.state.shop != 1780) {
          return;
        } 
        if (this.$store.state.member_id) {
          this.showAdcer = true;
          localStorage.setItem("hnw-web-advDay", day);
        }
      }
      // 获取是否已经改过密码或者不改密码
      let passDis = localStorage.getItem("pass-dia-save");
      let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/);

      if (this.$store.state.store_id&&!passDis && !reg.test(this.$store.state.password)&&this.$store.state.store_id!=1780) {
        this.editPassDia = true;
      }
    }, 1000);
    
    let seting = await this.$api.getCommonSeting();

    this.$store.commit("UpDateState", {
      zero_buy: seting.zero_buy,
    });
    let state = JSON.parse(localStorage.getItem("hnw-web-state"));

    if (!state) {
      localStorage.removeItem("hnw-web-state");
      this.$store.commit("resetState");
      // return this.$router.push("/login")
    } else {
      this.$store.commit("UpDateState", state);
      if (state.sc_bail) {
        state.sc_bail = Number(state.sc_bail);
      }
    }

    this.$nextTick(async () => {
      let authText = ""; 
      if (!this.$store.state.store_id) {
        let data = await this.$api.getAuthenState(this.$store.state.member_id);

        if (data.joinin_state == 11) {
          authText = "正在审核";
        } else {
          authText = "前去认证";
        }
      }
      if (this.$store.state.member_id) {
        
        const getPurInform = async ()=>{

         let res = await this.$api.getStockInform()
         try {
          
           if(res.today.length!=this.purDayGoodsList.length){
             let oldids = this.purDayGoodsList.map(goods=>goods.goods_id).join()
             if(oldids){
              let ids = res.today.map(goods=>goods.goods_id).join()
              if(oldids!=ids){
                this.purDayGoodsList =  res.today
                this.purGoodsList = []
                res.no_orders.forEach(goods=>{
                  if(!ids.includes(goods.goods_id)){
                    this.purGoodsList.push(goods)
                  }
                })
                this.showDiaPur = true
              }else{
                // 无变化
              }
             }else{
               this.purDayGoodsList =  res.today
               let ids = res.today.map(goods=>goods.goods_id).join()
               this.purGoodsList = []
                res.no_orders.forEach(goods=>{
                  if(!ids.includes(goods.goods_id)){
                    this.purGoodsList.push(goods)
                  }
                })
               this.showDiaPur = true
             }
           }
         } catch (error) {
          
         }
        
        }
        getPurInform()
        setInterval(() => {
          try {
            getPurInform()
          } catch (error) {
            
          }
        }, 1000*60*10);
      }

    
      // 判断是否是开放平台
      if (
        this.$store.state.shop == 2136 ||
        this.$store.state.shop == 2117 ||
        window.location.origin == "https://open.huanuowei.cn"
      ) {
        // 这是开放平台
        this.$store.commit("UpDateState", {
          isWebKfpt: true,
        });
      } else {
        this.$store.commit("UpDateState", {
          isWebKfpt: false,
        });
      }

    });
  },
  components: {
    StepList
  },
};
</script>

<style lang="scss">
.pre-cart-tips{
  // 预购单提醒样式
  .el-notification__content{
   
    overflow: hidden;
    p{
      .tips{
        padding: 0 5px;
        font-weight: 700;
        overflow-wrap: break-word;
        word-break: break-all;
        // color: red;
      }
    }
  }
}
@font-face {
  font-family: STFWXQ;
  src: url(../../styles/书体坊王学勤钢笔行书.ttf);
}
.gray {
  background: $backGray;
}
.white {
  background: #fff;
}
.hnw-index {
  overflow: hidden;
  min-width: 1200px;
  // min-height: 100%;
  .hnw-home-index-dia {
    .el-dialog {
      background: transparent;
    }
    .el-dialog__header {
      padding: 0;
      border-bottom: none;
    }
    .el-dialog__body {
      padding: 0;
      background: transparent;
    }
    .adver-banner {
      position: relative;
      .clear-btn {
        position: absolute;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border: 1px solid #2041af;
        right: 10px;
        top: 4%;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2041af;
        cursor: pointer;
        i {
          font-size: 18px;
          font-weight: 700;
        }
      }
      .el-carousel__indicator--horizontal {
        .el-carousel__button {
          background: #c1e2ff;
        }
      }
      .is-active {
        .el-carousel__button {
          background: #2851c3;
        }
      }

      .el-carousel__arrow {
        transform: scale(2);
        i {
          font-size: 18px;
        }
      }
      .el-carousel__item {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .hnw-index-password-edit {
    .el-dialog {
      background: transparent;
    }

    .el-dialog__header {
      padding: 0;
      border-bottom: none;
    }
    .el-dialog__body {
      padding: 0;
      background: transparent;
      .edit-password-img {
        background: transparent;
        position: relative;
        img {
          
        }
        > div {
          position: absolute;
          // border: 1px solid #000;
          cursor: pointer;
        }
        .close-btn {
          width: 40px;
          height: 40px;
          // border: 1px solid #000;
          right: 20px;
          top: 14px;
        }
        .btn {
          width: 140px;
          height: 60px;
          right: 50%;
          bottom: 36px;
        }
        .edit-btn {
          transform: translateX(160px);
        }
        .not-dia-btn {
          transform: translateX(-16px);
        }
      }
    }
  }
  .advertisement-ball {
    position: fixed;
    bottom: 30%;
    right: 10px;

    background: #3E84D6;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    i {
      font-size: 24px;
      color: #fff;
    }
  }

  .active-text {
    color: #3E84D6;
  }
  // 预购单 公用样式
  .cart-adv-btn {
    width: 100px;
    height: 42px;
    background: #eeeeee;
    border-radius: 4px;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
    color: #858585;
  }
  // 到货
  .aog {
    display: flex;
    align-items: center;
    color: red;
    font-size: 12px;
    .aog-text {
      animation: smallToBig 0.8s infinite linear alternate;
      width: 32px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: #ff0000;
      border-radius: 4px;
      color: #fff;
      margin-right: 10px;
    }
    .btn-text {
      font-size: 14px;
    }
  }
  @keyframes smallToBig {
    0% {
      transform: scale(0.8) translateZ(0);
    }
    100% {
      transform: scale(1.5) translateZ(0);
    }
  }
  .el-header {
    padding: 0;
    border-bottom: 1px solid #F4F4F4;
    height: 40px;
    .hnw-header {
      font-size: 12px;
      height: 100%;
      padding: 12px 0;
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666;
      .shu {
        height: 14px;
        width: 1px;
        // background: #333333;
        margin: 0 10px;
      }
      .logo {
        display: flex;
        align-items: center;
        z-index: 10;
        cursor: pointer;
        .re-home {
          cursor: pointer;
          display: flex;

          img {
            margin-right: 4px;
            width: 12px;
          }
          span {
            position: relative;
            top: 1px;
          }
        }
      }
      .img-box {
        width: 66px;
        height: 66px;
        img {
        }
      }
      .header-right {
        height: 30px;
        .el-menu {
          background: transparent;
          .shu {
            float: left;
            margin-top: 7px;
            // background: #999999;
          }
          .to-hnw-pur {
            display: flex;
            align-items: center;
            img {
              width: 14px;
              margin-right: 2px;
            }
          }
          .el-submenu {
            .el-submenu__title {
              padding: 0;
              font-size: 12px;
              color: #333;
              height: 30px;
              flex-shrink: 0;
              background: transparent;
              border-bottom: none;
              display: flex;
              align-items: center;
              &:hover {
                background: transparent;
              }
              i {
                margin-left: 3px;
                transform: scale(0.7);
                color: #999;
              }
              .my-hnw {
                height: 30px;
                line-height: 30px;
              }
            }
            .el-submenu__icon-arrow {
              margin-top: 0;
            }
          }
          .is-active {
            .el-submenu__title {
              border-bottom: none;
              padding: 0;
              font-size: 12px;
            }
          }
          .el-menu-item {
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #333;
            padding: 0;
            border: none;
            flex-shrink: 0;
            background: transparent;
            &:hover {
              background: transparent;
            }
          }
        }

        .el-menu--horizontal {
          border-bottom: none;
        }
      }
    }
  }
  .el-main {
    overflow: hidden;
    padding: 0;
    
    #watermark-overlay {  
      position: fixed;  
      top: 0;  
      left: 0;  
      width: 100%;  
      height: 100%;  
      pointer-events: none; /* Makes the watermark non-interactive */  
      z-index: 99; /* Ensure it's on top of other content */  
      display: flex;  
      flex-direction: column;  
      justify-content: space-between; /* Distribute rows evenly */  
      align-items: center; /* Center cells horizontally */  
      overflow: hidden; /* Hide overflow content if needed */  
    }  
      
    .watermark-row {  
      display: flex;  
      justify-content: space-between; /* Distribute cells evenly */  
      width: 100%;  
      height: calc(100% / 2); /* Each row takes up 1/3 of the height */  
      transform: rotate(-10deg);
    }

    .watermark-cell { 
      display: flex;  
      flex-direction: column;  
      justify-content: center;  
      align-items: center;  
      width: calc(100% / 3 ); /* Each cell takes up 1/6 of the width minus some margin */  
      margin: 5px; /* Add some margin between cells */  
      text-align: center;  
      color: #eee;
      opacity: .5;
      font-size: 20px; /* Adjust font size as needed */  
      transform: rotate(-20deg); /* Rotate each cell to counteract the row rotation */  
      // color: rgba(255, 255, 255, 0.5);  
    }  
      
    .watermark-text,  
    .watermark-number {  
      margin: 2px 0; /* Add some vertical spacing between text and number */  
    } 
    .main-box {
      .main-head {
        justify-content: space-between;
        display: flex;
        align-items: center;

        width: $centerW;
        margin: 12px auto 0;
        .logo {
          display: flex;
          align-items: center;
          cursor: pointer;
          
          // img {
          //   width: 112px;
          //   height: 50px;
          // }
          
          img {
            width: 112px;
            
            // height: 40px;
            margin-right: 10px;
            
          }
          // .logo-text-img {
          //   height: 26px;
          //   width: 170px;
          //   margin-left: -6px;
          // }
          .logo-text-img {
            // height: 26px;
            width: 170px;
            margin-left: -6px;
          }
          .logo-text {
            font-family: STFWXQ;
            font-size: 38px;
            color: rgb(49, 77, 140);
            -webkit-text-stroke: 0.2px rgb(49, 77, 140);
            margin-left: -7px;
            p {
              letter-spacing: -13px;
            }
          }
        }
        .search {
          display: flex;
          align-items: center;
          // 预购单要向后来点
          margin-right: -41px;
          // margin-right: 98px;
          .inline-input {
            width: 494px;
            height: 34px;
            border-radius: 0;
            .el-input__inner {
              border-radius: 0;
              height: 34px;
            }
          }
          .seach-btn {
            width: 100px;
            background: #3E84D6;
            color: #fff;
            border-radius: 0;
            height: 36px;
            position: relative;
            left: -2px;
            cursor: pointer;
          }
          .search-history {
            display: flex;
            .his-item {
              padding: 10px 10px 0 0;
              font-size: 12px;
              color: #999;
              cursor: pointer;
            }
            .his-item:hover {
              color: #3E84D6;
            }
          }
        }
      }
      
      // 新头部
      .newHead{
        margin: 15px auto;
        .head-left {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 112px;
            margin-right: 10px;
          }
          .logo-text-img {
            height: 26px;
            width: 170px;
            margin-left: -6px;
          }
          .left-text{
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            margin-left: 35px;
            color: #858585;
            
            border: 1px solid #C6C6C6;
            border-radius: 20px;
            width: 126px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
          }
        }
        .search{
          margin: 0;
          .inline-input{
            border-radius: 4px;
            border: 1px solid #063376;
            height: 46px;
            .el-input {
              .el-input__inner{
                height: 44px;
                margin-top: 1px;
                border: none;
              }
            }
          }
          .seach-btn{

            width: 146px;
            height: 48px;
            line-height: 50px;
            text-align: center;
            background: #063376;
            border-radius: 0px 4px 4px 0px;
          }
        }
        .shopcart-btns{
          .shopCart{
            width: 126px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #C6C6C6;
            margin-left: 12px;
            >div{
              width: 100%;
              background: #fff;
              height: 40px;
              line-height: 40px;
              text-align: center;
              cursor: pointer;
              margin: 0;
              .btn-text{
                top: 0;
              }
              
              .num{
                top: -2px;
              }
            }
            
          }
        }
        .inteNum{
            display: flex;
            align-items: center;
            font-weight: 700;
            text-align: center;
            margin-right:10px;
            .label{
                color: #999;
                padding-right: 5px;
            }
            .num{
                border-radius: 3px;
                background: red;
                color: #fff;
                min-width: 36px;
                box-sizing: border-box;
                padding: 5px 10px;
            }
        }
      }
      .shopcart-btns {
        display: flex;
        width: 275px;

        justify-content: flex-end;
        .shopCart {
          position: relative;

          .cart-btn {
            width: 128px;
            height: 42px;
            background: #eeeeee;
            border: none;

            border-radius: 4px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 13px;

            color: #3e84d6;
            img {
              width: 18px;
            }
            .btn-text {
              font-size: 14px;
              margin-left: 10px;
              position: relative;
              top: -2px;
            }
          }

          .num {
            position: absolute;
            top: 4px;
            left: 24px;
          }
        }
      }
      .my-header {
        height: 72px;
        background: #3E84D6;
        color: #fff;
        .hnw-area-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          .head-left {
            display: flex;
            align-items: center;

            .logoimg {
              width: 76px;
              height: 35px;
              cursor: pointer; 
            }
            .logo-text{
              font-family: STFWXQ;
              font-size: 38px;
              margin-right: 30px;
              >p{
                letter-spacing: -12px;
              }
            }
            .titleimg {
              width: 160px;
              height: 22px;
              margin: 0 15px 0 8px;
            }
            .title {
              font-size: 14px;
              text-align: center;
              > div {
              }
              > div:last-child {
                font-size: 12px;
                border-radius: 10px;
                border: 1px solid #dfeefd;
                padding: 4px 6px;
                cursor: pointer;
                margin-top: 6px;
              }
            }
            .lmth-btn {
              padding-top: 13px;
              padding-left: 10px;
              > div {
                font-size: 12px;
                border-radius: 10px;
                border: 1px solid #dfeefd;
                padding: 4px 6px;
                cursor: pointer;
                margin-top: 6px;
              }
            }
            .find-cls {
              background: #c81623;
              border: none !important;
            }
          }
          .head-right {
            display: flex;
            .search-box {
              margin-right: 60px;
              display: flex;
              input {
                outline: none;
                padding: 0 6px;
                width: 220px;
                border: none;
              }
              div {
                background: #3E84D6;
                border: 1px solid #fff;
                color: #fff;
                cursor: pointer;
                line-height: 36px;
                padding: 0 16px;
              }
            }
            .cart-adv-btn {
              height: 36px;
              margin-right: 10px;
            }
            .shopCart {
              width: 140px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
              .cart-btn {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                .carimg {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                }
                .btn-text {
                  color: #333;
                }
                .num {
                  position: absolute;
                  top: -6px;
                  left: 10px;
                }
              }
            }
          }
        }
      }
      // 店铺头
      .firm-head {
        // width: 1200px;
        height: 100px;
        background-color: #3e84d6;

        .hnw-area-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .head-left {
            height: 100px;
            // background-color: skyblue;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .store-head-left {
              .store-logo {
                // width: 280px;
                height: 40px;
                overflow: hidden;
                display: flex;
                align-items: center;
                img {
                  height: 40px;
                }
                .text-logo {
                  height: 29px;
                  margin-left: 10px;
                }
                .text-log-kfpt {
                  height: 40px;
                }
              }
            }
            .store-logo {
              height: 40px;
              margin-right: 10px;
              img {
                height: 40px;
              }
            }
            .logo1 {
              width: 78px;
              // height: 37px;
              // margin-right: 30px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .vertical {
              width: 2px;
              height: 30px;
              background-color: #ffffff;
              margin: 0 19px 0 15px;
            }
            .logo {
              width: 40px;
              height: 40px;
              margin-right: 26px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .news {
              margin-top: 40px;
              .news-name {
                font-weight: 800;
                font-size: 18px;
                padding-bottom: 10px;
                color: #fff;
              }
              .re-store {
                font-size: 12px;
                border-radius: 10px;
                border: 1px solid #dfeefd;
                padding: 4px 6px;
                cursor: pointer;
                margin-top: 6px;
                width: 90px;
                box-sizing: border-box;
                color: #fff;
                text-align: center;
              }
            }
          }
          .shopcart-btns {
            display: flex;
            align-items: center;
          }
          .head-right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 20px;
            .el-menu {
              background: transparent;

              .shu {
                float: left;
                margin-top: 7px;
                background: #999999;
              }
              .el-submenu {
                .el-submenu__title {
                  padding: 0 !important;
                  font-size: 12px;
                  color: #333;
                  line-height: 38px;
                  height: 38px;
                  flex-shrink: 0;
                  background: transparent;
                  border-bottom: none;
                  &:hover {
                    background: transparent;
                  }
                  i {
                    margin-left: 0;
                  }
                }
              }
              .el-submenu__icon-arrow {
                display: none;
              }
              .is-active {
                .el-submenu__title {
                  border-bottom: none;
                  padding: 0 !important;
                  font-size: 12px;
                }
              }

              .el-menu-item {
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                color: #333;
                padding: 0;
                border: none;
                flex-shrink: 0;
                background: transparent;
                text-align: center;
                > img {
                  width: 16px;
                  height: 16px;
                }
                &:hover {
                  background: transparent;
                }
              }
            }
            .portrait-img {
              width: 34px;
              height: 34px;
              border: 2px solid #fff;
              border-radius: 34px;
              margin: 0 20px;
              background-color: #fff;
              img {
                width: 100%;
                height: 100%;
                border-radius: 34px;
                vertical-align: baseline;
              }
            }
            .el-menu--horizontal {
              border-bottom: none;
            }
            .buShopCart {
              position: relative;
              .cart-btn {
                height: 26px;
                width: 26px;
                padding: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-radius: 0;
                background-color: #3e84d6;
                border: none;
                span {
                  width: 26px;
                  height: 26px;
                  > img {
                    width: 26px;
                    height: 26px;
                  }
                }
              }
              .btn-text {
                font-size: 14px;
                margin-left: 10px;
                position: relative;
                top: -2px;
              }
              .num {
                position: absolute;
                top: -4px;
                left: 20px;
              }
            }
          }
        }
      }
      .main-nav-box {
        .main-nav {
          height: 50px;
          position: relative;
          width: $centerW;
          // display: flex;
          align-items: center;
          margin: 0 auto;
          // margin-top: 15px;
          // padding-left: 310px;
          // padding-left: 255px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          // border-bottom: 1px solid #eee;
          .main-tabs {
            // height: 40px;
            // height: 35px;
            height: 50px;
            display: flex;
            align-items: center;
            .tab-item {
              padding: 0 20px;
              font-family: PingFang SC;
              cursor: pointer;
              font-size: 20px;
              color: #333333;
              line-height: 24px;
              transition: color .3s;
            }
            .active {
              color: #063376;
              font-family: PingFang SC;
              font-weight: bold;
              font-size: 20px;
              color: #063376;
            }
            .tab-item:hover {
              color: #063376;
              font-family: PingFang SC;
              font-weight: bold;
              font-size: 20px;
              color: #063376;
            }
          }
         
        
          // .el-tabs {
          //   margin-left: 235px;
          //   max-height: 40px;
          //   .el-tabs__active-bar {
          //     display: none;
          //   }
          //   .el-tabs__nav-wrap::after {
          //     background: transparent;
          //   }
          // }
          
        }
      }
      .main-body-box {
        position: relative;

        .main-body {
          // padding-top: 10px;
          // background-color: #f7f7f7;
        }
      }
      .el-backtop{
        flex-direction: column;
        width: 50px;
        height: 50px;
        .el-icon-caret-top{
          color: red;
        }
        .text{
          font-size: 14px;
          color: red;
        }
        
      }
      .all-up-btn{
        background-color: #fff;
				width: 110px;
				height: 48px;
				text-align: center;
				// line-height: 48px;
				border-bottom: 1px solid  #F2F2F2;
				cursor: pointer;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				i{
					margin-bottom: 2px;
				}
      }
      .nav-item:hover{
				background: #f00;
				color: #fff;

			}
    }
  }
  .el-footer {
    padding: 0;
    .feature-box{
      height: 120px;
      background: #FFFFFF;
      // padding-top: 35px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-top: 20px solid #f9f9f9;
      .feature-list{
        display: flex;
        justify-content: space-between;
        .feature-item{
          display: flex;
          align-items: center;
          img{
            margin-right: 10px;
            width: 50px;
          }
          >div{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
  .footer-bom{
    height: 140px;
    background: #1A1A1A;
    color: #fff;
    .footer-box{
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 100%;
      .footer-left{
        display: flex;
        align-items: center;
        margin-right: 80px;
        .links-copyright{
          margin-right: 110px;
          >ul{
            display: flex;
            li{
              border-right: 1px solid #979797;
              font-size: 12px;
              color: #e1e1e1;
              padding: 0 30px;
              cursor: pointer;
            }
            li:last-child{
              border: none;
            }
            li:first-child{
              padding-left: 0;
            }
          }
          .copyright{
            color: #979797;
            font-size: 12px;
            line-height: 24px;
            margin-top: 20px;
          }
          p{
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 24px;
            a{
  
            }
          }
        }
        .qrCode-wrap{
          display: flex;
          .line{
            width: 1px;
            height: 80px;
            background: #d6d6d6;
            margin: 0 20px;
          }
          .imgBox{
            text-align: center;
            img{
              width: 88px;
            }
            p{
              padding-top: 10px;
            }
          }
        }
      }
      .customer-service{
        p{
          color: #999999;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          line-height: 28px;
          span{
            cursor: pointer;
          }
        }
      }
    }
  }
  // 系统开发中样式
  .expl-info-dis {
    .img-box {
      margin-left: 120px;
    }
  }
  // 批量采购弹框
  .el-dialog__header {
    // border-bottom: 1px solid #dadada;
    padding: 14px 20px;
    .el-dialog__headerbtn {
      top: 15px;
    }
  }
  .el-dialog__body {
    padding: 10px 20px 20px 20px;
    .btn {
      display: flex;
      justify-content: center;
      > div {
        width: 99px;
        height: 39px;
        border: 1px solid #3e84d6;
        border-radius: 4px;
        line-height: 39px;
        text-align: center;
        font-size: 16px;
        color: #3e84d6;
        margin-right: 12px;
        cursor: pointer;
      }
      .atv {
        background-color: #3e84d6;
        color: #fff;
      }
    }
    .textBox {
      margin-top: 21px;
    }
    .hint {
      width: 96%;
      position: absolute;
      top: 22%;
      left: 2%;
      z-index: 6;
      background: #fff;
      pointer-events: none;
      .example-info {
        // width:100%;
        background: #f1f1f1;
        border-radius: 10px 10px 0px 0px;

        .red {
          color: red;
        }
        .blue {
          color: #3e84d6;
        }
        .gray {
          color: #999;
        }
        p {
          color: #333;
          line-height: 26px;
          padding-left: 20px;
        }
      }
      .example-table {
        padding-top: 10px;
        .table-head {
          background: #3e84d6;
          display: flex;
          > div {
            font-weight: 600;
            color: #fff;
            padding: 10px 20px;
            flex: 1;
            text-align: center;
          }
        }
        .table-body {
          border: 3px solid #f00;
          box-sizing: border-box;
          .body-row {
            display: flex;
            > div {
              flex: 1;
              padding: 10px;
              text-align: center;
            }
            > div:first-child {
              border-right: 1px solid #ccc;
            }
          }
          .body-row {
            border-bottom: 1px solid #ccc;
          }
        }
      }
    }
    .lead {
      .lead-text {
        font-size: 18px;
        color: #3e84d6;
        padding: 20px 0 10px;
      }
      .inp {
        display: flex;
        justify-content: space-between;
        width: 96%;
        height: 44px;
        line-height: 44px;
        background: #f1f1f1;
        border: 1px solid #c2c2c2;
        border-radius: 8px;
        .import {
          width: 111px;
          height: 44px;
          background: #3e84d6;
          border: 1px solid #c2c2c2;
          border-radius: 8px;
          color: #fff;
          text-align: center;
          line-height: 44px;
          img {
            vertical-align: middle;
            width: 16px;
            height: 13px;
          }
        }
      }
      .require {
        margin-top: 20px;
        p {
          color: #333;
          line-height: 26px;
        }
        .red {
          color: red;
          font-size: 16px;
        }
        .blue {
          color: #3e84d6;
        }
        .download {
          width: 60px;
          padding: 5px;
          background-color: #3e84d6;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }
  .el-dialog__footer {
    padding-bottom: 10px;
    background: #f1f2f5;
    text-align: center;
  }
  .batch-table {
    .paste-res {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      align-items: center;
    }
    .table {
      border: 1px solid#eee;
      .el-table {
        &::before {
          height: 0;
        }
      }
    }
  }
  .index-kernel-dialog {
    .el-dialog__header {
      padding: 10px;
      border-bottom: 0.5px solid #a0c0ff;
      .el-dialog__headerbtn {
        top: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px;
      padding-bottom: 22px;
      .body-center {
        text-align: center;
        .msg {
          margin-top: 10px;
        }
      }
    }
  }
  .dis-goods-show{
      background: transparent;
      .el-dialog{
        background: transparent;
        box-shadow: 0 0px 0px ;
      }
      .el-dialog__header{
        background: transparent;
        padding: 0;
      }
      .el-dialog__body{
        // background: transparent;
        padding: 0;
        display: flex;
        justify-content: center;
        
      }
      .vis-img-box{

        background: transparent;
        position: relative;
        text-align: center;
        width: 70%;
        .closeBtn{
          position: absolute;
          right: 40px;
          top: 0px;
          width: 100px;
          height: 100px;
          cursor: pointer;
          // background: #000;
        }
        img{
          
        }
    }
  }
}
.pur-dis{
  
  .el-dialog__headerbtn{
    top: -6% !important;
    right: 9%; 
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 4px;
    .el-icon-close{
      
      color: #fff;
    }
  }
  .pur-content-box{
    width: 70%;
    position: relative;
    .closeBtn{
      position: absolute;
      // background: #000;
    }
    .daohuobeijing{
      width: 100%;
    }
    .pur-list-box{
      position: absolute;
      // background: #000;
      left: 8%;
      top: 21%;
      height: 70%;
      width: 84%;
      display: flex;
      flex-direction: column;

      .pur-modu{
        flex: 1;
        .modu-title{
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          padding: 10px 0;
        }
        .goods-list{
          background: #FFFDFB;
          border-radius: 4px;
          border: 1px solid #F7D7AF;
          overflow-y: auto;
          // height: 84%;
          height: 75%;
          .goods-row{
            display: flex;
            border-bottom: 1px solid #fde8cf;
            padding: 10px 0;
            .goods-col{
              color: #999999;
              padding:0 10px;
              width: 40%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal; /* Ensure normal white-space handling */
              .label{

              }
             
            }
            .goods-col:first-child{
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              
            }
            .goods-col:last-child{
              width: 20%;
            }
          }
          .goods-row:last-child{
            border: none;
          }
        }
      }
    }
    .buy-box{
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
      .btn{
        width: 118px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        background: linear-gradient(-89deg, #FF9008 0%, #FFBD69 100%);
        box-shadow: 0px 2px 4px 0px rgba(255,207,127,0.21);
        border-radius: 20px;
        color: #fff;
      }
    }
  }

}
</style>
