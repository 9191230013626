import { post } from "axios"
import store from '../../../store'
import Vue from 'vue'
// 加入购物车
const joinCart = async (cart)=>{
    let hasPur = false
    if(cart.length>1){
        console.log("批量添加中");
        hasPur = cart.some(goods=>goods.goods_storage==0)
    }
    cart.forEach(ca => {
        
        ca.buyer_id = store.state.store_id
        ca.buyer_name = store.state.member_name
        ca.member_id = store.state.member_id
        ca.order_name = store.state.store_name
        if(ca.goods_price=="详询销售"){
            ca.goods_price = "99999"
        }
    });
    
    let joinRes = await post("index.php?act=store_cart&op=cartaddAll",{
        cart,
        store_id:store.state.store_id,
        shop_id:store.state.shop
    })
    if(joinRes){
        store.dispatch("getCartNums")
        if(hasPur){
            Vue.prototype.$suMsg("添加成功,部分无货商品已添加至预购单")
            // Vue.prototype.$suMsg("添加成功")
        }else{
            Vue.prototype.$suMsg("添加成功")
        }
    }else{
        Vue.prototype.$erMsg("添加失败")
    }
    return joinRes
}
// 删除
/**
 * 
 * @param {array} cart 删除购物车id列表
 */
const delCart = async (cart)=>{
    let res = await post("index.php?act=store_cart&op=cartDel",{
        cart
    })
    if(res){
        store.dispatch("getCartNums")
    }
    return res
}
// 修改购物车数量
const changeCartNums = async (data)=>{
    let res =  await post("index.php?act=store_cart&op=cartNumEdit",{
        ...data
    })
    if(res){
        store.dispatch("getCartNums")
    }
    return res 
}
// 修改购物车某个商品价格
const changeCartPrice = async (data)=>{
    
    let res =  await post("index.php?act=store_cart&op=cartPriceEdit",{
        ...data
    })
    return res 
}

const getCartList =async  (data)=>{
    let list = await post("index.php?act=store_cart&op=cartList",{
        buyer_id:store.state.store_id,
        member_id:store.state.member_id,
        ...data
    })
    
    return list || []
}
const getPreCartCode =async  ()=>{
    let codes = ""
    codes = await post("index.php?act=store_cart&op=push",{
        buyer_id:store.state.store_id,
        member_id:store.state.member_id,
        store_id:store.state.store_id
    })
    
    // return list || []
    return codes
}
 
// 生成订单
const createOrder = async (data)=>{
    
    let cre = await post("index.php?act=store_cart&op=orders_erp",{
        ...data
    })
    return cre
}
// 查询是否支付成功
const getPayRes = async (outTradeNo) =>{
    let res = await post("index.php?act=store_cart&op=mealOk",{
        outTradeNo
    })
    return res
}
export{
    joinCart,
    delCart,
    changeCartNums,
    createOrder,
    getCartList,
    getPayRes,
    changeCartPrice,
    getPreCartCode
}