<template>
<div class="find-index">
      <keep-alive include="findIndex">
        <router-view/>
      </keep-alive>
      
</div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
      
    },
    created(){
      // 首页防直营
			if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
				this.$store.dispatch("getThan")
				this.$router.push(`/store/businesses/${this.$store.state.for_branch}`)
			}else if(this.$store.state.shop&&this.$store.state.shop!=1780){ 
				this.$store.dispatch("getThan")
				this.$router.push(`/store/businesses/${this.$store.state.shop}`)
			}
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.find{
    
}
</style>