import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import hnwTools from "./common/utils/hnwTool"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./styles/base.css"
import api from "./common/api"
import api007 from "./common/api007/index.js"
import apiScd from "./common/apiScd/index.js"
import apiYbj from "./common/apiYbj/index.js" 
import apiGET from "./common/apiGET/index" 
import axios from "axios"
import { Message } from 'element-ui';
import Qs from "qs"
// 注册全局组件
// import Detail from "./components/Detail.vue"

// 倒计时
import CountDwon from "./components/countDown.vue"
// 字段搜索框
import ParaSearch from "./components/ParaSearch.vue"
// 商品详情

import GoodsInfo from "./components/GoodsInfo.vue"
import HnwCopy from "./components/HnwCopy.vue"
// 模态框商品详情
import DiaGoodsInfo from "./components/DiaGoosInfo.vue"
import HnwOssImg from "./components/hnwOssImg.vue"
// 所有配件
import Parts from "./components/Parts.vue"
import PartsXY from "./components/PartsXY.vue"
// vin采购单表单
import EpcPurFrom from "./pages/epcWeb/EpcPurFrom.vue"

// 店铺的品牌中心
import ShopBrand from "./components/ShopBrand.vue"
import ShopBrandXY from "./components/ShopBrandXY.vue"

import VueLuckyCanvas from '@lucky-canvas/vue'
 


// Vue.component("Detail",Detail)
Vue.component("CountDwon",CountDwon)
Vue.component("HnwOssImg",HnwOssImg)
Vue.component("ParaSearch",ParaSearch)
Vue.component("GoodsInfo",GoodsInfo)
Vue.component("DiaGoodsInfo",DiaGoodsInfo)
Vue.component("Parts",Parts)
Vue.component("HnwCopy",HnwCopy)
Vue.component("PartsXY",PartsXY)
Vue.component("EpcPurFrom",EpcPurFrom)
Vue.component("ShopBrand",ShopBrand)
Vue.component("ShopBrandXY",ShopBrandXY)
Vue.config.productionTip = false // 去掉vue警告

Vue.use(ElementUI); 

Vue.use(VueLuckyCanvas)
Vue.prototype.$http = axios.post
Vue.prototype.$https = axios.get
Vue.prototype.$axios = axios 


Vue.prototype.$mainUrl = "https://api.huanuowei.cn/" 

//1.1测试地址
// axios.defaults.baseURL="https://test.huanuowei.cn/api/web/"

// // axios.defaults.baseURL="/api/web/"

// 1.1正式 

axios.defaults.baseURL='https://api.huanuowei.cn/web/'

// axios.defaults.baseURL='/web/'

// axios.defaults.baseURL='https://b.huanuowei.cn/api/web/'

// 下面不用看
// axios.defaults.baseURL='https://api.huanuowei.cn/web/'

// 测试  
// axios.defaults.baseURL='https://jc.hnwmall.com/web/'

// 正式 
// axios.defaults.baseURL='https://api.huanuowei.cn/web/'

// axios.defaults.baseURL='http://101.132.32.209/api/web/'

// 获取本地存储状态

let locaState = localStorage.getItem("hnw-web-state") || "{}"

locaState = JSON.parse(locaState)
// 更新本地state
store.commit("UpDateState",locaState)

// 是否提示错误信息
let tipMsg = true
let timer = null

axios.interceptors.request.use(
  config=>{ 
    if(config.isScd){
    return config
    }else{
      if(config.data){

        if(!config.data.store_id){
          config.data.store_id =  store.state.store_id
        } 
      }
      
      if(store.state){
        config.headers.token = store.state.token
        // 加上设备信息
        config.headers['device-cdde'] = store.state.member_id +':'+store.state.deviceCdde
      }
      
    config.data=Qs.stringify(config.data)
    return config
    }
  },function(error){
    return Promise.reject(error)
  }
)
axios.interceptors.response.use((response)=>{
  if(response.config.isScd){
      // console.log("response",response);
      // 拾车道
      
      let res = {
        data:"",
        respJson:JSON.stringify(response.data),
        responseURL:response.request.responseURL || ''
      }
      if(response.status==200){
        if(response.data.code==200){
          res.data = response.data.content
 
        }else if(response.data.code==300){
          res.data = {
            manufacturer_id:response.data.content,
            isManufacturer_id:true
          }
         
        }else{
          if(response.config.showMsg){
            return Vue.prototype.$warMsg(response.data.message)
          }
        }
      }else{

        if(response.config.showMsg){
          if(response.data.message){
            response.data.message = response.data.message.replace("拾车道","华诺威")
          }{
            
          }
          return Vue.prototype.$warMsg(response.data.message)
        }
      }
      return res

    }else{
      
      if(response.data.code==1){
        if(response.config.url.includes("index.php?act=login&op=login")||response.config.url.includes("index.php?act=login&op=mesLogin")){
          if(response.data.msg.includes('禁用')){

          }else{
            Vue.prototype.$erMsg(response.data.msg)
          }
          // 登录错误
          response.data.loginErr = true
          return response.data
        }else{
          Vue.prototype.$erMsg(response.data.msg)
          
          return false

        }
      }else if(response.data.code==401){
        
        if(tipMsg){
          Vue.prototype.$erMsg(response.data.msg)
          tipMsg = false
        }
  
        localStorage.removeItem("hnw-web-state")
        store.commit("resetState")
        
        router.push("/login")
        return false
      }else if(response.data.code==305){
        location.reload()
      }else{
        return response.data.data
      }
    }
},error=>{
  return Promise.reject(error)
})



//成功的提示
Vue.prototype.$suMsg=function(message){
  this.$message({
  message,
  type:"success",
  duration:800
})
}

//失败的提示

Vue.prototype.$erMsg=function(message){
this.$message({
message,
type:"error",
duration:1300
})
}

//警告的提示
Vue.prototype.$warMsg=function(message){
this.$message({
message,
type:"warning",
duration:800
})
}

//普通的提示
Vue.prototype.$infoMsg=function(message){
this.$message({
message,
type:"info",
duration:800
})
}
Vue.prototype.$affMsg = function(title,su,er){
    this.$confirm(title, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      su()
    }).catch(() => {
      if(er){
        er()
      }
    });
}
// 判断是否是个人方法
Vue.prototype.$isCompany = ()=>{

  if(store.state.member_id&&!store.state.store_id){
      router.push('/Register')
      return false
  }else{
      if(!store.state.member_id||(store.state.member_id.includes("-")&&store.state.look_price==="0")){
        return false
      }else{
        return true
      }
  }
}
const copy = (text)=>{
  if(text){

      var textareaEl = document.createElement('textarea');
      textareaEl.value = text;
      document.body.appendChild(textareaEl);
      textareaEl.select();
      var res = document.execCommand('copy');
      
      document.body.removeChild(textareaEl);
      Message({
        message:"复制成功",
        type:"success",
        duration:800
      })
      return res;
  }
}
Vue.prototype.$copy = copy
// 华诺威针对下面企业的价格体系
const handlePrice = (goods)=>{
  
  if((!store.state.member_id)|| (store.state.member_id.includes("-")&&store.state.look_price==="0")){
    return "会员可见"
  }else if(goods.goods_commend==201){
    return "详询销售"
  }else if(goods.goods_commend==3){
    return goods.taxStoPrice2
  }else if(goods.goods_commend==7||goods.goods_commend==71||goods.goods_commend==8){
    return goods.goods_marketprice || goods.goods_price
  }else if(store.state.store_id==1780){
    return goods.goods_price
  }else{
    return goods.goods_price
  }
  
}

// 处理分仓库存和提示
const storageCangInfo = (data) => { 
  // 上海库存 丹阳库存
  data.sh_storage = data.goods_storage
  data.dy_storage = data.goods_stcids
	data.cangTip = ""

	if(data.store_id!="2197"){ 
		if(store.state.is_sh=="0"){  
			 if(data.goods_stcids==0&&data.goods_storage>0){ 
				// "上海分仓有货"
			 }
			 data.goods_storage = data.goods_stcids
		} 
		if(store.state.is_sh=="1"&&data.goods_storage==0&&data.goods_stcids>0){ 
			 data.cangTip = "丹阳总仓有货"
		} 
	}
}

// 非华诺威价格体系 
const handleStorePrice = (goods,type)=>{
  // 清库存价格
  if(goods.goods_commend==3){
    goods.goods_price = goods.taxStoPrice2 
  }else if(type){
    
    // console.log("自定义价格");
  }else if(store.state.for_branch&&store.state.for_branch!=1780){
    // 如果是直营 
    goods.goods_price = goods.sellPrice1
  }else if(store.state.shop&&store.state.shop!=1780){
    // 如果是自营(店铺)
    // console.log("自营(店铺)价格");
    goods.goods_price = goods.taxWebPrice
  }else if(goods.store_id!=1780){
    // 如果是联盟价格
    // console.log("联盟价格");
    goods.goods_price = goods.taxUntPrice
  }else{
    // 如果有提升比例
    if(store.state.than){
      let rote = hnwTools.plus(1,Number(store.state.than)) 
      goods.goods_price = hnwTools.times(Number(goods.goods_price),rote)
    }
  }
  
}
// 引入api
Vue.prototype.$api = api
Vue.prototype.$api007 = api007
Vue.prototype.$apiScd = apiScd
Vue.prototype.$apiYbj = apiYbj 
Vue.prototype.$apiGET = apiGET 
Vue.prototype.$hnwTools = hnwTools
Vue.prototype.$handlePrice = handlePrice
Vue.prototype.$storageCangInfo = storageCangInfo

Vue.prototype.$handleStorePrice = handleStorePrice

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
