<template>
  <div class="epc-home">
    <template v-if="showVinFill">
        <VinFill @hideFill="showVinFill=false"></VinFill>
    </template>
    <template v-else>
        <div class="vin-home-head" ref="cat-vin-hime-head">
            
            <div class="head-left">
                <img src="../../assets/index/logo.svg" alt="" class="logo" @click="toEpcHome(1)">
                <img src="../../assets/index/汽配供应链专家.png" alt="" @click="toEpcHome" class="log-text">
                <!-- <span class="logo-text-con"><p>华诺威电子目录查询</p></span> -->
                <el-button  v-if="!$route.path.startsWith('/epc/home')" type="primary" size="mini" @click="toEpcHome">返回首页</el-button>
            </div>
            <div class="head-right">
                <span @click="test" :class="{'shanShuo':$store.state.vin_over_time>0&&$store.state.vin_over_time<30}" class="jiacu red head-option" v-if="$store.state.vin_over_time">
                    到期时间:
                    <span class="red">
                        {{$hnwTools.formatDateTime($store.state.cycle_over_time,"ymd")}}
                    </span>
                </span>
                <div class="shu" v-if="$store.state.vin_over_time"></div>
                <span @click="test" class="head-option" >
                    剩余次数
                    <span class="red">
                        {{$store.state.vinNum}}
                    </span>
                </span>
               
                
                <div class="shu"></div>
                <el-menu mode="horizontal" active-text-color="#666" text-color="#666" class="right-menu">
                    <el-submenu index="1">

                        <template slot="title">

                            <span class="my-hnw">{{nickname||$store.state.member_name}} </span>
                        </template>

                        <div v-if="!$store.getters.onlyBrand&&$store.state.sc_rent==1">
                            <el-menu-item index="1-1" @click="$router.push(menu.path)" v-for="(menu,index) in $hnwTools.myMenuList" :key="index">{{menu.title}}</el-menu-item>
                        </div>
                        
                        <el-menu-item index="1-1" @click="loginOut" >退出登录</el-menu-item>
                    </el-submenu>
                </el-menu>
                <!-- 是不是只能使用epc -->
                 <template v-if="!$store.getters.onlyBrand">
                     <div class="shu" ></div> 
                     <span class="btn"  @click="$router.push('/epc/vinPurCart')">
                         采购车
                     </span>
                     <div class="shu" ></div>
                     <span class="btn"  @click="toCart(1)">
                         购物车
                     </span>
                     <template v-if="$store.state.sc_rent==1">
                         <div class="shu"></div>
                         <span  class="head-option cur" @click="$router.push('/shopcar/1/2')" >
                             <el-badge :is-dot="$store.state.advCart>0">
                                 <span>预购单</span>
                             </el-badge>
                         </span>
     
                         <div class="shu" ></div> 
                         <span  class="head-option cur" @click="$router.push('/shopcar/1/1')" >
                             <img src="../../assets/vin/cart .png" alt="">
                             <span>配件清单</span>
                         </span>
     
                         <div class="shu" ></div> 
                         <span @click="toPurHome" class="head-option cur" >
                             <img src="../../assets/shopcar/house.png" alt="">
                             <span>{{ $store.state.for_branch?"返回直营仓":"返回华诺威"}}</span>
                         </span>                
                     </template> 
                   
                 </template>

            </div>
        </div>

        <div class="vin-home-center" v-if="$route.path.startsWith('/epc/home')"> 
        <div class="sfgg" v-if="month==11">
            <img src="../../assets/vin/xufei1.png" alt="">
        </div>
            <div class="cen-content">
                <div class="vin" v-if="queryType=='vin'">
                    <div class="content-top">
                        <img class="back" src="../../assets/vin/vin-back.png">
                        <div class="mode-search">
                            <div class="mode-list">
                                <div  @click="tabQueryType('vin')" class="active">
                                    <img src="../../assets/vin/tit-dialog.png" alt="">
                                    车架号查询
                                </div>
                                <div  @click="tabQueryType('veh')"> 
                                    车型查询
                                </div>
                                <!-- <div @click="tabQueryType('part')">
                                    <img src="../../assets/vin/tit-dialog.png" alt="">
                                    零件号查询
                                </div> -->
                                <!-- <div @click="tabQueryType('old')">
                                    返回旧版
                                </div> -->
                            </div>
                            <div class="search-box"  v-loading="loading" >
                                <!-- <el-autocomplete
                                        class="inline-input"
                                        v-model="vinCode"
                                        :fetch-suggestions="vinQuerySearch"
                                        value-key="record_name"
                                        placeholder="请输入VIN码,或粘贴车架号图片"
                                        :hide-loading="true"
                                        @paste.native="pasteImg"
                                        clearable
                                >
                                    <i slot="suffix" class="el-input__icon el-icon-camera-solid ca007" @click="showUpLoad"></i>
                                </el-autocomplete> -->
                                <!-- @click="tabQueryType('vinPartList')" -->
                                <div class="sear-input-box">
                                    <el-input class="vin-code-input"  
                                        @paste.native="pasteImg" 
                                        v-model="vinCode"
                                        clearable
                                        placeholder="请输入VIN码,或粘贴车架号图片"
                                    ></el-input>
                                    <!-- @click="vinSearch(his.record_name)" -->
                                    <el-select v-model="vinCode" class="vin-his-box" popper-class="home-vin-his-select">
                                        <template v-for="(his,index) in vinHisList">
                                            <el-option  :value="his.record_name"  :key="index" v-if="his.record_name">
                                                <div class="vin-content" :key="index" >
                                                    <div class="vin-text">
                                                        {{his.record_name}}
                                                    </div>
                                                    <div class="vin-time">
                                                        {{$hnwTools.formatDateTime(his.add_time,"mdhm")}}
                                                    </div>
                                                </div>
                                                <div class="vin-info">
                                                    {{his.car_type}}
                                                
                                                </div>
                                            </el-option>
                                        </template>
                                    </el-select>
                                </div>
                                <div class="btn" @click="vinSearch">VIN查询</div>
                                <!-- action="https://sapi.007vin.com/api/ocr/parse" -->
                                <!-- action="action="https://xmall.51qcj.com:1443/api/v2.0/epcweb/other/ocrvin"" https://api.huanuowei.cn/web/index.php?act=data&op=liyang&model=image1780 -->
                                <el-upload
                                    class="upImgVin"
                                    
                                    v-if="orcNum==1"
                                    ref="upImgVin"
                                    drag 
                                    action="https://api.huanuowei.cn/web/index.php?act=data&op=liyang&model=image"
                                    :multiple="false"
                                    name="vinImage"
                                    :data="Vindata"
                                    :show-file-list="false"
                                    list-type="picture"
                                    :http-request="upVinImg"
                                    :limit="1"

                                    >
                                    <div class="test" >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text" >将图片拖拽到此处，或<em>点击上传</em></div>
                                        <!-- <div class="el-upload__text" >将文件拖到此处，或<em>点击上传</em></div> -->
                                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过1.5Mb</div>
                                        <div class="close-btn" @click.stop="orcNum=0">
                                            <i class="el-icon-circle-close up-close"></i>
                                        </div>
                                    </div>
                                </el-upload>
                            </div>

                        </div>
                        
                    </div>
                    <div class="content-end">
                        <div class="brand-title">
                            <div class="dot"></div>
                            <div class="tit">合作品牌</div>
                            <div class="dot"></div>
                        </div>
                        <div class="brand-img-box">
                            <div class="img-box">
                                <div class="brand-div" @click="comSearchBrand(brand.name)" v-for="(brand,index) in cooPeratives" :key="index" :style="brand.style">
                                    <!-- {{brand.name}} -->
                                </div>
                                <img src="../../assets/vin/brand-list.png" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="veh" v-if="queryType=='veh'">
                    <div class="content-top">
                        <img class="back" src="../../assets/vin/veh-back.png">
                        <div class="mode-search">
                            <div class="mode-list">
                                <div  @click="tabQueryType('vin')">
                                    
                                    车架号查询
                                </div>
                                <div class="active">
                                    <img src="../../assets/vin/tit-dialog.png" alt="">
                                    车型查询
                                </div>
                                <!-- <div @click="tabQueryType('part')">
                                    <img src="../../assets/vin/tit-dialog.png" alt="">
                                    零件号查询
                                </div> -->
                                <!-- <div @click="tabQueryType('old')">
                                
                                    返回旧版
                                </div> -->
                            </div>
                            <div class="search-box">

                            </div>
                        </div>
                    </div>
                    <!-- 改为007接口 -->
                    <div class="content-end">
                        <div class="veh-item">
                            <div class="brand-numerical">
                                <div class="brand-label">
                                    常用品牌
                                </div>
                            </div>
                            <div class="brand-box" >
                                <div class="brand-logo-list" >
                                    
                                    <div class="brand-logo-item" v-for="(brand,index) in often" :key="index" @click="toVeh(brand)"  id="m10" >
                                        <!-- 007 -->
                                    <!-- <div class="brand-logo-item" v-for="(brand,index) in oftens" :key="index" @click="toVeh(brand)"  id="m10" > -->
                                        <div class="img-box">
                                            <img :src="brand.manufacturer_logo" alt="">
                                            <!-- 007 -->
                                            <!-- <img :src="brand.img" alt=""> -->
                                        </div>
                                        <div class="logo-text">
                                            {{brand.manufacturer_name}}
                                            <!-- 007 -->
                                            <!-- {{brand.name}} -->
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="veh-item">
                            <div class="brand-numerical">
                                <div class="brand-label">
                                    选择品牌
                                </div>

                                <!-- <div class="numerical-list">
                                    <div class="numerical-item all-numer" :class="{'active':acronymActive=='全部'}" @click="acronymActive='全部'">
                                        
                                        全部
                                    </div>
                                    <div class="numerical-item" v-for="(acronym,index) in acronymKeys" :key="index" :class="{'active':acronymActive==acronym}" @click="acronymActive=acronym">
                                        {{acronym}}
                                    </div>
                                    
                                </div> -->
                            </div>
                            
                            <div class="brand-box">
                                
                                <div class="brand-logo-list" :style="{'max-height':maxH+'px'}">

                                    <div class="brand-logo-item" v-for="(brand,index) in vehBrandList" :key="index" @click="toVeh(brand)"  id="m10" >
                                        <!-- 修改为007接口 -->
                                    <!-- <div class="brand-logo-item" v-for="(brand,index) in showBradnList" :key="index" @click="toVeh(brand)"  id="m10" > -->
                                        <div class="img-box">
                                            <img :src="brand.manufacturer_logo" alt="">
                                            <!-- 007的 -->
                                            <!-- <img :src="brand.img" alt=""> -->
                                        </div>
                                        <div class="logo-text">
                                            {{brand.manufacturer_name}}
                                            <!-- 007的 -->
                                            <!-- {{brand.name}} -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="oecode" v-if="queryType=='part'">
                    <div class="content-top">
                        <img class="back" src="../../assets/vin/oe-back.png">
                        <div class="mode-search">
                            <div class="mode-list">
                                <div  @click="tabQueryType('vin')">
                                    
                                    车架号查询
                                </div>
                                <div  @click="tabQueryType('veh')">
                                    
                                    车型查询
                                </div>
                                <!-- 007 -->
                                <!-- <div class="active">
                                    <img src="../../assets/vin/tit-dialog.png" alt="">
                                    零件号查询
                                </div> -->
                            </div>
                            <div class="search-box"  v-loading="loading">
                                <!-- <el-autocomplete
                                        class="inline-input"
                                    
                                        placeholder="请输入零件号查询"
                                        v-model="oeCode"
                                        :fetch-suggestions="oeQuerySearch"
                                        value-key="record_name"
                                        clearable
                                >
                                </el-autocomplete> -->
                                <el-autocomplete
                                        class="inline-input"
                                    
                                        placeholder="请输入零件号查询"
                                        v-model="oeCode"
                                        :fetch-suggestions="oeQuerySearch"
                                        value-key="record_name"
                                        clearable
                                >
                                <el-select v-model="manufacturerId" slot="prepend" placeholder="请选择">
                                    <el-option :label="brand.manufacturer_name" :value="brand.manufacturer_id" v-for="(brand,index) in vehBrandList" :key="index"></el-option>
                                </el-select>
                                </el-autocomplete>
                                <div @click="toOecode" class="btn" >oe查询</div>
                            </div>
                        </div>
                    </div>
                    <div class="content-end">
                        <div class="hot-title">
                            热销零件
                        </div>
                        <div class="hot-list">
                            <div class="part-item" v-for="(goods,index) in recomGoods" :key="index" @click="showGoodsInfo(goods)">
                                <!-- https://api.huanuowei.cn/data/upload/shop/store/goods/1780/1780_06746749602409296.jpg -->
                                <img :src="goods.goods_image"  alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="explain">
                <span>免责说明</span>
            </div>
        </div>
        <template v-else>

            <!-- <div class="his-row">
                <div class="re-home" @click="rebackHome">
                    <img src="../../assets/vin/reback.png" alt="">
                    返回主页
                </div>
                <div class="route-list">
                    <span class="rote-item" v-for="(rote,index) in epcNavList" :key="index">
                        <template v-if="rote.label">
                            <span @click="toNav(rote)">{{rote.label}}</span>
                            <i class="el-icon-arrow-right"></i>
                        </template>
                    </span>

                </div>
            </div> -->
           
            <div class="vin-home-center" >

                <keep-alive include="vehicle,vinTree">
                    
                    <router-view :brandList="vehBrandList"/>
                    <!-- 007 -->
                    <!-- <router-view :brandCode="brandCode" :brandList="vehBrandList"/> -->
                </keep-alive>
            </div>
        </template>

    </template>
    
     <!-- 历史记录重写 数据存在这，不放vuex了 -->
        <!-- 历史记录行 -->
        

        <!-- include - 字符串或正则表达式。只有名称匹配的组件会被缓存。
exclude - 字符串或正则表达式。任何名称匹配的组件都不会被缓存。 -->
<!-- max - 数字。最多可以缓存多少组件实例。 -->
        <!-- 使用缓存的组件会出现后退但上面历史记录不变的问题 -->
    
    <div class="epc-home-footer">
        <div>
            版权所有©2020华诺威汽车零部件（上海）有限公司 
        </div>
        <div>
            <a href="https://beian.miit.gov.cn" target="_blank" >沪ICP备16048698号-3</a>
        </div>
    </div>
    <el-dialog
        :visible.sync="explVis"
        class="expl-info-dis"
        width="860px"
        top="9vh"
        >
        
        <div class="img-box">
            
            <img src="../../assets/vin/notRole.png" alt="">
        </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex" 
import VinFill from "./VinFill"

export default {
    data() {
        return {
            Vindata:{
                member_id:this.$store.state.member_id,
                shop_id:this.$store.state.shop,
                member_name:this.$store.state.member_name,
                store_name:this.$store.state.store_name
            },
            nickname:"",

            // LSVUD60T9J2243862
            vinCode:"",
            oeCode:"",
            // 06A90643
            partsName:"",
            partsCode:"",
            brandCenList:["卢卡斯","博世","斯泰必鲁斯","索菲玛","德尔福"],
           
            // upImgData:{
            //    url:"/api/ocr/parse"
            // },
            
            showVinimg:false,
            orcNum:0,
            orcVin:"",
            brandCode:"",
            mcid:"",
            listFilter:true,
            acronymKeys:[],
            manufacturerId:"",
            queryType:"vin",
            acronymActive:"全部",
            handleR:0,
            explVis:false,
            epcPart:[],
            screenH:"54px",
            canvaSty:{
                w:350,
                h:500,
                "transform":`scale(0.2)`,
                left:"0",
                top:"0"
            },
            partImgSty:{
                // width:"580px",
                width:"17rem",
                height:"358px"
            },
            imgSrc:"",
            canvaScale:0.2,
            dot:'',
            isMove:false,
            // 鼠标对象
            cansBox:{
            },
            startPos:{},
            partList:[],
            vehBrandList:[],
            activePnum:0,
            carMsg:{"brand":"","VehicleSys":"","year":"","displacement":"","transmission":""},
            // 品牌图片
            brandImg:"https://cdns.007vin.com/img/vwag.png",
            // 某些页面的额外参数
            params:{},
            // 是否显示充值页
            showVinFill:false,
            loading:false,
            maxH:0,
            ofList:[],
            // 推荐商品列表
            recomGoods:[],
            // 007
            bradnList:[],
            cooPeratives:[
                {
                    name:'上海来利',
                    style:{
                        left: '250px',
                        top: '2px',
                    }
                },
                {
                    name:'上海法雷奥',
                    style:{
                        left: '402px',
                        top: '2px',
                    }
                },
                {
                    name:'三爱海陵',
                    style:{
                        left: '560px',
                        top: '2px',
                    }
                },
                {
                    name:'永信',
                    style:{
                        left: '712px',
                        top: '2px',
                    }
                },
                {
                    name:'昌辉',
                    style:{
                        left: '172px',
                        top: '42px',
                    }
                },
                {
                    name:'凯吉',
                    style:{
                        left: '328px',
                        top: '42px',
                    }
                },
                {
                    name:'法雷奥',
                    style:{
                        left: '480px',
                        top: '42px',
                    }
                },
                {
                    name:'NTK',
                    style:{
                        left: '634px',
                        top: '42px',
                    }
                },

                {
                    name:'博世',
                    style:{
                        left: '794px',
                        top: '42px',
                    }
                },

                {
                    name:'斯泰必鲁斯',
                    style:{
                        left: '96px',
                        top: '82px',
                    }
                },
                
                {
                    name:'采埃孚',
                    style:{
                        left: '248px',
                        top: '82px',
                    }
                },
                
                {
                    name:'汉高',
                    style:{
                        left: '402px',
                        top: '82px',
                    }
                },
                
                {
                    name:'卢卡斯',
                    style:{
                        left: '558px',
                        top: '82px',
                    }
                },
                
                {
                    name:'信力',
                    style:{
                        left: '714px',
                        top: '82px',
                    }
                },
                
                {
                    name:'海拉',
                    style:{
                        left: '870px',
                        top: '82px',
                    }
                },
                {
                    name:'爱尔铃',
                    style:{
                        left: '24px',
                        top: '120px',
                    }
                },
                {
                    name:'汉格斯特',
                    style:{
                        left: '172px',
                        top: '120px',
                    }
                },
                
                {
                    name:'捷豹',
                    style:{
                        left: '326px',
                        top: '120px',
                    }
                },
                
                {
                    name:'华诺威',
                    style:{
                        left: '480px',
                        top: '120px',
                    }
                },
                
                {
                    name:'索恩格',
                    style:{
                        left: '636px',
                        top: '120px',
                    }
                },
                
                {
                    name:'崇芳',
                    style:{
                        left: '792px',
                        top: '120px',
                    }
                },
                
                {
                    name:'一汽富奥',
                    style:{
                        left: '944px',
                        top: '120px',
                    }
                },
                
                {
                    name:'德尔福',
                    style:{
                        left: '100px',
                        top: '160px',
                    }
                },
                {
                    name:'天合',
                    style:{
                        left: '248px',
                        top: '160px',
                    }
                },
                
                {
                    name:'上制',
                    style:{
                        left: '402px',
                        top: '160px',
                    }
                },
                {
                    name:'上汽大众',
                    style:{
                        left: '558px',
                        top: '160px',
                    }
                },
                
                {
                    name:'上海兴盛',
                    style:{
                        left: '710px',
                        top: '160px',
                    }
                },
                
                {
                    name:'小糸',
                    style:{
                        left: '870px',
                        top: '160px',
                    }
                },
                
                {
                    name:'舍弗勒',
                    style:{
                        left: '168px',
                        top: '200px',
                    }
                },
                {
                    name:'欧博萨',
                    style:{
                        left: '324px',
                        top: '200px',
                    }
                },
                
                {
                    name:'安庆帝伯格茨',
                    style:{
                        left: '480px',
                        top: '200px',
                    }
                },
                {
                    name:'天博',
                    style:{
                        left: '638px',
                        top: '200px',
                    }
                },
                
                {
                    name:'车享配',
                    style:{
                        left: '790px',
                        top: '200px',
                    }
                },
                
                {
                    name:'厦门理研',
                    style:{
                        left: '250px',
                        top: '238px',
                    }
                },
                {
                    name:'SABS',
                    style:{
                        left: '408px',
                        top: '238px',
                    }
                },
                {
                    name:'索菲玛',
                    style:{
                        left: '560px',
                        top: '238px',
                    }
                },
                {
                    name:'萨克斯',
                    style:{
                        left: '712px',
                        top: '238px',
                    }
                },
                
            ],
            vinHisList:[],
            month:0
        }
    },
    methods: {
        // 去购物车
        toCart(type){
				if(!this.$isCompany()){
					return 
				}
				this.$router.push("/shopcar/1/"+type)
			},
         // vin
        toVinFill(){
            // 跳转充值页
            // console.log("跳转充值页");

            // if(this.$store.state.canVinSearch==0){
            //     return this.explVis =  true
            // }
            
           this.showVinFill = true
        },
        toEpcHome(reload){
            // this.$router.push('/epc/home/vin')
            this.$router.push('/')
            // if(reload==1){

            //     location.reload()
            // }
        },
        showCz(){
            let arr = ['1989',"2019",'1907']
            let ids =  arr.find(id=>id==this.$store.state.store_id)
            return ids
        },
        comSearchBrand(brand){
            if(this.$store.getters.notLimitBrands){
                let link = location.href.split("#")[0]
                
                localStorage.setItem("epc-brand-text",brand)
                window.open(link + '#/brand')
            }else{
                
                // this.$warMsg("")
            }

        },
        // 弹出框数据
        showGoodsInfo(goods){

            goods.preRou = "找件"
            localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
          
            let link = location.href.split("#")[0]
            window.open(link + '#/find/info')
        },
        changeData(obj){
            for(let key in obj){
                this[key] = obj[key]
                
            }
        },
        
        async vinSearch(){

            // if(this.$store.state.canVinSearch==0){
            //     return this.explVis =  true
            // }
            
            if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1&&this.$store.state.store_id!=1989){
                this.$warMsg("您的次数已用完")
                return false
            }

            this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()

            if(this.vinCode.length!=17){
                
                return this.$warMsg("车架号长度必须17位，请检查您的车架号")
            }

            this.getVinHisList()
            return this.$router.push('/epc/tree/'+this.vinCode+'/1')
        },
        // 退出登录deng
        loginOut(){

            
            this.$router.push("/login")
            // if(!this.$store.getters.notLvD||this.$store.state.sc_rent!=1){

            // }else{
            //     this.$router.push("/")
            // }
            localStorage.removeItem("hnw-web-state")
            this.$store.commit("resetState")
        },
        // async partSearch(partsName){
        //     if(!this.mcid){
        //         return this.$warMsg("没有车辆数据")
        //     }
        //     const res = await this.$api007.vinSearchByName({
        //         brandCode:this.brandCode,
        //         vin:this.vinCode,
        //         search_key:partsName||this.partsName,
        //         mcid:this.mcid
        //     })
        //     console.log("partsName",partsName||this.partsName)
        //     this.partList = res.data
            
        //     // 需要获取标签得列表
        //     if(Array.isArray(this.partList)){
        //         let pid_list = this.partList.map(part=>part.pid)
        //         let priceRes = {}
                
        //         if(pid_list.length>0){
        //             // 批量获取参考价格
        //             priceRes = await this.$api007.VinTagsQuery({
        //                 brandCode:this.brandCode,
        //                 pid_list
        //             })
        //         }
                
        //         this.partList.forEach(part=>{
        //             part.sale_price = priceRes.data[part.pid].sale_price
        //             this.$set(part,"showEpc",false)
        //             part.remark = part.remark.replace(/<br\/>/g,"")
        //             part.label = part.label.replace(/<br\/>/g,"")
        //         })
        //         if(this.partList[0]){
        //             this.epcSearch(this.partList[0])
        //         }
        //     }else{
        //         this.$warMsg("未找到对应商品")
        //     }
        // },
        // 过滤零件列表
        filterPartList(){

        },
        upVinImg(e,e1){
            // console.log("触发上传",);
            this.fileReaderupdateImg(e.file)
        },
        canvaScaleChange(canvaScale){
            this.canvaScale = canvaScale
        },
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "17rem"
            }else{
                this.partImgSty.width = "21rem"
            }
        },
        acDotChange(pnum){
            this.activePnum = pnum
        },
        rowClick(dot){
            this.dot = dot
        },
        rebackHome(){
            if(this.$route.path.startsWith('/epc/vinGoods')){
                // this.$router.replace(`/catalog/tree/${this.vinCode}/1`)
                this.$router.go(-1)
            }else{
                this.$router.push('/epc/home'+'/'+this.queryType)
            }
        },
        changeShowEpc(part,partIndex){
            
            this.partList.forEach((item,index)=>{
                if(index==partIndex){

                    part.showEpc = !part.showEpc

                    if(part.showEpc){

                        this.epcSearch(part)
                        
                    }else{
                        part.showText = "展开配件列表"

                    }

                }else{

                    item.showEpc = false
                    part.showText = "展开配件列表"
                }
            })
            
        },
        
        // epc查询
        async epcSearch(part){
        
            
            part.showText = "收起配件列表"
            
            // 如果没有查询过
            if(!part.hasEpcArre){

                let res = await this.$api007.vinPartSearch({
                    vin:this.vinCode,
                    brandCode:this.brandCode,
                    mcid:this.mcid,
                    num:part.num,
                    mid:part.mid,
                    subgroup:part.subgroup
                })
                part.hasEpcArre = true
                // 处理相邻配件 有的是数组

                let nearList = []
                
                let hotspots = res.img.imgPoints.hotspots
                // imagePath
                
                let imgObj = res.img
                if(res.data.part_detail){
                    
                    res.data.part_detail.forEach(parts=>{
                        // colorvalue 过滤掉无效字段
                    
                        if(Array.isArray(parts)){
                            parts.forEach(part=>{
                                nearList.push(part)
                               
                            })
                        }else{
                            nearList.push(parts)
                           
                        }
                    })
                    
                    nearList = nearList.sort((part1,part2)=>Number(part1.itid)-Number(part2.itid))

                    nearList.forEach(part=>{
                        
                        // 处理点
                        let hots = hotspots.find(hot=>hot.hsKey==part.pnum)
                        if(hots){
                            part.hotspots = hots
                        }else{
                            part.hotspots ={}
                        }
                    })
                   
                    this.$set(part,"nearList",nearList)
                    this.$set(part,"imgObj",imgObj)
                }else{
                    console.error("未查询到相邻配件")
                }
            }
            this.epcPart = part.nearList
            
            // this.imgSrc = part.url
            this.imgSrc = part.imgObj.imagePath
            
            part.showEpc = true
        },
        async querySearch(str,cb){
            
            // if(!this.searText){
            //     let res =  await this.$http("index.php?act=store_system&op=record_list",{
            //         store_id:this.$store.state.store_id,
            //         record_type:4
            //     })
            //     cb(res)
            // }else{
            //     cb([])
            // }
            cb([])
        },
        async vinQuerySearch(str,cb){
            if(!this.vinCode){
                let res =  await this.$api.getSearchRecord({
                    store_id:this.$store.state.store_id,
                    record_type:3,
                    record_from:1,
                    page:30
                })
               
                cb(res)
            }else{
                cb([])
            }
        },
        async getVinHisList(){
            let res =  await this.$api.getSearchRecord({
                store_id:this.$store.state.store_id,
                record_type:3,
                record_from:1,
                page:30
            })
            if(res&&res.length>0){
                this.vinHisList = res
            }else{
                this.vinHisList = []
            }
          
        },
        async oeQuerySearch(str,cb){

            if(!this.oeCode){
                let res =  await this.$api.getSearchRecord({
                    store_id:this.$store.state.store_id,
                    record_type:3,
                    record_from:3
                })
                res.forEach(item=>{
                    if(item.record_name.includes("|&|")){
                        let arr = item.record_name.split("|&|")
                        item.record_name = arr[0]
                        item.manufacturer_id = arr[1]
                    }
                    
                })
                cb(res)
            }else{
                cb([])
            }
        },
        oeSearch(){

        },
        mokeData(){
            this.queryType = "vinPartList"
            this.$nextTick(()=>{
                // this.queryType = "vinPartList"
                this.partList = this.$hnwTools.sevenData
                this.imgSrc = this.partList[0].imgObj.imagePath
                this.epcPart = this.partList[0].nearList
                // this.imgSrc = "https://structimgs.007vin.com/thumbnail/1000014/3276ca701aaabf8509dc781eb992f291.png"
                // console.log() poq 
                // this.isEpc = true
                // this.vinOver = true
                // 给某个元素添加 @click="drawCanvas"
                // 
            })
            // this.brandCode
        },
        async tabQueryType(type){ 
            if(type=="old"){
                let link = location.href.split("#")[0]
                window.open(link + '#/catalog/home/vin')
                return 
            }
            // if(this.$store.state.canVinSearch==0){
            //     return this.explVis =  true
            // }
            this.queryType =  type
            // 007接口的数据
            // if(this.queryType=='veh'){
            //     if(this.acronymKeys.length==0){
            //         let res = await this.$api007.getAllBrand()
                    
            //         this.bradnList = res.data
            //         this.acronymKeys = res.acronym_keys
            //         console.log("this.bradnList",this.bradnList)
            //         // this.bradnList.forEach(brand=>{
            //         //     brand.img = res.IMG_DNS + brand.img
            //         // })
                   
                    
            //     } 

            // }
            
            

            

        },
        showUpLoad(){
            if(this.orcNum!=0){
                this.orcNum = 0
            }else{
                this.orcNum = 1
            }
        },
        async upImg(baseData){
            this.loading = true
            // let arr = baseData = baseData.split(",")
            // let orcRes = await this.$apiScd.ocrvin({
            //     vinImage:arr[1]
            // }) 
            let orcRes = await this.$http(`index.php?act=data&op=liyang&model=image`,{  
                data:baseData,
                member_id:this.$store.state.member_id,
                shop_id:this.$store.state.shop,
                member_name:this.$store.state.member_name,
                store_name:this.$store.state.store_name
            });
            if(orcRes.Result){
                this.$suMsg("解析成功")
                this.vinCode = orcRes.Result
            }
            this.loading = false
        },
        compress (img) {
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            let width = img.width
            let height = img.height
            canvas.width = width
            canvas.height = height
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(img, 0, 0, width, height)
            let ndata = canvas.toDataURL('image/jpeg', 0.5)
            return ndata
        },
        dataURItoBlob (base64Data) {
            var byteString
            if (base64Data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(base64Data.split(',')[1])
            } else {
            byteString = unescape(base64Data.split(',')[1])
            }
            var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
            var ia = new Uint8Array(byteString.length)
            for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], { type: mimeString })
        },
        fileReaderupdateImg(file){
            this.loading = true
            let reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = (e)=>{
                let img = new Image()
                // console.log(e.target.result)
                img.src = e.target.result
                img.onload =async ()=>{
                    
                    // console.log("img",img)
                    let data = this.compress(img)
                    this.upImg(data)
                    
                    return 
                    let blob = this.dataURItoBlob(data)
                    // console.log("data",data)
                    // console.log("blob",blob)
                          
                   
                }
            }
        },

        pasteImg(event){ 
            let clipboardData = event.clipboardData
            if(!(clipboardData&&clipboardData.items)){
                return 
            }
            for(let i= 0,len = clipboardData.items.length;i<len;i++){
                let item = clipboardData.items[i];
                if(item.type.indexOf('image')!==-1){
                    let f = item.getAsFile()

                    this.fileReaderupdateImg(f)
                }
            }
        },
        
        imgVinSuc(res){
            if(res.data.vin){
               
                this.vinCode = res.data.vin
               
            }else{
                this.$warMsg(res.info)
                this.resetImg()
            }
        },
        // 重置图片
        resetImg(){
            this.orcNum = 1 
            this.$refs.upImgVin.clearFiles()
            this.orcVin = ""
        },
        commitOrcSearch(){
            this.vinCode = this.orcVin

        },
        imgVinErr(err){
            // console.log("图片上传失败",err)
        },
        // 去品牌中心
        toBrandCen(brand){

            let link = location.href.split("#")[0]
            window.open(link + '#/index/brand/'+brand)
        },
        toNav(rote){

            this.$router.push(rote.route)
            if(!rote.level){
                
                let index = this.$store.state.epcNavList.findIndex(item=>item.route==rote.route)

               
                let list = this.$store.state.epcNavList.slice(0,index+1)

                this.params =  rote.params
                
                this.$store.commit("UpDateState",{
                    epcNavList:list
                })

            }

        },
        toHnwPur(){
            if(!this.token){
                
                this.$warMsg("未登录账号")
                return this.$router.push("/login")
                // window.open("http://www.huanuowei.cn/#/login/self")
            }else if(this.register_from==2){

                return this.$warMsg("您的账号不能登录采购品牌")
                // window.open("http://www.huanuowei.cn/#/login/self")
            }else{
                let acc = this.account_number + "|" + this.password
                let mixText =  this.$hnwTools.mixStr(acc,1)
                window.open("http://localhost:8081/#/login/"+mixText)
            }
        },

        toVeh(brand){
            
            if(this.$store.getters.onlyBrand&&this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1){
                this.$warMsg("您的次数已用完")
                return false
            }
            this.$router.push('/epc/tree/veh/'+brand.manufacturer_id)
            
            

            // 007的
            // this.brandCode = brand.brandcode
            
            // if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1){
            //     this.$warMsg("您的次数已用完")
            //     return false
            // }
            // this.$router.push('/catalog/vehicle/'+this.brandCode+'/1')
            // this.brandImg = brand.img
            
            // this.$store.commit("UpDateState",{
            //     epcNavList:[{
            //         label:brand.name,
            //         route:`/catalog/vehicle/${this.brandCode}/1`
            //     }]
            // })

        },
        
        toTree(){
            this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()
            this.$router.push('/epc/tree/'+this.vinCode+'/1')
           
        },
        toPurHome(){
            document.title = '华诺威_汽配供应链专家';
            this.$router.push("/")
        },
        test(){
        },
        toHome(){
            this.$store.commit("UpDateState",{
                epcNavList:[]
            })
            this.$router.push('/epc/home'+"/"+this.queryType)
        },
        toOecode(){
            
            if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1){
                this.$warMsg("您的次数已用完")
                return false
            }
            if(!this.manufacturerId){
                return this.$warMsg("请选择品牌")
            }
            this.oeCode = this.oeCode.replace(/\s/g,'').toUpperCase()
            if(this.oeCode.length<5){
                return this.$warMsg("零件号必须大于5位")
            }
            this.$router.push('/epc/oesearch/'+this.oeCode+'/'+this.manufacturerId)

            // 007
            // if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1){
            //     this.$warMsg("您的次数已用完")
            //     return false
            // }
            // this.oeCode = this.oeCode.replace(/\s/g,'').toUpperCase()
            // if(this.oeCode.length<5){
            //     return this.$warMsg("零件号必须大于5位")
            // }
            // this.$router.push('/catalog/oesearch/'+this.oeCode+'/1')
        }
    },
    async created(){
        // 版本升级
        this.month =  new Date().getMonth()
        this.$api.getAnalysisLog("login")
        let verRes = await this.$api.handleVer({
         model: 2,
        });
        let vers = localStorage.getItem("hnw-web-vers");
        if(vers!="undefined"){
        vers = JSON.parse(vers);

        if (vers != verRes) {
            localStorage.setItem("hnw-web-vers", JSON.stringify(verRes));
            location.reload(true);
        }
        }

        if(this.$store.state.shop==2018){

            this.loginOut()
        }
        
        this.ofList = await this.$api.getOftenList()
        
        document.title = '华诺威_电子目录';
        // 获取品牌列表
        
        if(this.token){
            this.$nextTick(async ()=>{
                let res = await this.$api.getAccMsg({
                    member_id:this.member_id,
                    type:this.$store.state.role_name=='all'?1:2
                })
                this.nickname =  res.nickname
            }) 
            this.$store.dispatch("getAdvCart")
            this.$store.dispatch("getVinNum")
        }else{
            // this.$router.push('/login')
        }
        // 获取拾车道品牌列表
        let brandRes = await this.$apiScd.getAllBrand() 
        this.vehBrandList = brandRes 
        // 获取热门推荐商品
        this.recomGoods = await this.$api.getRecomGoods() 
        this.getVinHisList()
    },
    watch:{
        
    },
    mounted(){
        
    },
    components:{
        VinFill
    },
    computed:{
        ...mapState(["company_name_jc","store_id","cartNums","token","account_number","password","member_name","register_from","role_name","member_id","epcNavList"]),
         //    007
    //    showBradnList(){
    //         if(this.acronymActive=='全部'){ 
    //             let list = this.bradnList.map(item=>item)
    //             // 放前面几个 数组是倒序的
    //             let tq = ['西雅特','斯柯达','奥迪',"大众"]

                
    //             tq.forEach(item=>{
                   
    //                 let i = list.findIndex(item1=>item1.name==item)

    //                 if(i!=-1){
    //                     list.unshift(list.splice(i,1)[0])
    //                 }
                    
    //             })
		
    //             return list
    //         }else{
    //             let list = []

    //             list = this.bradnList.filter(brand=>{
    //                 if(brand.acronym == this.acronymActive||brand.area_name == this.acronymActive){
    //                     return brand
    //                 }        
    //             })

    //             let tq = ['西雅特','斯柯达','奥迪',"大众"]
    //             tq.forEach(item=>{
                   
    //                 let i = list.findIndex(item1=>item1.name==item)

    //                 if(i!=-1){
    //                     list.unshift(list.splice(i,1)[0])
    //                 }
                    
    //             })

    //             return list
    //         }
    //     },
        often(){
            let list = [] 
            let names = this.ofList.map(item=>item.automobile)
            
            names.forEach(name=>{
                let temp =this.vehBrandList.find(brand=>brand.manufacturer_name==name)
                if(temp){
                    list.push(temp)
                }
            })  
            console.log("list",list)
            return list
        },
        // 007
        // oftens(){
        //     let list = []
            
        //     let names = this.ofList.map(item=>item.automobile)
        //     names.forEach(name=>{
        //         let temp =this.bradnList.find(brand=>brand.name==name)
        //         list.push(temp)
        //     })
        //     return list
        // }
    }
}
</script>

<style lang="scss">
@font-face {
  font-family: STFWXQ;
  src: url(../../styles/书体坊王学勤钢笔行书.ttf);
}
    .home-vin-his-select{
        .el-select-dropdown__item{
            padding: 5px;
            line-height: 1;
            height: 40px;
        .vin-content{
                display: flex;
                justify-content: space-between;
                .vin-text{
                    color: #409EFF;
                    font-weight: 700;
                    font-size: 15px;
                    color: #333;
                }
                .vin-time{
                    font-weight: 400 !important;
                }
            }
            .vin-info{
                padding: 3px;
                padding-left: 3px;
                font-size: 14px;
                // width: 270px;
                width: 30rem /* 990/33 */;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #999;
            }
        }
        .el-select-dropdown__item.selected{
            div{
                color: #409EFF;
            }
            .vin-text{
                color: #409EFF;
            }
        }
    }
    .epc-home{
        display: flex;
        flex-direction: column;
        height: 100%;
        .vin-home-head{
            display: flex;
            // height: 1.818182rem /* 60/33 */;
            height: 40px;
            overflow: hidden;
            align-items: center;
            flex-shrink: 0;
            .head-left{
                padding-left: .606061rem /* 20/33 */;
                width: 50%;
                // line-height: 1.818182rem /* 60/33 */;
                display: flex;
                align-items: center;
                
                >img{
                    padding-left: .181818rem /* 6/33 */;
                    vertical-align: middle;
                    cursor: pointer;
                }
                .logo{
                    width: 80px /* 64/33 */ !important;
                    height: auto;
                }
                // .logo-text{
                //     width: 6.969697rem /* 230/33 */;
                //     cursor: pointer;
                // }
                .logo-text-con{
                    font-family: STFWXQ;
                    font-size: 34px;
                    color: rgb(49,77,140);
                    -webkit-text-stroke: 0.2px rgb(49,77,140);
                    margin-left: -7px;
                    p{
                        letter-spacing: -15px;
                    }
                }
                .el-button{
                    margin-left: 20px;
                }
            }
            .head-right{ 
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: flex-end;
                padding-right: 1.212121rem /* 40/33 */;
                .shu{
                    height: 14px;
                    width: 1px;
                    margin: 0 10px;
                    background: #999999;
                }
                .btn{
                    cursor: pointer;
                    // margin-right: 10px;
                }
                .right-menu{
                    background: transparent;
                    border: none;
                    .el-submenu{
                        .my-hnw{
                            height: auto;
                            line-height: 1;
                        }
                        .el-submenu__title{
                            padding: 0;
                            font-size: 14px;
                            line-height: 1.818182rem /* 60/33 */;
                            height: 1.818182rem /* 60/33 */;
                            display: flex;
                            align-items: center;
                            transform: translateY(1px);
                            i{
                                margin-left: 5px;
                                margin-top: 0;
                            }
                        }
                    }
                    .is-active{
                        .el-submenu__title{
                            border-bottom: none;
                            padding: 0;
                            font-size: 12px;
                        }
                    }
                    .el-menu-item{
                        font-size: 14px;
                    }
                }
                
                .head-option{
                    display: flex;
                    align-items: center;
                    .red{
                        color: #FF1919;
                    }
                    span{
                        margin-left: 5px;
                    }
                }
                @keyframes smallToBig1 {
                    0%{ 
                        transform: scale(.8) translateZ(0);
                    }
                    100%{
                        transform: scale(1.1) translateZ(0) ;
                    }
                }
                .jiacu{
                    font-size: 16px;
                    font-weight: 700;
                }
                .shanShuo{
                    animation: smallToBig1 0.8s infinite linear alternate;
                }
                .red{
                    color: #FF1919;
                }
                .cur{
                    cursor: pointer;
                }
            }
        }
        .vin-home-center{
            flex:1 ;
            display: flex;
            flex-direction: column;
            position: relative;
            .sfgg{
                position: absolute;
                right: 40px;
                top: 10px;
                z-index: 999;
                img{
                    width: 10.606061rem /* 251/33 */;
                }
            }
            .cen-content{
                flex: 1;
                // 中心头部公共样式
                .content-top{
                    position: relative;
                    height: 11.212121rem /* 370/33 */;
                    .back{
                        position:absolute;
                        width: 100%;
                        height: 100%;
                    }
                    .mode-search{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 3.030303rem /* 100/33 */;
                        .mode-list{
                            display: flex;
                            padding-left: .151515rem /* 5/33 */;
                            >div{
                                width: 3.878788rem /* 128/33 */;
                                height: 1.515152rem /* 50/33 */;
                                text-align: center;
                                line-height: 1.393939rem /* 46/33 */;
                                font-size: .484848rem /* 16/33 */;
                                color: #fff;
                                cursor: pointer;
                            }
                            .active{
                                position: relative;
                                color: $vin-blue;
                                
                                
                                img{
                                    width: 100%;
                                    text-align: center;
                                    position: absolute;
                                    z-index: -1;
                                    left: 0;
                                }

                            }
                        }
                        .search-box{
                            // padding-top: .30303rem /* 10/33 */;
                            margin-top: .30303rem /* 10/33 */;
                            width: 30rem /* 990/33 */;
                            display: flex;
                            border: .121212rem /* 4/33 */ solid #4D80CE;
                            border-radius: .363636rem /* 12/33 */;
                            position: relative;
                            .el-autocomplete{
                                flex: 1;
                                height: 2.121212rem /* 70/33 */;
                                font-size: .545455rem /* 18/33 */;
                                .el-input{
                                    height: 100%;
                                    .el-input-group__prepend{
                                        width: 15%;
                                        // padding: .30303rem /* 10/33 */;
                                        .el-select{
                                            height: 100%;
                                        }
                                        .el-input__icon{
                                            line-height: 1;
                                        }
                                        .el-select{
                                            input{
                                                padding: 0 10px;
                                            }
                                        }
                                    }
                                    .el-input__inner{
                                        height: 100%;
                                        border-radius: 4px 0 0 4px;
                                    }
                                    .ca007{
                                        font-size: .848485rem /* 28/33 */;
                                        margin-right: .606061rem /* 20/33 */;
                                        line-height: 2.060606rem /* 68/33 */;
                                        cursor: pointer;
                                        position: relative;
                                        top: .060606rem /* 2/33 */;
                                    }
                                    .el-icon-circle-close{
                                        font-size: .787879rem /* 26/33 */;
                                        margin-right: .181818rem /* 6/33 */;
                                        line-height: 2.060606rem /* 68/33 */;
                                    }
                                }
                            }
                            .sear-input-box{
                                position: relative;
                                flex: 1;
                            }
                            .vin-code-input{
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 10;
                                width: 90%;
                                height: 2.121212rem /* 70/33 */;
                                .el-input__inner{
                                    font-weight: 700;
                                    border-right: none;
                                    font-size: 16px;
                                    color: #3158c6;
                                    padding-right: 10px;
                                    height: 100%;
                                    &::placeholder {
                                        font-size: 12px;
                                    }
                                }
                            }
                            .vin-his-box{
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 8;
                                width: 100%;
                                
                                height: 2.121212rem /* 70/33 */;
                                .el-input{
                                    height: 2.121212rem /* 70/33 */;
                                }
                                .el-input__inner{
                                    border-right: none;
                                    height: 100%;
                                }
                            }
                            .btn{
                                box-sizing: border-box;
                                width: 4.545455rem /* 150/33 */;
                                height: 2.121212rem /* 70/33 */;
                                background: #0072F2;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: .787879rem /* 26/33 */;
                                color: #fff;
                                cursor: pointer;
                            }
                            .upImgVin{
                                position: absolute;
                                // top: 2.15rem;
                                bottom: -210px;
                                left: 50%;
                                transform: translateX(-50%);
                                padding: 10px 20px;
                                padding-top: 20px;
                                background: #fff;
                                box-shadow: 0 0 6px rgba(0, 0, 0, .2);
                                box-sizing: border-box;
                                .el-upload-dragger{
                                    width: 21rem;
                                    
                                    overflow: inherit;
                                }
                                .close-btn{
                                    position: absolute;
                                    right: -18px;
                                    top: -14px;
                                    .up-close{
                                        line-height: 1;
                                        font-size: 18px;
                                    }
                                }
                                .el-upload-list{
                                    .el-upload-list__item{
                                        padding: 10px;
                                        .el-upload-list__item-name{
                                            display: none;
                                            overflow: hidden;
                                        }
                                        img{
                                            // width: 20.30303rem /* 670/33 */;
                                            width: auto;
                                            height: 100%;
                                            margin-left: 0;
                                            float: none;
                                        }
                                    }
                                    .el-upload-list__item-status-label{
                                        z-index: 99;
                                    }
                                    .el-icon-close{
                                        z-index: 99;
                                    }
                                }

                            }
                        }
                    }
                }

                .vin{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    
                    .content-end{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        .brand-title{
                            // height: 3.030303rem /* 100/33 */;
                            // height: 2.424242rem /* 80/33 */;
                            height: 1.818182rem /* 60/33 */ ;
                            font-size: .606061rem /* 20/33 */;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #666;
                            font-weight: 700;
                            .tit{
                                padding: 0 .363636rem /* 12/33 */;
                            }
                            .dot{
                                width: .30303rem /* 10/33 */;
                                height: .30303rem /* 10/33 */;
                                border-radius: 50%;
                                background: #ccc;
                            }
                        }
                        .brand-img-box{
                            position: relative;
                            text-align: center;
                            // flex:1;
                            height: 310px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // overflow: auto;
                            .img-box{
                                height: 310px;
                                position: relative;
                                img{
                                    height: 100%;
                                    position: relative;
                                    z-index: -1;
                                }
                                .brand-div{
                                    position: absolute;
                                    // background: rgba(#000,.3);
                                    // color: #fff;
                                    width: 50px;
                                    // height: 65px;
                                    height: 68px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .veh{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    .content-top{
                        position: relative;
                        height: 6.060606rem /* 200/33 */;
                       .mode-search{
                            bottom: 0;
                            .search-box{
                                border: none;
                            }
                        }
                    }
                    .content-end{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        overflow: auto;
                        border-bottom: 1px solid #eee;
                        >div{
                            .brand-logo-list{
                                display: flex;
                                
                                flex-wrap: wrap;
                                .brand-logo-item{
                                    cursor: pointer;
                                    // width: 98px;
                                    // height: 98px;
                                    width: 100px;
                                    height: 100px;
                                    margin-right: 10px;
                                    margin-bottom: 10px;
                                    border: 1px solid rgba(0,0,0,.15);
                                    border-radius: 4px;
                                    display: flex;
                                    flex-direction: column;
                                    .img-box{
                                        width: 100px;
                                        // height: 58px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        img{
                                            width: 100px;
                                            // width: 100%;
                                            // height: 100%;
                                        }
                                    }
                                    .logo-text{
                                        flex: 1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }
                                .brand-logo-item:hover {
                                    box-shadow: 0 2px 8px 0 rgba(0,0,0,.3);
                                }
                            }
                        }
                        
                        .veh-item{
                            .brand-numerical{
                                display: flex;
                                padding: 10px 20px;
                               
                                .brand-label{
                                    display: flex;
                                    align-items: center;
                                    padding-right: 10px;
                                }
                                .numerical-list{
                                    display: flex;
                                    .numerical-item{
                                        cursor: pointer;
                                        padding: 2px 6px;
                                        display: flex;
                                        align-items: center;
                                    }
                                    .all-numer{
                                        padding: 4px 6px;
                                        
                                    }
                                    .numerical-item:hover{
                                        background: #edf9ff;
                                        color: #333;
                                    }
                                    .active{
                                        background: $vin-blue;
                                        color: #fff;
                                    }
                                }
                            }
                            .brand-box{
                                padding: 10px 20px;
                                padding-top: 0;
                                
                            }
                        }
                    }
                }
                .oecode{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    .content-top{
                        position: relative;
                        height: 11.212121rem /* 370/33 */;
                       
                    }
                    .content-end{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        overflow: auto;
                        .hot-title{
                            color: #666;
                            font-size: .606061rem /* 20/33 */;
                            font-weight: bold;
                            text-align: center;
                            padding: 15px 0;
                        }
                        .hot-list{
                            display: flex;
                            width: 1200px;
                            margin: 0 auto;
                            flex-wrap: wrap;
                            max-height: 194px;
                            .part-item{
                                box-shadow: 0px 0px 6px 0px rgba(38, 118, 212, 0.19);
                                border-radius: 4px;
                                width: 18%;
                                display: flex;
                                margin-right: 15px;
                                margin-top: 15px;
                                cursor: pointer;
                                align-items: center;
                                justify-content: center;
                                padding: 10px 0;
                                img{
                                    width: 90%;
                                }
                            }
                        }
                    }
                }
            }
            .explain{
                text-align: center;
                padding: .454545rem /* 15/33 */ 0 !important;
                span{
                    color: $vin-blue;
                }
            }
        }
        // 其他页面的历史记录
        .his-row{
            border-top: 1px solid #F8F7F4;
            display: flex;
            align-items: center;
            height: 40px;
            min-height: 40px;
            padding: 0 20px;
            flex-shrink: 0;
            .re-home{
                background: #1F3E82;
                color: #fff;
                width: 90px;
                height: 28px;
                font-size: 14px;
                cursor: pointer;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    margin-right: 2px;
                }
            }
            .route-list{
                .rote-item{
                    cursor: pointer;
                    span{
                        font-size: 14px;
                    }
                }
                .rote-item:last-child{
                    color: $vin-blue;
                }
            }
        }
        .epc-home-footer{
            text-align: center;
            // height: 2.424242rem /* 80/33 */;
            height: 50px;
            // height: 2.121212rem /* 70/33 */;
            padding-top: .30303rem /* 10/33 */;
            border-top: 2px solid #f5f5f5;
            box-sizing: border-box;
            color: #666666;
            background: #fff;
           div{
               margin-bottom: 4px;
           }
        }
		.expl-info-dis{
			.img-box{
				margin-left: 120px;
			} 
		}
       
    }
</style>